import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewMaintenanceOrderStatusFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import FormSelectField from './Fields/FormSelectField'
import FormTextField from './Fields/FormTextField'

const MaintenanceOrderStatusForm = ({ onSubmit, currentData, cancelDialog, currentAction, injectedFormValues, updateParentEntity, parentEntityName }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: '',
      maintenance_order_id: '',
      status: '',
      reason: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewMaintenanceOrderStatusFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, false, null, true, null, updateParentEntity, parentEntityName)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(injectedFormValues || {}).length) {
        formActions.setFieldValue('maintenance_order_id', injectedFormValues['maintenance-orders'] || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [injectedFormValues])


  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('status', currentData?.status || '')
        formActions.setFieldValue('reason', currentData?.reason || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])


  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
      <FormSelectField formActions={formActions} classes={classes} name={'status'} labelKey={'Fields.Labels.status'}
                       required type={'statusTrack'} row/>

      <FormTextField formActions={formActions} classes={classes} name={'reason'}
                     labelKey={'Fields.Labels.reason'} required row lines={3}/>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default MaintenanceOrderStatusForm
