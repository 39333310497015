import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewTicketCaseFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import FormTextField from './Fields/FormTextField'
import FormTextFieldWithMask from './Fields/FormTextFieldWithMask'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const TicketCasesForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      name: currentData?.name || '',
      sla: currentData?.sla || '',
      ticket_type_id: currentData?.ticket_type?.id || '',
      form_template_id: currentData?.form_template?.id || '',
      team_id: currentData?.team?.id || '',
      sort_order: currentData?.sort_order || 1,
      description: currentData?.description || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewTicketCaseFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  const NumberChange = (event, fieldName) => {
    const re = /^[0-9\b]+$/
    if (event.target.value === '' || re.test(event.target.value)) {
      formActions.setFieldValue(fieldName, event.target.value)
    }
  }
  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'name'} labelKey={'Fields.Labels.Name'}
                     required/>

      <FormTextFieldWithMask formActions={formActions} classes={classes} name={'sla'} labelKey={'Fields.Labels.sla'}
                             required mask="99:m9:m9"
                             formatChars={{
                               'm': '[0-5]',
                               '9': '[0-9]'
                             }}/>

      {currentAction !== 'create' &&
      <FormTextField formActions={formActions} classes={classes} name={'sort_order'}
                     labelKey={'Fields.Labels.sort_order'}
                     required onChange={(event) => NumberChange(event, 'sort_order')}/>
      }

      <ListingProvider entityName={'ticket-types'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'ticket_type_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.ticket_type'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'teams'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'team_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'title'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.team'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'form-templates'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'form_template_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.form_template'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>
      <FormTextField formActions={formActions} classes={classes} name={'description'}
                     labelKey={'Fields.Labels.description'} required row lines={2}/>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default TicketCasesForm
