import React from 'react'
import { Box } from '@material-ui/core'
import UsersForm from './UsersForm'
import RolesForm from './RolesForm'
import PermissionsForm from './PermissionsForm'
import TeamsForm from './TeamsForm'
import ServiceRequestsForm from './ServiceRequestsForm'
import ServiceRequestSchedulesForm from './ServiceRequestschedulesForm'
import InstallationOrderForm from './InstallationOrderForm'
import CustomersForm from './CustomersForm'
import ContactsForm from './ContactsForm'
import BrandsForm from './BrandsForm'
import VehiclesForm from './VehiclesForm'
import VehicleBrandsForm from './VehicleBrandsForm'
import CustomerTypesForm from './CustomerTypesForm'
import OperatorsForm from './OperatorsForm'
import ImeisForm from './ImeisForm'
import ProductsForm from './ProductsForm'
import ProductQuantitiesForm from './ProductQuantitiesForm'
import VehicleProductsForm from './VehicleProductsForm'
import InstallationOrderProductsForm from './InstallationOrderProductsForm'
import CalllogsForm from './CalllogsForm'
import InventoriesForm from './InventoriesForm'
import TicketCategoriesForm from './TicketCategoriesForm'
import MaintenanceProvidersForm from './MaintenanceProvidersForm'
import TicketTypesForm from './TicketTypesForm'
import TicketCasesForm from './TicketCasesForm'
import TicketsForm from './TicketsForm'
import { AuthConsumer } from '../../../context/AuthContext'
import TicketUserCaseForm from './TicketUserCaseForm'
import FormTemplatesForm from './FormTemplatesForm'
import MaintenanceOrderStatusListing from './MaintenanceOrderStatusListing'
import MaintenanceOrderStatusForm from './MaintenanceOrderStatusForm'
import MaintenanceOrderProductStatusListing from './MaintenanceOrderProductStatusListing'
import MaintenanceOrderProductStatusForm from './MaintenanceOrderProductStatusForm'
import MaintenanceOrderProductsForm from './MaintenanceOrderProductsForm'
import ServiceRequestSchedulesListForm from './ServiceRequestSchedulesListForm'
import InstallationOrderListForm from './InstallationOrderListForm'
import MaintenanceOrderForm from './MaintenanceOrderForm'
import MaintenanceOrderListForm from './MaintenanceOrderListForm'
import VehicleProductsTransferForm from './VehicleProductsTransferForm'

const AddNewForm = ({ entityName, onSubmit, currentData, cancelDialog, currentAction, type, remaining, injectedFormValues, handleInjectedFormValues, updateParentEntity, otherUpdateParentEntity, parentEntityName, disableStatusAdd }) => {
  const searchFiltersSwitcher = () => {
    switch (entityName) {
      case 'users':
        return <UsersForm currentData={currentData}
                          onSubmit={onSubmit}
                          currentAction={currentAction}
                          cancelDialog={cancelDialog}/>
      case 'roles':
        return <RolesForm currentData={currentData}
                          onSubmit={onSubmit}
                          currentAction={currentAction}
                          cancelDialog={cancelDialog}/>
      case 'permissions':
        return <PermissionsForm currentData={currentData}
                                onSubmit={onSubmit}
                                currentAction={currentAction}
                                cancelDialog={cancelDialog}/>
      case 'teams':
        return <TeamsForm currentData={currentData}
                          onSubmit={onSubmit}
                          currentAction={currentAction}
                          cancelDialog={cancelDialog}/>
      case 'customers':
        return <CustomersForm currentData={currentData}
                              onSubmit={onSubmit}
                              currentAction={currentAction}
                              cancelDialog={cancelDialog}/>

      case 'vehicle-products':
        if (currentAction === 'transfer') {
          return <VehicleProductsTransferForm currentData={currentData}
                                              onSubmit={onSubmit}
                                              currentAction={currentAction}
                                              cancelDialog={cancelDialog}/>
        } else {
          return <VehicleProductsForm currentData={currentData}
                                      onSubmit={onSubmit}
                                      currentAction={currentAction}
                                      cancelDialog={cancelDialog}/>
        }

      case 'inventories':
        return <InventoriesForm currentData={currentData}
                                onSubmit={onSubmit}
                                currentAction={currentAction}
                                cancelDialog={cancelDialog}/>

      case 'imeis':
        return <ImeisForm currentData={currentData}
                          onSubmit={onSubmit}
                          currentAction={currentAction}
                          cancelDialog={cancelDialog}/>

      case 'products':
        return <ProductsForm currentData={currentData}
                             onSubmit={onSubmit}
                             currentAction={currentAction}
                             cancelDialog={cancelDialog}/>

      case 'product-quantities':
        return <ProductQuantitiesForm currentData={currentData}
                                      onSubmit={onSubmit}
                                      currentAction={currentAction}
                                      cancelDialog={cancelDialog}
                                      updateParentEntity={updateParentEntity}
                                      parentEntityName={parentEntityName}
                                      entityName={entityName}/>

      case 'installation-order-products':
        return <InstallationOrderProductsForm currentData={currentData}
                                              onSubmit={onSubmit}
                                              currentAction={currentAction}
                                              cancelDialog={cancelDialog}
                                              updateParentEntity={updateParentEntity}
                                              otherUpdateParentEntity={otherUpdateParentEntity}
                                              injectedFormValues={injectedFormValues}
                                              parentEntityName={parentEntityName}
                                              entityName={entityName}/>

      case 'maintenance-order-products':
        if (currentAction === 'status') {
          return <MaintenanceOrderProductStatusListing currentData={currentData}
                                                       disableStatusAdd={disableStatusAdd}
                                                       injectedFormValues={injectedFormValues}
                                                       handleInjectedFormValues={handleInjectedFormValues}
                                                       parentEntityName={parentEntityName}
                                                       onSubmit={onSubmit}
                                                       entityName={entityName}/>
        } else {
          return <MaintenanceOrderProductsForm currentData={currentData}
                                               onSubmit={onSubmit}
                                               currentAction={currentAction}
                                               cancelDialog={cancelDialog}
                                               updateParentEntity={updateParentEntity}
                                               otherUpdateParentEntity={otherUpdateParentEntity}
                                               parentEntityName={parentEntityName}
                                               entityName={entityName}/>
        }
      case 'operators':
        return <OperatorsForm currentData={currentData}
                              onSubmit={onSubmit}
                              currentAction={currentAction}
                              cancelDialog={cancelDialog}/>
      case 'customer-types':
        return <CustomerTypesForm currentData={currentData}
                                  onSubmit={onSubmit}
                                  currentAction={currentAction}
                                  cancelDialog={cancelDialog}/>
      case 'ticket-categories':
        return <TicketCategoriesForm currentData={currentData}
                                     onSubmit={onSubmit}
                                     currentAction={currentAction}
                                     cancelDialog={cancelDialog}/>
      case 'contacts':
        return <ContactsForm currentData={currentData}
                             onSubmit={onSubmit}
                             currentAction={currentAction}
                             cancelDialog={cancelDialog}/>

      case 'brands':
        return <BrandsForm currentData={currentData}
                           onSubmit={onSubmit}
                           currentAction={currentAction}
                           cancelDialog={cancelDialog}/>

      case 'vehicle-brands':
        return <VehicleBrandsForm currentData={currentData}
                                  onSubmit={onSubmit}
                                  currentAction={currentAction}
                                  cancelDialog={cancelDialog}/>

      case 'maintenance-providers':
        return <MaintenanceProvidersForm currentData={currentData}
                                         onSubmit={onSubmit}
                                         currentAction={currentAction}
                                         cancelDialog={cancelDialog}/>

      case 'ticket-types':
        return <TicketTypesForm currentData={currentData}
                                onSubmit={onSubmit}
                                currentAction={currentAction}
                                cancelDialog={cancelDialog}/>

      case 'ticket-cases':
        return <TicketCasesForm currentData={currentData}
                                onSubmit={onSubmit}
                                currentAction={currentAction}
                                cancelDialog={cancelDialog}/>

      case 'ticket-user-cases':
        let additionalFields = {}
        let hasValues = !!Object.keys(currentData?.additional || {}).length
        if (currentData?.additional_fields?.length) {
          currentData?.additional_fields.forEach(field => {
            let val = ''
            if (!!hasValues) {
              val = currentData?.additional.filter(option => option.name === field?.name)
              val = !!val?.length ? val[0]?.value : ''
              if (['select', 'multi_select'].includes(field?.type)) {
                let valObj = field?.additional.filter(option => option.value === val)
                if (!!valObj?.length) {
                  valObj = valObj[0]
                  val = valObj.key
                }
              }
            }
            additionalFields[field?.name] = val || ((field?.type === 'multi_select') ? [] : '')
          })
        }
        return <TicketUserCaseForm currentData={currentData}
                                   onSubmit={onSubmit}
                                   updateParentEntity={updateParentEntity}
                                   additionalFields={additionalFields}
                                   currentAction={currentAction}
                                   cancelDialog={cancelDialog}/>

      case 'tickets':
        return <AuthConsumer>
          {({ userData }) => (
            <TicketsForm currentData={currentData}
                         onSubmit={onSubmit}
                         userData={userData}
                         currentAction={currentAction}
                         cancelDialog={cancelDialog}/>
          )}
        </AuthConsumer>

      case 'vehicles':
        return <VehiclesForm currentData={currentData}
                             onSubmit={onSubmit}
                             currentAction={currentAction}
                             cancelDialog={cancelDialog}/>

      case 'calllogs':
        return <CalllogsForm currentData={currentData}
                             onSubmit={onSubmit}
                             currentAction={currentAction}
                             cancelDialog={cancelDialog}/>

      case 'service-requests':
        return <ServiceRequestsForm currentData={currentData}
                                    onSubmit={onSubmit}
                                    currentAction={currentAction}
                                    cancelDialog={cancelDialog}
                                    entityName={entityName}/>

      case 'service-request-schedules':
        return <ServiceRequestSchedulesForm currentData={currentData}
                                            injectedFormValues={injectedFormValues}
                                            handleInjectedFormValues={handleInjectedFormValues}
                                            updateParentEntity={updateParentEntity}
                                            parentEntityName={parentEntityName}
                                            onSubmit={onSubmit}
                                            type={type}
                                            currentAction={currentAction}
                                            cancelDialog={cancelDialog}
                                            entityName={entityName}/>

      case 'service-request-schedules-list':
        return <ServiceRequestSchedulesListForm currentData={currentData}
                                                onSubmit={onSubmit}
                                                currentAction={currentAction}
                                                cancelDialog={cancelDialog}
                                                entityName={entityName}/>

      case 'installation-orders':
        return <InstallationOrderForm currentData={currentData}
                                      injectedFormValues={injectedFormValues}
                                      handleInjectedFormValues={handleInjectedFormValues}
                                      remaining={remaining}
                                      updateParentEntity={updateParentEntity}
                                      parentEntityName={parentEntityName}
                                      onSubmit={onSubmit}
                                      currentAction={currentAction}
                                      cancelDialog={cancelDialog}
                                      entityName={entityName}/>

      case 'maintenance-orders':
        if (currentAction === 'status') {
          return <MaintenanceOrderStatusListing currentData={currentData}
                                                disableStatusAdd={disableStatusAdd}
                                                injectedFormValues={injectedFormValues}
                                                handleInjectedFormValues={handleInjectedFormValues}
                                                parentEntityName={parentEntityName}
                                                onSubmit={onSubmit}
                                                entityName={entityName}/>
        } else {
          return <MaintenanceOrderForm currentData={currentData}
                                       injectedFormValues={injectedFormValues}
                                       handleInjectedFormValues={handleInjectedFormValues}
                                       remaining={remaining}
                                       updateParentEntity={updateParentEntity}
                                       parentEntityName={parentEntityName}
                                       onSubmit={onSubmit}
                                       type={type}
                                       currentAction={currentAction}
                                       cancelDialog={cancelDialog}
                                       entityName={entityName}/>
        }

      case 'installation-orders-list':
        return <InstallationOrderListForm currentData={currentData}
                                          onSubmit={onSubmit}
                                          currentAction={currentAction}
                                          cancelDialog={cancelDialog}
                                          entityName={entityName}/>
      case 'maintenance-orders-list':
        if (currentAction === 'status') {
          return <MaintenanceOrderStatusListing currentData={currentData}
                                                disableStatusAdd={disableStatusAdd}
                                                parentEntityName={parentEntityName}
                                                onSubmit={onSubmit}
                                                entityName={entityName}/>
        } else {
          return <MaintenanceOrderListForm currentData={currentData}
                                           onSubmit={onSubmit}
                                           currentAction={currentAction}
                                           cancelDialog={cancelDialog}
                                           entityName={entityName}/>
        }

      case 'maintenance-order-status-tracks':
        return <MaintenanceOrderStatusForm currentData={currentData}
                                           injectedFormValues={injectedFormValues}
                                           updateParentEntity={updateParentEntity}
                                           parentEntityName={parentEntityName}
                                           onSubmit={onSubmit}
                                           currentAction={currentAction}
                                           cancelDialog={cancelDialog}/>

      case 'maintenance-product-status-tracks':
        return <MaintenanceOrderProductStatusForm currentData={currentData}
                                                  injectedFormValues={injectedFormValues}
                                                  updateParentEntity={updateParentEntity}
                                                  parentEntityName={parentEntityName}
                                                  onSubmit={onSubmit}
                                                  currentAction={currentAction}
                                                  cancelDialog={cancelDialog}/>

      case 'form-templates':
        return <FormTemplatesForm currentData={currentData}
                                  onSubmit={onSubmit}
                                  currentAction={currentAction}
                                  cancelDialog={cancelDialog}/>
      default:
        return ''
    }
  }
  return (
    <Box p={3}>
      {!!entityName && searchFiltersSwitcher()}
    </Box>
  )
}

export default AddNewForm
