import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, FormControlLabel, Switch, InputLabel } from '@material-ui/core'

const FormSwitchField = ({ formActions, classes, name, labelKey, valueLabelKey, required, isSearch, labelPrefix, onChange, otherSwitchAttributes = {}, otherAttributes = {} }) => {
  const intl = useIntl()
  return (
    !!isSearch ?
      <FormControl className={classes.formControl} variant="outlined">
        <FormControlLabel
          control={
            <Switch
              checked={formActions.values[name]}
              onChange={onChange ?? ((event) => {
                formActions.setFieldValue(name, !!event.target.checked)
              })}
              name={name}
              id={name}
              color="primary"
              onBlur={formActions.handleBlur}
              error={formActions.errors[name] && formActions.touched[name] !== undefined}
              {...otherSwitchAttributes}
            />}
          label={intl.formatMessage({ id: labelKey })}
          labelPlacement="end"
          {...otherAttributes}
        />
      </FormControl>
      :
      <div className={classes.FormItem}>
        <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <FormControlLabel
          control={
            <Switch
              checked={formActions.values[name]}
              onChange={onChange ?? ((event) => {
                formActions.setFieldValue(name, !!event.target.checked)
              })}
              name={name}
              id={name}
              color="primary"
              onBlur={formActions.handleBlur}
              error={formActions.errors[name] && formActions.touched[name] !== undefined}
              {...otherSwitchAttributes}
            />}
          label={intl.formatMessage({ id: valueLabelKey ?? `${labelPrefix}.${!!formActions.values[name]}` })}
          labelPlacement="end"
          {...otherAttributes}
        />
      </div>
  )
}

export default FormSwitchField
