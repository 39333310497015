import React from 'react'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer } from '../../../context/ListingContext'
import FormListAddMore from './Fields/FormListAddMore'

const MaintenanceOrderProductStatusListing = ({ onSubmit, currentData, entityName, injectedFormValues, handleInjectedFormValues, parentEntityName, disableStatusAdd }) => {
  const classes = useStyles()
  const [statusFilter, setStatusFilter] = React.useState(!!currentData?.id ? { maintenance_order_product_id: currentData?.id } : null)
  return (
    <ListingConsumer>
      {({ updateCurrentSelectedItem }) => (
        <FormListAddMore labelKey={'Fields.Labels.statusTracks'}
                         entityName={'maintenance-product-status-tracks'}
                         id={currentData?.id}
                         noMarginTop
                         currentData={currentData}
                         disableAdd={disableStatusAdd}
                         disableEdit={true}
                         disableDelete={true}
                         initFilter={statusFilter}
                         classes={classes} parentEntityName={entityName}
                         handleInjectedFormValues={handleInjectedFormValues}
                         injectedFormValues={injectedFormValues}
                         onSubmit={onSubmit}
                         updateParentEntity={(value) => {
                           setTimeout(() => {
                             setStatusFilter(!!currentData?.id ? { maintenance_order_product_id: currentData?.id } : null)
                             if (!!value?.length && value[0]?.status) {
                               updateCurrentSelectedItem([{
                                 id: currentData?.id,
                                 status_track: value[0]?.status
                               }], parentEntityName, false, true)
                             }
                           }, 200)
                         }}
        />
      )}
    </ListingConsumer>
  )
}

export default MaintenanceOrderProductStatusListing
