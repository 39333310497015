/**
 * Configuration Class
 * Responsible for All The Api Configuration for the Application
 */
import CookiesStorageService from '../CookiesStorageService'
import { ArabicFont, EnglishFont } from '../../utils/Fonts'
import arMessages from './../../translations/ar.json'
import enMessages from './../../translations/en.json'
import { LocalStorageService } from '../LocalStorageService'

class Configuration {
  static BASE_URL = process.env.REACT_APP_BASE_URL
  static STORAGE_BASE_URL = process.env.REACT_APP_STORAGE_BASE_URL
  static UPLOAD_FILE_SIZE = process.env.REACT_APP_UPLOAD_FILE_SIZE_IN_BYTES

  static Admin_EndPoints_Root = {
    Auth: '/auth'
  }
  static Admin_EndPoints = {
    Auth: {
      login: this.Admin_EndPoints_Root.Auth + '/login',
      refreshToken: this.Admin_EndPoints_Root.Auth + '/refresh-token',
      getUser: this.Admin_EndPoints_Root.Auth + '/me',
      logout: this.Admin_EndPoints_Root.Auth + '/logout',
      password: {
        forgot: this.Admin_EndPoints_Root.Auth + '/forgot-password',
        reset: this.Admin_EndPoints_Root.Auth + '/reset-password'
      }
    }
  }
  static Local = LocalStorageService.get('QHUBLanguage')
  static DefaultLocale = process.env.REACT_APP_DEFAULT_LANGUAGE
  static DefaultDirection = process.env.REACT_APP_DEFAULT_DIRECTION
  static DefaultFont = process.env.REACT_APP_DEFAULT_LANGUAGE === 'ar' ? ArabicFont : EnglishFont
  static DefaultMessage = process.env.REACT_APP_DEFAULT_LANGUAGE === 'ar' ? arMessages : enMessages

  /**
   * Function To Check if the user is Authenticated or not
   * @returns {boolean}
   */
  static isAuth = () => {
    return !!CookiesStorageService.getAccessToken()
  }

  static isIOS = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  }
}

export default Configuration
