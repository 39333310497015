import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewMaintenanceOrderProductStatusFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import FormSelectField from './Fields/FormSelectField'
import FormTextField from './Fields/FormTextField'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const MaintenanceOrderProductStatusForm = ({ onSubmit, currentData, cancelDialog, currentAction, injectedFormValues, updateParentEntity, parentEntityName }) => {
  const classes = useStyles()
  const [productImeisFilter, setProductImeisFilter] = React.useState({})
  const formActions = useFormik({
    initialValues: {
      id: '',
      maintenance_order_product_id: '',
      status: '',
      reason: '',
      solved_by: '',
      replaced_product: '',
      replaced_imei: '',
      replace_reason: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewMaintenanceOrderProductStatusFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, false, null, true, null, updateParentEntity, parentEntityName)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(injectedFormValues || {}).length) {
        formActions.setFieldValue('maintenance_order_product_id', injectedFormValues['installation-order-products'] || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [injectedFormValues])

  const handleProductImeisFilter = (value) => {
    if (value?.type === 'configurable') {
      setProductImeisFilter({ 'product_id': value?.id || '' })
    }
  }

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
      <FormSelectField formActions={formActions} classes={classes} name={'status'} labelKey={'Fields.Labels.status'}
                       required type={'statusTrack'} row/>

      <FormTextField formActions={formActions} classes={classes} name={'reason'}
                     labelKey={'Fields.Labels.reason'} required row lines={3}/>

      {formActions.values.status === 'solved' && <>
        <FormSelectField formActions={formActions} classes={classes} name={'solved_by'}
                         labelKey={'Fields.Labels.solvedBy'}
                         required type={'solvedBy'} row/>

        {formActions.values.solved_by === 'device_replacement' && <>
          <ListingProvider entityName={'products'}>
            <ListingConsumer>
              {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                <FormEntityLoadField formActions={formActions} classes={classes} name={'replaced_product'}
                                     list={listingData} loading={listingLoading} allEntity entityKey={'id'}
                                     searchable={'name'} loadEntities={handleListingFilterSubmit}
                                     setFilter={handleProductImeisFilter} row
                                     labelKey={'Fields.Labels.replacedProduct'} required/>
              )}
            </ListingConsumer>
          </ListingProvider>

          {formActions.values.replaced_product?.type === 'configurable' &&
          <ListingProvider entityName={'imeis'} filter={productImeisFilter}>
            <ListingConsumer>
              {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                <FormEntityLoadField formActions={formActions} classes={classes} name={'replaced_imei'}
                                     disabled={!formActions.values.replaced_product?.id} allEntity row
                                     list={listingData} loading={listingLoading} entityKey={'id'}
                                     searchable={'name'} loadEntities={handleListingFilterSubmit}
                                     labelKey={'Fields.Labels.replacedImei'} required addMore/>
              )}
            </ListingConsumer>
          </ListingProvider>
          }
          <FormTextField formActions={formActions} classes={classes} name={'replace_reason'}
                         labelKey={'Fields.Labels.replaceReason'} required row lines={3}/>
        </>}
      </>}

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default MaintenanceOrderProductStatusForm
