import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  pageTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2.25),
    marginBottom: theme.spacing(2.5)
  },
  pageTitle: {
    margin: theme.spacing(0),
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.primary.dark
  },
  pageTitleListCount: {
    marginLeft: theme.spacing(2.5),
    display: 'inline-block',
    background: theme.palette.primary.light,
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.spacing(0.625),
    padding: theme.spacing(0.625, 1.350),
    minWidth: theme.spacing(4.5),
    textAlign: 'center'
  }
}))
