/**
 * Rest Client class
 * Responsible for consuming Api endpoints
 *
 * * getRequest
 * * postRequest
 * * putRequest
 * * deleteRequest
 */
import React from 'react'
import axios from 'axios'
import Configuration from '../Api/Configuration'
import '../axios'
import { handleNotificationAlert, log } from '../../utils/helpers'
import { FormattedMessage } from 'react-intl'

class RestClient {
  constructor () {
    this.config = new Configuration()
  }

  static setFormikErrors = (errorObject, setFormikError) => {
    let errors = Object.keys(errorObject)
    if (!!errors?.length) {
      errors.forEach(errorItem => {
          let fieldNames = errorItem.split('.')
          setFormikError(fieldNames?.length ? fieldNames[0] : errorItem, errorObject[errorItem].join('\r\n'))
        }
      )
    }
  }

  static getRequest = async (url, config, defaultErrorMessage, shouldRedirect) => {
    return await axios.get(url, config)
      .then(response => {return response })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx

          let message = defaultErrorMessage || <FormattedMessage id='BasicInfo.FormErrorAlert'/>
          if (!!error.response && error.response.status === 422 && error.response.data) {
            if (error.response.data.message) {
              message = error.response.data.message
            }
            // if (error.response.data.errors && !!FormikActions) {
            //   this.setFormikErrors(error.response.data.errors, FormikActions.setFieldError)
            // }
          }
          handleNotificationAlert({
            type: 'defence',
            message: message,
            color: 'error'
          }, 'error')

          if (!!shouldRedirect) {
            shouldRedirect(true)
          }

          log('getRequest data:', error.response.data)
          log('getRequest status:', error.response.status)
          log('getRequest headers:', error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          log('getRequest request:', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          log('getRequest Error Message:', error.message)
        }
        log('getRequest config:', error.config)
      })
  }

  static postRequest (url, data, config, setFormLoading, setFailure, FormikActions, defaultErrorMessage = '') {
    return axios.post(url, data, config)
      .then(response => { return response })
      .catch((error) => {
        // eslint-disable-next-line no-unused-expressions
        if (!!FormikActions) {
          try {
            FormikActions.setSubmitting(false)
          } catch (e) {
            FormikActions.isSubmitting = false
          }
        }
        // eslint-disable-next-line no-unused-expressions
        !!setFormLoading ? setFormLoading(false) : ''
        // eslint-disable-next-line no-unused-expressions
        if (!!setFailure) {
          setFailure(true)
        }

        let message = defaultErrorMessage || <FormattedMessage id='BasicInfo.FormErrorAlert'/>
        if (!!error.response && error.response.status === 422 && error.response.data) {
          if (error.response.data.message) {
            message = error.response.data.message
          }
          if (error.response.data.errors && !!FormikActions) {
            this.setFormikErrors(error.response.data.errors, FormikActions.setFieldError)
          }
        }
        handleNotificationAlert({
          type: 'defence',
          message: message,
          color: 'error'
        }, 'error')

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          log('postRequest data:', error.response.data)
          log('postRequest status:', error.response.status)
          log('postRequest headers:', error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          log('postRequest request:', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          log('postRequest Error Message:', error.message)
        }
        log('postRequest config:', error.config)
      })
  }

  static putRequest (url, data, config, setFormLoading, setFailure, FormikActions, defaultErrorMessage = '') {
    return axios.put(url, data, config)
      .then(response => {return response })
      .catch((error) => {
        // eslint-disable-next-line no-unused-expressions
        !!FormikActions ? FormikActions.setSubmitting(false) : ''
        // eslint-disable-next-line no-unused-expressions
        !!setFormLoading ? setFormLoading(false) : ''
        // eslint-disable-next-line no-unused-expressions
        !!setFailure ? setFailure(true) : ''

        let message = defaultErrorMessage || <FormattedMessage id='BasicInfo.FormErrorAlert'/>
        if (!!error.response && error.response.status === 422 && error.response.data) {
          if (error.response.data.message) {
            message = error.response.data.message
          }
          if (error.response.data.errors && !!FormikActions) {
            this.setFormikErrors(error.response.data.errors, FormikActions.setFieldError)
          }
        }
        handleNotificationAlert({
          type: 'defence',
          message: message,
          color: 'error'
        }, 'error')

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          log('putRequest data:', error.response.data)
          log('putRequest status:', error.response.status)
          log('putRequest headers:', error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          log('putRequest request:', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          log('putRequest Error Message:', error.message)
        }
        log('putRequest config:', error.config)
      })
  }

  static deleteRequest (url, config, setFormLoading, setFailure, FormikActions, defaultErrorMessage = '') {
    return axios.delete(url, config)
      .then(response => {return response })
      .catch((error) => {
        // eslint-disable-next-line no-unused-expressions
        !!FormikActions ? FormikActions.setSubmitting(false) : ''
        // eslint-disable-next-line no-unused-expressions
        !!setFormLoading ? setFormLoading(false) : ''
        // eslint-disable-next-line no-unused-expressions
        !!setFailure ? setFailure(true) : ''

        let message = defaultErrorMessage || <FormattedMessage id='BasicInfo.FormErrorAlert'/>
        if (!!error.response && error.response.status === 422 && error.response.data) {
          if (error.response.data.message) {
            message = error.response.data.message
          }
          if (error.response.data.errors && !!FormikActions) {
            this.setFormikErrors(error.response.data.errors, FormikActions.setFieldError)
          }
        }
        handleNotificationAlert({
          type: 'defence',
          message: message,
          color: 'error'
        }, 'error')

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          log('deleteRequest data:', error.response.data)
          log('deleteRequest status:', error.response.status)
          log('deleteRequest headers:', error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          log('deleteRequest request:', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          log('deleteRequest Error Message:', error.message)
        }
        log('deleteRequest config:', error.config)
      })
  }
}

export default RestClient
