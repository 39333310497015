import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewInstallationOrderFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormListAddMore from './Fields/FormListAddMore'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import FormSelectField from './Fields/FormSelectField'
import { LanguageConsumer } from '../../../context/LanguageContext'

const userFilter = { 'role': 'Technician' }
const serviceRequestFilter = { 'type': 'installation' }
const InstallationOrderListForm = ({ onSubmit, currentData, cancelDialog, currentAction, entityName }) => {
  const classes = useStyles()
  const [products, setProducts] = React.useState([])
  const [injectedFormValues, setInjectedFormValues] = React.useState({})
  const [requestScheduleFilter, setRequestScheduleFilter] = React.useState(!!currentData?.service_request?.id ? { 'service_request_id': currentData?.service_request?.id } : {})
  const formActions = useFormik({
    initialValues: {
      id: '',
      service_request_id: '',
      service_request_schedule_id: '',
      technician_id: '',
      status: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewInstallationOrderFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })


  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('service_request_id', currentData?.service_request?.id || '')
        formActions.setFieldValue('service_request_schedule_id', currentData?.service_request_schedule?.id || '')
        formActions.setFieldValue('status', currentData?.status || '')
        formActions.setFieldValue('technician_id', currentData?.technician?.id || '')
        formActions.setFieldValue('products', currentData?.products || [])
        setProducts(currentData?.products || [])
        setInjectedFormValues({
          ...injectedFormValues,
          'service-requests': currentData?.service_request?.id,
          'service-request-schedules': currentData?.service_request_schedule?.id,
          'installation-orders': currentData?.id
        })
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])

  const handleInjectedFormValues = (name, value) => {
    setInjectedFormValues({ ...injectedFormValues, [name]: value })
  }

  const handleRequestScheduleFilter = (value) => {
    setRequestScheduleFilter({ 'service_request_id': value })
  }
  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
      <ListingProvider entityName={'service-requests'} filter={serviceRequestFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'service_request_id'}
                                 list={listingData} setFilter={handleRequestScheduleFilter}
                                 loading={listingLoading} entityKey={'id'}
                                 disabled={!!currentData?.id}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.service_request'} required/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <LanguageConsumer>
        {({ locale }) => (
          <ListingProvider entityName={'service-request-schedules-list'} filter={requestScheduleFilter} locale={locale}>
            <ListingConsumer>
              {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                <FormEntityLoadField formActions={formActions} classes={classes} name={'service_request_schedule_id'}
                                     disabled={!formActions.values.service_request_id || !!currentData?.id}
                                     list={listingData} loading={listingLoading} entityKey={'id'}
                                     searchable={'name'} loadEntities={handleListingFilterSubmit}
                                     labelKey={'Fields.Labels.service_request_schedule'} required/>
              )}
            </ListingConsumer>
          </ListingProvider>
        )}
      </LanguageConsumer>

      <ListingProvider entityName={'users'} filter={userFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'technician_id'}
                                 list={listingData}
                                 loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.technician'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <FormSelectField formActions={formActions} classes={classes} name={'status'} labelKey={'Fields.Labels.status'}
                       required type={'status'}/>

      <ListingConsumer>
        {({ updateCurrentSelectedItem }) => (
          <FormListAddMore labelKey={'Fields.Labels.products'}
                           entityName={'installation-order-products'}
                           id={currentData?.id}
                           currentData={currentData}
            // listCRUD
                           handleInjectedFormValues={handleInjectedFormValues}
                           injectedFormValues={injectedFormValues}
                           statusUpdate={entityName === 'maintenance-orders-list'}
                           checkStatusUpdateKey='maintenance_id'
                           disableStatusAdd={currentData?.status === 'closed'}
                           disableAdd={currentData?.status === 'closed'}
                           disableEdit={currentData?.status === 'closed'}
                           disableDelete={currentData?.status === 'closed'}
                           formActions={formActions} classes={classes} parentEntityName={entityName}
                           onSubmit={onSubmit}
                           updateParentEntity={updateCurrentSelectedItem}
            // updateParentEntity={(value) => formActions.setFieldValue('products', value)}
                           list={products}
          />
        )}
      </ListingConsumer>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default InstallationOrderListForm
