import React from 'react'
import { FormattedMessage } from 'react-intl'
import ListingTable from '../ListingTable'
import PageTitle from '../PageTitle'
import SearchBar from '../SearchBar'
import AddNewForm from './Form'
import { ListingConsumer } from '../../context/ListingContext'
import { FormLoader } from '../Loader/FormLoader'
import DeleteEntity from '../DeleteEntity'
import ShowEntityImage from '../ShowEntityImage'
import ViewEntity from './View'

const EntityPageContent = ({ inner }) => {
  return (
    <ListingConsumer>
      {({
          listingCount, listingData, listingHeader, listingLoading, currentSelectedRow, currentDialogAction,
          handleListingFilterSubmit, isDialogOpen, openDialog, cancelDialog, dialogActionSubmit, entityName,
          loadEntityRole, handleChangeListingSort, exportListing, loadCurrentData, currentDataLoading
        }) => (
        <>
          {!inner &&
          <PageTitle
            title={<FormattedMessage id={`EntityPageContent.${entityName}.PageTitle`}/>}
            count={listingCount}
            loading={listingLoading}
          />}

          <div className={'listing-wrapper'}>
            {!inner &&
            <SearchBar
              entityName={entityName}
              searchFilters={listingHeader?.filter(column => !!column.search)}
              handleListingFilterSubmit={handleListingFilterSubmit}
              dialogAction={currentDialogAction}
              isDialogOpen={isDialogOpen}
              openDialog={openDialog}
              cancelDialog={cancelDialog}
              hasExport={['customers', 'contacts', 'vehicles', 'products', 'vehicle-products', 'imeis'].includes(entityName)}
              exportListing={exportListing}
              buttonLabel={<FormattedMessage id={`EntityPageContent.${entityName}.SearchBar.buttonLabel`}/>}
              dialogTitle={
                <FormattedMessage id={`EntityPageContent.${entityName}.SearchBar.dialogTitle.${currentDialogAction}`}
                                  values={{ name: currentSelectedRow?.name || currentSelectedRow?.title || currentSelectedRow?.id || '' }}/>
              }
              dialogBody={(currentDialogAction === 'delete') ?
                <DeleteEntity/>
                :
                (currentDialogAction === 'showImage') ?
                  <ShowEntityImage/>
                  :
                  (currentDialogAction === 'show') ?
                    <ViewEntity entityName={entityName}
                                loadCurrentData={loadCurrentData}
                                currentDataLoading={currentDataLoading}
                                listingHeader={listingHeader?.filter(column => !column.hide)}
                                currentData={currentSelectedRow}/>
                    :
                    <AddNewForm entityName={entityName}
                                currentData={currentSelectedRow}
                                loadEntityList={loadEntityRole}
                                onSubmit={dialogActionSubmit}
                                currentAction={currentDialogAction}
                                cancelDialog={cancelDialog}/>
              }
            />}
            <ListingTable rows={listingData} entityName={entityName} additionalClass={!!inner ? 'inner' : ''}
                          columns={listingHeader?.filter(column => !column.hide)} inner={inner}
                          handleChangeListingSort={handleChangeListingSort} listingLoading={listingLoading}/>
            {!!listingLoading && <FormLoader loading={true}/>}
          </div>
        </>

      )}
    </ListingConsumer>
  )
}

export default EntityPageContent
