import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import ar from 'react-phone-input-2/lang/ar.json'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { LanguageConsumer } from '../../../../context/LanguageContext'

const FormPhoneField = ({ formActions, classes, name, labelKey, required, isSearch }) => {
  const intl = useIntl()
  const [mobileError, setMobileError] = React.useState('')

  return (
    !!isSearch ?
      <FormControl className={classes.formControl} variant="outlined">
        <div className={classes.PhoneInputWrapper}>
          <LanguageConsumer>
            {({ locale }) => (
              <PhoneInput
                placeholder={intl.formatMessage({ id: labelKey })}
                enableSearch={false}
                countryCodeEditable={false}
                disableDropdown={true}
                jumpCursorToEnd={true}
                onlyCountries={['eg']}
                country={'eg'}
                localization={locale === 'ar' ? ar : ''}
                specialLabel={''}
                excludeCountries={['il']}
                inputProps={{
                  name: name,
                  required: true,
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off'
                  }
                }}
                searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                onBlur={formActions.handleBlur}
                value={formActions.values[name]}
                helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
                error={formActions.errors[name] && formActions.touched[name] !== undefined}
                onChange={(phone) => {
                  formActions.setFieldValue(name, '+' + phone.toString())
                }}
                isValid={(value, country) => {
                  if (country.iso2 === 'eg' && value.length > 12) {
                    setMobileError(intl.formatMessage({ id: 'validationSchema.phoneError' }))
                    return false
                  } else {
                    setMobileError('')
                    return true
                  }
                }}
              />
            )}
          </LanguageConsumer>
          {!!mobileError ?
            <FormHelperText margin="dense" variant='outlined' error={true}>{mobileError}</FormHelperText>
            : (formActions.errors[name] && formActions.touched[name] &&
              <FormHelperText margin="dense" variant='outlined' error={true}>
                {formActions.errors[name]}
              </FormHelperText>
            )}
        </div>
      </FormControl>
      :
      <div className={classes.FormItem}>
        <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <div className={classes.PhoneInputWrapper}>
          <LanguageConsumer>
            {({ locale }) => (
              <PhoneInput
                placeholder={intl.formatMessage({ id: labelKey })}
                enableSearch={false}
                countryCodeEditable={false}
                disableDropdown={true}
                jumpCursorToEnd={true}
                onlyCountries={['eg']}
                country={'eg'}
                localization={locale === 'ar' ? ar : ''}
                specialLabel={''}
                excludeCountries={['il']}
                inputProps={{
                  name: name,
                  required: true,
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off'
                  }
                }}
                searchPlaceholder={intl.formatMessage({ id: 'PhoneInput.Search.placeholder' })}
                searchNotFound={intl.formatMessage({ id: 'PhoneInput.Search.noResults' })}
                onBlur={formActions.handleBlur}
                value={formActions.values[name]}
                helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
                error={formActions.errors[name] && formActions.touched[name] !== undefined}
                onChange={(phone) => {
                  formActions.setFieldValue(name, '+' + phone.toString())
                }}
                isValid={(value, country) => {
                  if (country.iso2 === 'eg' && value.length > 12) {
                    setMobileError(intl.formatMessage({ id: 'validationSchema.phoneError' }))
                    return false
                  } else {
                    setMobileError('')
                    return true
                  }
                }}
              />
            )}
          </LanguageConsumer>
          {!!mobileError ?
            <FormHelperText margin="dense" variant='outlined' error={true}>{mobileError}</FormHelperText>
            : (formActions.errors[name] && formActions.touched[name] &&
              <FormHelperText margin="dense" variant='outlined' error={true}>
                {formActions.errors[name]}
              </FormHelperText>
            )}
        </div>
      </div>
  )
}

export default FormPhoneField
