import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { useStyles } from '../FiltersStyles'
import { useFormik } from 'formik'
import FormTextField from '../../EntityPageContent/Form/Fields/FormTextField'
import FormSelectField from '../../EntityPageContent/Form/Fields/FormSelectField'
import FormPhoneField from '../../EntityPageContent/Form/Fields/FormPhoneField'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormEntityLoadField from '../../EntityPageContent/Form/Fields/FormEntityLoadField'

const SearchFilterForm = ({ searchFilters, initials, onSubmit }) => {
  const classes = useStyles()

  const filterForm = useFormik({
    initialValues: initials,
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })


  const searchFilterFieldSwitcher = (id, label, searchId, filter, searchEntity) => {
    switch (id) {
      case 'name':
      case 'title':
      case 'organization_id':
      case 'plate_number':
      case 'code':
      case 'imei':
        return <FormTextField key={id} formActions={filterForm} classes={classes} name={searchId || id} labelKey={label}
                              isSearch/>
      case 'email':
        return <FormTextField key={id} formActions={filterForm} classes={classes} name={searchId || id} labelKey={label}
                              email isSearch/>

      case 'gender':
      case 'serviceRequestType':
      case 'productsType':
      case 'calllogsType':
      case 'vehicleProductStatus':
      case 'ticketStatus':
      case 'state':
        return <FormSelectField key={id} formActions={filterForm} classes={classes} name={searchId || id}
                                labelKey={label} isSearch type={id}/>

      case 'mobile':
      case 'phone_number':
        return <FormPhoneField key={id} formActions={filterForm} classes={classes} name={searchId || id}
                               labelKey={label} isSearch/>
      case 'roles':
      case 'permissions':
        return <ListingProvider key={id} entityName={searchEntity || id} filter={filter}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={filterForm} classes={classes} name={searchId || id} list={listingData}
                                   loading={listingLoading} entityKey={'name'}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={label} multiple isSearch/>
            )}
          </ListingConsumer>
        </ListingProvider>

      case 'supervisor':
      case 'leader':
      case 'sales':
        return <ListingProvider key={id} entityName={'users'} filter={filter}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={filterForm} classes={classes} name={searchId || id} list={listingData}
                                   loading={listingLoading} entityKey={'id'}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={label} isSearch/>
            )}
          </ListingConsumer>
        </ListingProvider>

      case 'customer':
      case 'customer_contact':
      case 'brand':
      case 'product':
      case 'inventory':
      case 'vehicle':
      case 'operators':
      case 'ticket_category':
      case 'ticket_type':
      case 'form_template':
        return <ListingProvider key={id} entityName={searchEntity} filter={filter}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={filterForm} classes={classes} name={searchId || id} list={listingData}
                                   loading={listingLoading} entityKey={'id'}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={label} isSearch/>
            )}
          </ListingConsumer>
        </ListingProvider>

      default:
        return ''
    }
  }
  return (
    <form onSubmit={filterForm.handleSubmit} noValidate autoComplete="off" className={classes.searchFilter}>
      <FormTextField formActions={filterForm} classes={classes} name={'keyword'} labelKey={'Fields.Labels.keyword'}
                     isSearch/>
      {!!searchFilters.length && searchFilters.map(filterColumn => searchFilterFieldSwitcher(filterColumn.id, filterColumn.label, filterColumn.searchId, filterColumn.filter, filterColumn.searchEntity))}
      <Button
        variant='contained'
        color='primary'
        type='submit'
        onClick={filterForm.handleSubmit}
        className={classes.searchFilterSubmit}
      >
        <FormattedMessage id='SearchFilters.search'/>
      </Button>
    </form>
  )
}

export default SearchFilterForm
