import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button, InputAdornment } from '@material-ui/core'
import { AddNewProductFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import FormListAddMore from './Fields/FormListAddMore'
import FormSelectField from './Fields/FormSelectField'

const ProductsForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  /* eslint-disable no-unused-vars */
  const [imeisFilter, setImeisFilter] = React.useState(!!currentData?.id ? { product_id: currentData?.id } : null)
  /* eslint-enable no-unused-vars */
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      name: currentData?.name || '',
      type: currentData?.type || '',
      serial_number: currentData?.serial_number || '',
      brand_id: currentData?.brand?.id || '',
      cost: currentData?.cost || '',
      price: currentData?.price || '',
      description: currentData?.description || '',
      quantities: currentData?.quantities || [],
      imeis: currentData?.imeis || []
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: (currentAction === 'edit'),
    validationSchema: AddNewProductFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  const DecimalChange = (event, fieldName) => {
    const re = /^[0-9]*\.?[0-9]*$/
    if (event.target.value === '' || re.test(event.target.value)) {
      formActions.setFieldValue(fieldName, event.target.value)
    }
  }

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'name'} labelKey={'Fields.Labels.Name'}
                     required/>

      <FormSelectField formActions={formActions} classes={classes} name={'type'} labelKey={'Fields.Labels.type'}
                       required type={'productsType'}/>

      <FormTextField formActions={formActions} classes={classes} name={'serial_number'}
                     labelKey={'Fields.Labels.serial_number'}
                     required/>

      <ListingProvider entityName={'brands'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'brand_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.brand'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <FormTextField formActions={formActions} classes={classes} name={'cost'} labelKey={'Fields.Labels.cost'}
                     required onChange={(event) => DecimalChange(event, 'cost')}
                     otherAttributes={{
                       InputProps: {
                         endAdornment: <InputAdornment position="end">
                           <FormattedMessage id="VehiclePrice.pound"/>
                         </InputAdornment>
                       }
                     }}/>

      <FormTextField formActions={formActions} classes={classes} name={'price'} labelKey={'Fields.Labels.price'}
                     required onChange={(event) => DecimalChange(event, 'price')}
                     otherAttributes={{
                       InputProps: {
                         endAdornment: <InputAdornment position="end">
                           <FormattedMessage id="VehiclePrice.pound"/>
                         </InputAdornment>
                       }
                     }}/>

      <FormTextField formActions={formActions} classes={classes} name={'description'}
                     labelKey={'Fields.Labels.description'}
                     required row lines={2}/>

      {currentAction === 'edit' && formActions.values.type === 'configurable' &&
      <ListingProvider entityName={'imeis'} filter={imeisFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'imeis'}
                                 list={listingData} loading={listingLoading} allEntity entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.imeis'} row multiple addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>
      }
      {formActions.values.type === 'simple' &&
      <FormListAddMore labelKey={'Fields.Labels.quantities'} entityName={'product-quantities'}
                       listCRUD
                       formActions={formActions} classes={classes} parentEntityName={'products'}
                       onSubmit={onSubmit}
                       updateParentEntity={(value) => formActions.setFieldValue('quantities', value)}
                       list={currentData?.quantities || []}
      />
      }

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default ProductsForm
