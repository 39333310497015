import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  userMenuButton: {
    borderRadius: 0,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    '& span':{
      marginLeft: theme.spacing(1),
    }
  },
  menuUserName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.33,
    letterSpacing: ' 0.08px',
    textAlign: 'center',
    color: theme.palette.text.primary,
    display: 'block',
    marginBottom: theme.spacing(0.5),
    textDecoration: 'none',
    textTransform: 'capitalize',
    borderBottom: `1px solid ${theme.palette.colors.table}`,
    paddingTop: theme.spacing(3.25),
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  logoutButton: {
    borderRadius: theme.spacing(0),
    border: `none`,
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.43,
    textAlign: 'center',
    padding: theme.spacing(1.15, 7),
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    }
  }
}))
