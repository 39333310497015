import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TextField,
  FormControl,
  InputLabel, InputAdornment
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const FormNumberField = ({ formActions, classes, name, labelKey, required, isSearch, email }) => {
  const intl = useIntl()
  return (
    !!isSearch ?
      <FormControl className={classes.formControl} variant="outlined">
        <TextField
          label={intl.formatMessage({ id: labelKey })}
          id={name}
          type="number"
          margin="dense"
          variant="outlined"
          name={name}
          InputProps={{
            inputProps: {
              min: 1
            },
            startAdornment:
              <InputAdornment position="start">
                <SearchIcon/>
              </InputAdornment>
          }}
          InputLabelProps={{ shrink: !!formActions.values[name] }}
          onChange={formActions.handleChange}
          onBlur={formActions.handleBlur}
          value={formActions.values[name]}
          helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
          error={formActions.errors[name] && formActions.touched[name] !== undefined}
        />
      </FormControl>
      :
      <div className={classes.FormItem}>
        <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <FormControl variant="outlined">
          <TextField
            required={required}
            fullWidth
            margin="dense"
            type="number"
            name={name}
            id={name}
            InputProps={{
              inputProps: {
                min: 1
              }
            }}
            variant="outlined"
            placeholder={intl.formatMessage({ id: labelKey })}
            onChange={formActions.handleChange}
            onBlur={formActions.handleBlur}
            value={formActions.values[name]}
            helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
            error={formActions.errors[name] && formActions.touched[name] !== undefined}
          />
        </FormControl>
      </div>
  )
}

export default FormNumberField
