import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    color: theme.palette.primary.main,
    padding: theme.spacing(0),
    '& .MuiSvgIcon-root': {
      fontSize: theme.typography.pxToRem(21),
      color: theme.palette.common.black
    }
  },
  DialogWrapper: {
    '& .MuiDialogActions-root': {
      padding: 0
    },
    '& .MuiDialog-paperWidthSm': {
      width: '100%',
      margin: 0,
      maxWidth: 748
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0)
    },
    '& .MuiDialogTitle-root': {
      margin: theme.spacing(0),
      padding: theme.spacing(2.5, 2),
      borderBottom: `1px solid #e2e8ee`,
      background: '#F6F8F3',
      display: 'flex',
      alignItems: 'center'
    }
  },
  dialogTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.3,
    marginRight: theme.spacing(5)
  },
  actionButtonsWrapper: {
    padding: theme.spacing(2),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderTop: `1px solid ${fade(theme.palette.colors.border, 0.8)}`,
    justifyContent: 'flex-end',
    '& button': {
      lineHeight: 1.3,
      padding: theme.spacing(1.25, 2.75),
      borderRadius: theme.spacing(1.25),
      [theme.breakpoints.up('sm')]: {
        minWidth: 146
      },
      '& + button': {
        marginLeft: theme.spacing(2)
      }
    }
  }

}))
