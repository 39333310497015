import React from 'react'
import ListingTable from '../ListingTable'
import { Tabs, Tab } from '@material-ui/core'
import { ListingConsumer, ListingProvider } from '../../context/ListingContext'
import { FormLoader } from '../Loader/FormLoader'
import ListingServices from '../../Services/Consumers/ListingServices'

const TabPanel = (props) => {
  const { children, currentTab, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={currentTab !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {currentTab === index && children}
    </div>
  )
}

export const Reports = ({ endpoint }) => {
  const [currentTab, setCurrentTab] = React.useState(0)

  const [tables, setTables] = React.useState([])
  const getTabs = React.useCallback(async () => {
    const res = await ListingServices.getListingData('', null, '', endpoint)
    if (res?.status === 200) setTables(res.data || [])
  }, [endpoint])
  React.useEffect(() => {
    getTabs()
  }, [getTabs])

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        centered
      >
        {tables.map((table) => (
          <Tab label={`${table.title}`}/>
        ))}
      </Tabs>
      {tables.map((table, idx) => (
        <TabPanel currentTab={currentTab} index={idx}>
          <ListingProvider endpoint={table.endpoint}>
            <ListingConsumer>
              {({
                  listingCount,
                  listingData,
                  listingHeader,
                  listingLoading,
                  currentSelectedRow,
                  currentDialogAction,
                  handleListingFilterSubmit,
                  isDialogOpen,
                  openDialog,
                  cancelDialog,
                  dialogActionSubmit,
                  entityName,
                  loadEntityRole,
                  handleChangeListingSort
                }) => {
                return (
                  <div className={'listing-wrapper'}>
                    <ListingTable
                      rows={listingData}
                      columns={table.headers}
                      entityName={entityName}
                      handleChangeListingSort={handleChangeListingSort}
                      listingLoading={listingLoading}
                    />
                    {!!listingLoading && <FormLoader loading={true}/>}
                  </div>
                )
              }}
            </ListingConsumer>
          </ListingProvider>
        </TabPanel>
      ))}
    </>
  )
}
