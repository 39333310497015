/**
 * General Services class
 */
import RestClient from '../Client/RestClient'
import Configuration from '../Api/Configuration'
import CookiesStorageService from '../CookiesStorageService'

const { Admin_EndPoints } = Configuration

class AuthServices {

  static login (data, setFormLoading, FormikActions, defaultErrorMessage = '') {
    return RestClient.postRequest(Admin_EndPoints.Auth.login, data, null, setFormLoading, null, FormikActions, defaultErrorMessage)
  }

  static loginOut (setFormLoading, defaultErrorMessage = '') {
    return RestClient.postRequest(Admin_EndPoints.Auth.logout, {}, { headers: { 'Authorization': `Bearer ${CookiesStorageService.getAccessToken()}` } }, setFormLoading, null, null, defaultErrorMessage)
  }

  static getCurrentUser (defaultErrorMessage = '', setFailure) {
    return RestClient.postRequest(Admin_EndPoints.Auth.getUser, {}, { headers: { 'Authorization': `Bearer ${CookiesStorageService.getAccessToken()}` } }, null, setFailure, null, defaultErrorMessage)
  }

  static forgotPassword (data, setFormLoading, FormikActions, defaultErrorMessage = '') {
    return RestClient.postRequest(Admin_EndPoints.Auth.password.forgot, data, null, setFormLoading, null, FormikActions, defaultErrorMessage)
  }

  static changePassword (data, setFormLoading, FormikActions, defaultErrorMessage = '') {
    return RestClient.postRequest(Admin_EndPoints.Auth.password.reset, data, null, setFormLoading, null, FormikActions, defaultErrorMessage)
  }

  static getBootData (defaultErrorMessage = '') {
    return RestClient.getRequest(Admin_EndPoints.boot, { headers: { 'Authorization': `Bearer ${CookiesStorageService.getAccessToken()}` } }, defaultErrorMessage)
  }

  static refreshToken () {
    return RestClient.postRequest(Admin_EndPoints.Auth.refreshToken, { 'refresh_token': CookiesStorageService.getRefreshToken() }, null, null, null, null, '')
  }


}

export default AuthServices
