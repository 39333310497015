import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Chip, Grid, Tooltip } from '@material-ui/core'
import { useStyles } from '../../../utils/FormStyles'
import { LanguageConsumer } from '../../../context/LanguageContext'
import Moment from 'react-moment'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import CustomerTabs from './CustomerTabs'

const ViewCustomer = ({ listingHeader, currentData }) => {
  const classes = useStyles()
  const tooltipValue = (value, fields) => {
    let intersection = fields.filter(x => Object.keys(value || {}).includes(x))
    return !!intersection?.length ? (!!fields?.length && <Tooltip
      title={
        <React.Fragment>
          {fields.map((userProp, index) => (
            !!value[userProp] &&
            <div key={index} className={classes.userDataWrapper}>
              <label><FormattedMessage id={`Fields.Labels.${userProp}`}/>:</label> <span>{value[userProp]}</span>
            </div>
          ))}
        </React.Fragment>
      }
      arrow
    >
      <span>{value?.name || ''}</span>
    </Tooltip>) : value?.name || ''
  }
  const getColumnValue = (key, value) => {
    if (!!key) {
      switch (key) {
        case 'operators':
          return !!value && !!value.length &&
            value.map((role, index) => (
              !!role?.name &&
              <Chip key={index} className={classes.TableChip} variant="outlined" color="primary"
                    label={role?.name || ''}/>
            ))
        case 'sales':
          return !!value && !!value?.name && tooltipValue(value, ['email', 'mobile', 'gender'])

        case 'customersType':
          return !!value && !!value?.name && <>{value?.name || ''}</>

        case 'authorized_contact':
          if (!!value) {
            value['customerName'] = value?.customer?.name || ''
          }
          return !!value && !!value?.name && tooltipValue(value, ['email', 'mobile', 'nid_number', 'customerName', 'description'])

        case 'verified':
          return !!value && (value?.toString() === '1') ? <CheckCircleIcon color={'primary'}/> :
            <CancelIcon color={'error'}/>

        case 'created_at':
          return <LanguageConsumer>
            {({ locale }) => (
              <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                      format="DD MMMM YYYY, h:mm A">{value}</Moment>
            )}
          </LanguageConsumer>
        default:
          return value
      }
    } else {
      return ''
    }
  }
  return (
    <>
      <div className={classes.EntityInfoContainer}>
        <div className={classes.EntityInfoWrapper}>
          {listingHeader.map((column, index) => (
            !!currentData[column.id] &&
            <Grid item xs={12} sm={6} md={6} key={index} className={classes.infoItemWrapper}>
              <Grid item xs={12} sm={5} className={classes.infoItemLabel}>
                {!!column.label && <FormattedMessage id={column.label}/>}
              </Grid>
              <Grid item xs={12} sm={7} className={classes.infoItemValue}>
                {getColumnValue(column.id, currentData[column.id])}
              </Grid>
            </Grid>
          ))}
        </div>
      </div>

      <CustomerTabs entities={['contacts', 'calllogs', 'tickets']}
                    filter={{
                      'contacts': { customer_id: currentData.id },
                      'calllogs': { customer_id: currentData.id },
                      'tickets': { customer_id: currentData.id }
                    }}/>

      <CustomerTabs entities={['service-requests', 'installation-orders-list', 'maintenance-orders-list']}
                    filter={{
                      'service-requests': { customer_id: currentData.id },
                      'installation-orders-list': { customer: currentData.id },
                      'maintenance-orders-list': { customer: currentData.id }
                    }}/>

    </>
  )
}

export default ViewCustomer
