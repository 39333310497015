import React from 'react'
import { toast } from 'react-toastify'
import NotificationAlert from '../components/NotificationAlert'

export const DisplayFormikState = (props) => {
  return (
    <div style={{
      position: 'sticky',
      top: 75.67,
      zIndex: 10
    }}>
      <pre
        style={{
          background: '#f6f8fa',
          padding: '.5rem',
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
          margin: 0
        }}
      >
      <strong>props</strong> ={' '}
        {JSON.stringify(props, null, 2)}
    </pre>
    </div>
  )
}

/**
 * Log function works only in development and removed in production
 * @param args
 */
export const log = (...args) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

export const randomKey = Math.random().toString(36).substr(2, 9)

export const isEmpty = (object) => {
  return !Object.getOwnPropertySymbols(object).length && !Object.getOwnPropertyNames(object).length
}


/**
 * notificationProps: {
 *  type: `One of: 'e-commerce','notification','offer', 'info','message','feedback','customer','shipped','done'
                    'delivered','defence','report','upload','disc'`
 *  message: message,
 *  color: `One of theme colors: 'error', 'success', 'info' , 'warning' ... etc
 * }
 * Set the toastType.
 * `One of: 'info', 'success', 'warning', 'error', 'default'`
 */
export const handleNotificationAlert = (notificationProps, toastType) => {
  return toast(
    <NotificationAlert
      {...notificationProps}
      variant='contained'
      className={'notification-component'}
    />,
    {
      type: toastType,
      position: toast.POSITION.BOTTOM_LEFT,
      progressClassName: 'notification-progress-wrapper',
      className: 'notification-wrapper'
    }
  )
}

/** Method to Filter the Empty params before send the API Request.
 *
 * @param data
 * @returns {{}}
 */
export const filterData = (data) => {
  if (data instanceof FormData) {
    return data
  } else {
    let newData = {}
    Object.keys(data).forEach((key => {
      const isString = typeof data[key] === 'string' && data[key].length
      const isArray = Array.isArray(data[key]) && data[key].length
      const isNumber = Number.isInteger(data[key])
      const isDate = data[key] instanceof Date
      const isBoolean = typeof data[key] === 'boolean' && data[key] === !!data[key]
      if (isString || isArray || isNumber || isBoolean) {
        newData[key] = data[key]
      }
      if (isDate) {
        let date = new Date(data[key])
        let dd = String(date.getDate()).padStart(2, '0')
        let mm = String(date.getMonth() + 1).padStart(2, '0') //January is 0!
        let yyyy = date.getFullYear()
        newData[key] = dd + '-' + mm + '-' + yyyy
      }
    }))
    return newData
  }
}

export const parseLocaleNumber = (num) => {
  // Detect the user's locale decimal separator:
  let decimalSeparator = (1.1).toLocaleString().substring(1, 2)
  // Detect the user's locale thousand separator:
  let thousandSeparator = (1000).toLocaleString().substring(1, 2)
  // In case there are locales that don't use a thousand separator
  if (thousandSeparator.match(/\d/))
    thousandSeparator = ''

  num = num.replace(thousandSeparator, '').replace(decimalSeparator, '.')

  return parseFloat(num)
}

export const formatPhoneNumber = (str) => {
  if (!!str) {
    let match = str.replace(/ /g, '').match(/.{1,3}/g)
    match[0] = '(' + match[0] + ')'
    return match.reduce((previous, current, index, array) => {
      if (index > 3) {
        return previous + '' + current
      } else {
        return previous + ' ' + current
      }
    })
  }
}
