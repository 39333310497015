import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  menuButton: {
    '& svg': {
      fontSize: theme.typography.pxToRem(32),
      fontWeight: 600
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.common.white,
      outline: 'none'
    }
  },
  navigationDrawerWrapper: {
    '& .MuiPaper-root': {
      background: 'transparent'
    }
  },
  navigationDrawer: {
    width: 300,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(6.25, 2),
    flex: '1 1 auto',
    position: 'relative',
    '& nav': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
      '& a': {
        fontSize: theme.typography.pxToRem(20),
        margin: theme.spacing(0)
      }
    }
  },
  menuCloseButton: {
    color: theme.palette.common.white + ' !important',
    position: 'absolute !important',
    top: 0,
    right: theme.spacing(2),
    '& svg': {
      fontSize: theme.typography.pxToRem(42),
      fontWeight: 600
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      color: theme.palette.common.white,
      outline: 'none'
    }
  },
  navigationDrawerUserMenu: {
    borderTop: `1px solid ${theme.palette.common.white}`,
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  username: {
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.common.white,
    width: '100%',
    textAlign: 'center',
    display: 'block'
  },
  userNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  menuUserName: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.33,
    letterSpacing: ' 0.08px',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(0.5),
    textDecoration: 'none',
    textTransform: 'capitalize',
    paddingTop: theme.spacing(3.25),
    paddingBottom: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: theme.typography.pxToRem(24),
      marginRight: theme.spacing(2.5)
    },
    '&:hover,&:focus': {
      color: theme.palette.text.primary
    }
  },
  logoutButton: {
    borderRadius: theme.spacing(0),
    border: `none`,
    color: theme.palette.common.white,
    backgroundColor: 'transparent',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.43,
    padding: theme.spacing(1.15, 0),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& svg': {
      fontSize: theme.typography.pxToRem(24),
      marginRight: theme.spacing(2.5)
    },
    '&:hover,&:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent'
    }
  }
}))
