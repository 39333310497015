import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewInstallationOrderProductsFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const productsFilter = { 'type': 'configurable' }
const productsSimpleFilter = { 'type': 'simple' }
const InstallationOrderProductsForm = ({ onSubmit, currentData, cancelDialog, currentAction, updateParentEntity, otherUpdateParentEntity, parentEntityName, injectedFormValues }) => {
  const classes = useStyles()
  const [productImeisFilter, setProductImeisFilter] = React.useState({})
  /* eslint-disable no-unused-vars */
  const [sensorsFilter, setOperatorsFilter] = React.useState({ 'type': 'simple', ...(!!currentData?.sensors?.length ? { id: currentData?.sensors?.map(item => item.id) || null } : {}) })
  /* eslint-enable no-unused-vars */
  const formActions = useFormik({
    initialValues: {
      id: '',
      installation_order_id: '',
      product: {},
      imei: {},
      // quantity: '',
      vehicle: {},
      operator_id: '',
      sensor_ids: [],
      sim_number: '',
      sim_serial: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewInstallationOrderProductsFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, false, null, true, null, updateParentEntity, parentEntityName)
    })
  })
  React.useEffect(() => {
      if (!!Object.keys(injectedFormValues || {}).length) {
        formActions.setFieldValue('installation_order_id', injectedFormValues['installation-orders'] || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [injectedFormValues])

  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.resetForm()
        setProductImeisFilter({ 'product_id': currentData?.product?.id || '' })
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('product', currentData?.product || '')
        formActions.setFieldValue('quantity', currentData?.quantity || '')
        formActions.setFieldValue('imei', { ...currentData?.imei, product: currentData?.product || {} } || '')
        formActions.setFieldValue('vehicle', currentData?.vehicle || '')
        formActions.setFieldValue('operator_id', currentData?.operator?.id || '')
        formActions.setFieldValue('sensor_ids', !!currentData?.sensors?.length ? currentData?.sensors?.map(item => item.id) : [])
        formActions.setFieldValue('sim_number', currentData?.sim_number || '')
        formActions.setFieldValue('sim_serial', currentData?.sim_serial || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])

  const handleProductImeisFilter = (value) => {
    if (value?.type === 'configurable') {
      setProductImeisFilter({ 'product_id': value?.id || '' })
    }
  }
  const NumbersChange = (event, fieldName) => {
    const re = /^[0-9]+$/
    if (event.target.value === '' || re.test(event.target.value)) {
      formActions.setFieldValue(fieldName, event.target.value)
    }
  }
  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <ListingProvider entityName={'products'} filter={productsFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'product'}
                                 list={listingData} loading={listingLoading} allEntity entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 setFilter={handleProductImeisFilter}
                                 labelKey={'Fields.Labels.product'} required/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'imeis'} filter={productImeisFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'imei'}
                                 disabled={!formActions.values.product?.id} allEntity
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.imei'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'vehicles'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'vehicle'}
                                 list={listingData} loading={listingLoading} allEntity entityKey={'id'}
                                 searchable={'id'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.vehicle'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>
      <ListingProvider entityName={'operators'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'operator_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.operator'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'products'} filter={productsSimpleFilter} initFilter={sensorsFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'sensor_ids'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.sensors'} required multiple addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <FormTextField formActions={formActions} classes={classes} name={'sim_number'}
                     labelKey={'Fields.Labels.sim_number'} required
                     onChange={(event) => NumbersChange(event, 'sim_number')}/>

      <FormTextField formActions={formActions} classes={classes} name={'sim_serial'}
                     labelKey={'Fields.Labels.sim_serial'}
                     required/>


      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default InstallationOrderProductsForm
