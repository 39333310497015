import React from "react";
import { Reports } from "../Reports";
import AccountLayout from "../../components/AccountLayout";
import { AuthConsumer } from "../../context/AuthContext";
import { FullBodyLoader } from "../../components/Loader/FullBodyLoader";
import { useIntl } from 'react-intl'
import { SEO } from '../../components/Seo/seo'

const ReportsPageContent = () => {
    const intl = useIntl()
    return (
      <>
        <SEO
          title={intl.formatMessage({ id: "Page.title."  })}
          pathname={window.location.origin}
          titleTemplate={intl.formatMessage({ id: "QHub" })}
          description={intl.formatMessage({ id: "Page.title."  })}
        />
        <AccountLayout
          content={
            <AuthConsumer>
              {({ isAuth }) =>
                !!isAuth ? (
                  <Reports endpoint="/reports/headers" />
                ) : (
                  <FullBodyLoader loading={true} />
                )
              }
            </AuthConsumer>
          }
        />
      </>
    );
};

export default ReportsPageContent;
