import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  Chip,
  MenuItem
} from '@material-ui/core'

const FormSelectField = ({ formActions, classes, name, labelKey, label, list, required, isSearch, type, multiple, onChange, placeholder, row, disabled, otherAttributes }) => {
  const intl = useIntl()
  const [exactList, setExactList] = React.useState([])

  const genderList = [
    { key: 'male', name: intl.formatMessage({ id: 'gender.male' }) },
    { key: 'female', name: intl.formatMessage({ id: 'gender.female' }) }
  ]
  const serviceRequestTypes = [
    { key: 'installation', name: intl.formatMessage({ id: 'serviceRequestType.installation' }) },
    { key: 'maintenance', name: intl.formatMessage({ id: 'serviceRequestType.maintenance' }) }
  ]
  const statusList = [
    { key: 'assigned', name: intl.formatMessage({ id: 'status.assigned' }) },
    { key: 'overdue', name: intl.formatMessage({ id: 'status.overdue' }) },
    { key: 'closed', name: intl.formatMessage({ id: 'status.closed' }) }
  ]
  const statusTrackList = [
    { key: 'pending', name: intl.formatMessage({ id: 'statusTrack.pending' }) },
    { key: 'solving', name: intl.formatMessage({ id: 'statusTrack.solving' }) },
    { key: 'solved', name: intl.formatMessage({ id: 'statusTrack.solved' }) }
  ]
  const solvedByList = [
    { key: 'part_change', name: intl.formatMessage({ id: 'solvedBy.part_change' }) },
    { key: 'configuration', name: intl.formatMessage({ id: 'solvedBy.configuration' }) },
    { key: 'device_replacement', name: intl.formatMessage({ id: 'solvedBy.device_replacement' }) }
  ]
  const productTypes = [
    { key: 'simple', name: intl.formatMessage({ id: 'productTypes.simple' }) },
    { key: 'configurable', name: intl.formatMessage({ id: 'productTypes.configurable' }) }
  ]
  const vehicleProductStatus = [
    { key: 'installed', name: intl.formatMessage({ id: 'vehicleProductStatus.installed' }) },
    { key: 'uninstalled', name: intl.formatMessage({ id: 'vehicleProductStatus.uninstalled' }) }
  ]
  const callLogTypes = [
    { key: 'ask_for_product', name: intl.formatMessage({ id: 'callLogTypes.ask_for_product' }) },
    { key: 'ask_for_sales', name: intl.formatMessage({ id: 'callLogTypes.ask_for_sales' }) },
    { key: 'update', name: intl.formatMessage({ id: 'callLogTypes.update' }) },
    { key: 'inquiry', name: intl.formatMessage({ id: 'callLogTypes.inquiry' }) }
  ]
  const ticketStatusList = [
    { key: 'open', name: intl.formatMessage({ id: 'ticketStatus.open' }) },
    { key: 'closed', name: intl.formatMessage({ id: 'ticketStatus.closed' }) }
  ]
  const statesList = [
    { key: 'on_track', name: intl.formatMessage({ id: 'ticketState.on_track' }) },
    { key: 'may_be_late', name: intl.formatMessage({ id: 'ticketState.may_be_late' }) },
    { key: 'urgent', name: intl.formatMessage({ id: 'ticketState.urgent' }) },
    { key: 'overdue', name: intl.formatMessage({ id: 'ticketState.overdue' }) }
  ]
  const customFieldTypes = [
    { key: 'select', name: intl.formatMessage({ id: 'form-templates.select' }) },
    { key: 'multi_select', name: intl.formatMessage({ id: 'form-templates.multiselect' }) },
    { key: 'text', name: intl.formatMessage({ id: 'form-templates.text' }) },
    { key: 'date', name: intl.formatMessage({ id: 'form-templates.date' }) },
    { key: 'text_area', name: intl.formatMessage({ id: 'form-templates.textarea' }) }
  ]

  const getExactList = () => {
    if (!!type) {
      switch (type) {
        case 'gender':
          setExactList(genderList)
          break
        case 'serviceRequestType':
          setExactList(serviceRequestTypes)
          break
        case 'status':
          setExactList(statusList)
          break
        case 'productsType':
          setExactList(productTypes)
          break
        case 'vehicleProductStatus':
          setExactList(vehicleProductStatus)
          break
        case 'calllogsType':
          setExactList(callLogTypes)
          break
        case 'ticketStatus':
          setExactList(ticketStatusList)
          break
        case 'statusTrack':
          setExactList(statusTrackList)
          break
        case 'solvedBy':
          setExactList(solvedByList)
          break
        case 'state':
          setExactList(statesList)
          break
        case 'customFieldTypes':
          setExactList(customFieldTypes)
          break
        default:
          setExactList(list)
          break
      }
    } else {
      setExactList(list)
    }
  }
  React.useEffect(() => {
      getExactList()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
  React.useEffect(() => {
      if (!!list?.length) {
        getExactList()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [list])

  const handleSelectChange = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    formActions.setFieldValue(name, event.target.value.toString())
  }

  let renderValue = {}
  if (!!multiple) {
    renderValue = {
      renderValue: (selected) => (
        <div className={classes.chips}>
          {selected.map((value) => (
            <Chip key={value} label={value} className={classes.chip}/>
          ))}
        </div>
      )
    }
  }

  return (
    !!isSearch ?
      <FormControl variant="outlined" margin={'dense'} className={classes.formControl + ' select-control'}>
        <InputLabel id={name + '-label'}><FormattedMessage id={labelKey}/></InputLabel>
        <Select
          fullWidth
          // displayEmpty
          labelId={name + '-label'}
          disabled={!!disabled}
          id={name}
          name={name}
          multiple={multiple}
          MenuProps={{ disableScrollLock: true }}
          value={!!exactList?.length ? formActions.values[name] || '' : ''}
          label={label ?? intl.formatMessage({ id: labelKey })}
          onChange={onChange ?? handleSelectChange}
          onBlur={formActions.handleBlur}
          error={formActions.errors[name] && formActions.touched[name] !== undefined}
          {...renderValue}
          {...otherAttributes}
        >
          <MenuItem value={''}>{placeholder ?? (label ?? <FormattedMessage id={labelKey}/>)}</MenuItem>
          {!!exactList?.length && exactList.map((item, index) => (
            <MenuItem key={item.key || index}
                      value={item.key || item.name || item.value}>{item.name || item.value}</MenuItem>))}
        </Select>
        {formActions.errors[name] && formActions.touched[name] && (
          <FormHelperText error={true}>{formActions.errors[name]}</FormHelperText>
        )}
      </FormControl>
      :
      <div className={classes.FormItem + (!!row ? ` ${classes.FormRow}` : '')}>
        <InputLabel id={name + '-label'} htmlFor={name}>{label ?? <FormattedMessage id={labelKey}/>}{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <FormControl variant="outlined" className={classes.FormItemSelect}>
          <Select
            fullWidth
            displayEmpty
            labelId={name + '-label'}
            disabled={!!disabled}
            id={name}
            name={name}
            multiple={multiple}
            margin={'dense'}
            variant='outlined'
            MenuProps={{ disableScrollLock: true }}
            value={!!exactList?.length ? formActions.values[name] || '' : ''}
            // label={intl.formatMessage({ id: 'Fields.Labels.Role' })}
            onChange={onChange ?? handleSelectChange}
            onBlur={formActions.handleBlur}
            error={formActions.errors[name] && formActions.touched[name] !== undefined}
            {...renderValue}
            {...otherAttributes}
          >
            <MenuItem value={''}>{placeholder ?? (label ?? <FormattedMessage id={labelKey}/>)}</MenuItem>
            {!!exactList?.length && exactList.map((item, index) => (
              <MenuItem key={item.key || index}
                        value={item.key || item.name || item.value}>{item.name || item.value}</MenuItem>))}
          </Select>
          {formActions.errors[name] && formActions.touched[name] && (
            <FormHelperText error={true}>{formActions.errors[name]}</FormHelperText>
          )}
        </FormControl>
      </div>
  )
}

export default FormSelectField
