import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  IconButton,
  Accordion, AccordionSummary, AccordionDetails, Grid,
  Typography, Button, Tooltip
} from '@material-ui/core'
import { ListingConsumer, ListingProvider } from '../../../../context/ListingContext'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddBoxIcon from '@material-ui/icons/AddBox'
import FormConfirmation from '../FormConfirmation'
import AddNewDialog from '../../../SearchBar/Dialog/AddNewDialog'
import AddNewForm from '../index'
import { LanguageConsumer } from '../../../../context/LanguageContext'
import Moment from 'react-moment'
import EditIcon from '@material-ui/icons/Edit'
import PrintIcon from '@material-ui/icons/Print'
import HistoryIcon from '@material-ui/icons/History'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import DeleteEntity from '../../../DeleteEntity'
import { FormLoader } from '../../../Loader/FormLoader'
import domtoimage from 'dom-to-image'
import jsPDF from 'jspdf'

const FormListAddMore = ({ formActions, classes, labelKey, currentData, entityName, parentEntityName, onSubmit, injectedFormValues, handleInjectedFormValues, list, initFilter, type, updateParentEntity, updateParentEntityOnly, otherUpdateParentEntity, remaining, recursiveChildren, listCRUD, disableAdd, disableEdit, disableDelete, print, statusUpdate, checkStatusUpdateKey, disableStatusAdd, noMarginTop, printContent: Component, children }) => {
  const intl = useIntl()
  const [isConfirmView, setIsConfirmView] = React.useState(false)
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false)
  const handleConfirmation = (e) => {
    !!e?.preventDefault && e.preventDefault()
    !!e?.stopPropagation && e.stopPropagation()
    if (!isConfirmOpen) {
      formActions?.setSubmitting(false)
      setIsConfirmView(true)
    } else {
      setTimeout(() => setIsConfirmView(false), 500)
    }
    setIsConfirmOpen(!isConfirmOpen)
  }

  const [pdfLoading, setPdfLoading] = React.useState(false)
  const [currentPrintedItem, setCurrentPrintedItem] = React.useState(null)
  const generatePDF = async (e, item) => {
    setCurrentPrintedItem(item)
    !!e?.preventDefault && e.preventDefault()
    !!e?.stopPropagation && e.stopPropagation()
    setTimeout(async () => {
      setPdfLoading(true)
      const ids = ['contentPdfContent']
      const pdfPages = await generatePDFPages(ids, item)

      pdfPages.autoPrint({ variant: 'non-conform' })
      const printBlob = window.URL.createObjectURL(pdfPages.output('blob'))
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: printBlob
      }).click()
      window.URL.revokeObjectURL(printBlob)
      setPdfLoading(false)
    }, 50)
  }

  const generatePDFPages = async (ids, item) => {
    const pdf = new jsPDF('p', 'px', 'a4', true)
    pdf.setProperties({
      title: intl.formatMessage({ id: `${entityName}.Print.Title` }, { id: item?.id }),
      subject: intl.formatMessage({ id: `${entityName}.Print.Title` }, { id: item?.id }),
      author: 'QHUB',
      creator: 'QHUB'
    })
    const pdfWidth = pdf.internal.pageSize.getWidth()

    const pagesContent = ids.map((id) => {
      const node = document.getElementById(id)
      return !!node ? domtoimage.toPng(node) : false
    })
    await Promise.all(pagesContent)
    pagesContent.forEach((pdfPage, index) =>
      !!pdfPage && pdfPage.then(function(dataUrl) {
        if (index !== 0) pdf.addPage()
        const imgProps = pdf.getImageProperties(dataUrl)
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST')
      }))
    setCurrentPrintedItem(null)
    return pdf
  }

  const tooltipValue = (value, fields) => {
    let intersection = fields.filter(x => Object.keys(value || {}).includes(x))
    return !!intersection?.length ? (!!fields?.length && <Tooltip
      title={
        <React.Fragment>
          {fields.map((userProp, index) => (
            !!value[userProp] &&
            <div key={index} className={classes.userDataWrapper}>
              <label><FormattedMessage id={`Fields.Labels.${userProp}`}/>:</label>
              <span>{value[userProp]}</span>
            </div>
          ))}
        </React.Fragment>
      }
      arrow
    >
      <span>{value?.name || ''}</span>
    </Tooltip>) : value?.name || ''
  }
  const getColumnValue = (key, value) => {
    if (!!key) {
      switch (key) {
        case 'start_date':
        case 'end_date':
          return <LanguageConsumer>
            {({ locale }) => (
              <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                      format="DD MMMM YYYY, h:mm A">{value}</Moment>
            )}
          </LanguageConsumer>
        case 'is_needs_visit':
          return <FormattedMessage id={`needs_visit.${value || 0}`}/>

        case 'technician':
          return !!value && !!value?.name && tooltipValue(value, ['email', 'mobile', 'gender'])

        case 'inventory':
          return !!value && !!value?.name && tooltipValue(value, ['id'])

        case 'product':
          return !!value && !!value?.name && tooltipValue(value, ['id', 'productsType', 'serial_number', 'cost', 'price'])

        case 'imei':
          return !!value && !!value?.imei && tooltipValue(value, ['id', 'inventoryName'])

        case 'vehicle':
          let newName = []
          newName.push(value?.brand?.name || '')
          newName.push(value?.chassis_number || '')
          newName.push(value?.plate_number || '')
          value['name'] = newName.join(' - ')
          return !!value && !!value?.name && tooltipValue(value, ['id', 'model_year', 'motor_number'])

        default:
          return value
      }
    } else {
      return ''
    }
  }
  return (
    <ListingProvider entityName={entityName} listArr={list} listCRUD={listCRUD} initFilter={initFilter}>
      <ListingConsumer>
        {({ listingData, listingLoading, dialogActionSubmit, currentSelectedRow, currentDialogAction, isDialogOpen, cancelDialog, openDialog, listingHeader, updateCurrentSelectedItem }) => (
          <div className={classes.FormRow + ' ' + classes.FormRowList + `${!!noMarginTop ? ' noMarTop' : ''}`}>
            <div className={classes.FormListHeader}>
              <Typography variant="h5" component="h3"><FormattedMessage id={labelKey}/></Typography>
              {!disableAdd &&
              <Button color='primary' variant='contained'
                      startIcon={<AddBoxIcon/>}
                      onClick={(e) => {
                        !!e?.preventDefault && e.preventDefault()
                        !!e?.stopPropagation && e.stopPropagation()
                        !!listCRUD ? openDialog() : (!!currentData?.id ? openDialog() : handleConfirmation())
                        if (!!handleInjectedFormValues) {
                          handleInjectedFormValues(parentEntityName, currentData?.id)
                        }
                      }}
              >
                <FormattedMessage id={`EntityPageContent.${entityName}.SearchBar.dialogTitle.create`}/>
              </Button>
              }
              {!!isConfirmView &&
              <AddNewDialog
                children={<FormConfirmation
                  name={formActions?.values?.name}
                  cancelDialog={handleConfirmation}
                  loading={formActions.isSubmitting}
                  entityName={parentEntityName}
                  onSubmit={() => {
                    if (!!formActions.isValid) {
                      formActions.setSubmitting(true)
                      onSubmit(formActions.values, formActions, false, null, true, openDialog, updateParentEntity, parentEntityName, handleConfirmation)
                    } else {
                      handleConfirmation()
                      formActions.handleSubmit(formActions.values, formActions)
                    }
                  }}/>}
                dialogAction={'confirm'}
                DialogTitle={<FormattedMessage
                  id={`EntityConfirm.${parentEntityName}.dialogTitle`}
                  values={{ name: formActions?.values?.name || formActions?.values?.id || '' }}/>}
                isDialogOpen={isConfirmOpen}
                cancelDialog={handleConfirmation}
              />
              }
              <AddNewDialog
                children={(currentDialogAction === 'delete') ?
                  !listCRUD && <DeleteEntity/>
                  : <AddNewForm entityName={entityName}
                                remaining={remaining}
                                updateParentEntity={updateParentEntity}
                                updateParentEntityOnly={updateParentEntityOnly}
                                otherUpdateParentEntity={otherUpdateParentEntity}
                                disableStatusAdd={disableStatusAdd}
                                type={type}
                                injectedFormValues={injectedFormValues}
                                handleInjectedFormValues={handleInjectedFormValues}
                                currentData={currentSelectedRow}
                                parentEntityName={parentEntityName}
                                onSubmit={dialogActionSubmit}
                                currentAction={currentDialogAction}
                                cancelDialog={cancelDialog}/>}
                dialogAction={currentDialogAction}
                DialogTitle={<FormattedMessage
                  id={`EntityPageContent.${entityName}.SearchBar.dialogTitle.${currentDialogAction}`}
                  values={{ name: currentSelectedRow?.name || currentSelectedRow?.id || '' }}/>}
                isDialogOpen={isDialogOpen}
                cancelDialog={cancelDialog}
              />
            </div>
            {!!listingData?.length &&
            <div className={classes.EntityListContainer}>
              {!!print && <>
                {!!pdfLoading && <FormLoader loading={true}/>}
                {!!Component && <Component content={currentPrintedItem} entityName={entityName}/>}
              </>
              }
              {listingData?.map((item) => (
                <Accordion key={item.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={classes.ItemHeadingWrapper}>
                      <Typography className={classes.ItemHeading}>{item?.icon}{item?.headerTitle}</Typography>
                      <div className={classes.EntityActionsWrapper}>
                        {!!statusUpdate && !!item[checkStatusUpdateKey] &&
                        <Button size={'small'} color="primary" variant={'outlined'} startIcon={<HistoryIcon/>}
                                className={classes.statusButton} aria-label="Status" onClick={(e) => {
                          !!e?.preventDefault && e.preventDefault()
                          !!e?.stopPropagation && e.stopPropagation()
                          openDialog('status', item, item.id)
                        }}>
                          <FormattedMessage id={`statusTrack.${item?.status_track || 'pending'}`}/>
                        </Button>
                        }
                        {!!print &&
                        <IconButton color="primary" aria-label="Print" onClick={e => generatePDF(e, item)}>
                          <PrintIcon/>
                        </IconButton>
                        }

                        {!disableEdit &&
                        <IconButton color="primary" aria-label="Edit" onClick={(e) => {
                          !!e?.preventDefault && e.preventDefault()
                          !!e?.stopPropagation && e.stopPropagation()
                          openDialog('edit', item, item.id)
                          if (!!handleInjectedFormValues) {
                            handleInjectedFormValues(parentEntityName, currentData?.id)
                          }
                        }}>
                          <EditIcon/>
                        </IconButton>
                        }
                        {!disableDelete &&
                        <IconButton color="secondary" aria-label="Edit" onClick={(e) => {
                          !!e?.preventDefault && e.preventDefault()
                          !!e?.stopPropagation && e.stopPropagation()
                          !!listCRUD ? openDialog('delete', item, item.id, null, updateParentEntity) : openDialog('delete', item, item.id)
                        }}>
                          <DeleteForeverIcon/>
                        </IconButton>
                        }
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.EntityInfoContainer}>
                      <div className={classes.EntityInfoWrapper}>
                        {listingHeader?.map((column, index) => (
                          !!item[column.id] && !['icon', 'headerTitle', 'actions'].includes(column.id) &&
                          <Grid item xs={12} sm={6} md={6} key={index} className={classes.infoItemWrapper}>
                            <Grid item xs={12} sm={5} className={classes.infoItemLabel}>
                              {!!column.label && <FormattedMessage id={column.label}/>}
                            </Grid>
                            <Grid item xs={12} sm={7} className={classes.infoItemValue}>
                              {getColumnValue(column.id, item[column.id])}
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                      {!!recursiveChildren?.length && recursiveChildren.map(child =>
                        <FormListAddMore key={item.id}
                                         labelKey={child.labelKey}
                                         entityName={child.entityName}
                                         currentData={item}
                                         print={child.print}
                                         printContent={child.printContent}
                                         statusUpdate={child.statusUpdate}
                                         disableStatusAdd={child.disableStatusAdd}
                                         checkStatusUpdateKey={child.checkStatusUpdateKey}
                                         remaining={child.remaining}
                                         formActions={formActions} classes={classes} parentEntityName={entityName}
                                         onSubmit={dialogActionSubmit} updateParentEntity={updateCurrentSelectedItem}
                                         updateParentEntityOnly={updateParentEntityOnly}
                                         injectedFormValues={injectedFormValues}
                                         handleInjectedFormValues={handleInjectedFormValues}
                                         list={item[child.listItemKey] || []}
                        />)}
                      {children}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
            }
            {!!listingLoading && <FormLoader loading={true}/>}
          </div>
        )}
      </ListingConsumer>
    </ListingProvider>
  )
}

export default FormListAddMore
