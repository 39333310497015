import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  actionMenuButton: {
    padding: theme.spacing(0)
  },
  buttonAction: {
    borderRadius: theme.spacing(0),
    border: `none`,
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.43,
    textAlign: 'center',
    padding: theme.spacing(1.5, 2.5),
    width: '100%',
    justifyContent: 'flex-start',
    borderBottom: `1px solid ${theme.palette.colors.border}`,
    '& i, & svg': {
      marginRight: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14)
    },
    '&:hover,&:focus': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent'
    }
  }
}))
