import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewTicketUserCaseFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import FormTextField from './Fields/FormTextField'
import FormSelectField from './Fields/FormSelectField'
import { LanguageConsumer } from '../../../context/LanguageContext'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import FormDateField from './Fields/FormDateField'

const TicketUserCaseForm = ({ onSubmit, currentData, additionalFields, cancelDialog, currentAction, updateParentEntity }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: '',
      status: '',
      ...additionalFields
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewTicketUserCaseFormValidation(currentData?.additional_fields),
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })
  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('status', currentData?.status || '')
        currentData?.additional?.forEach(field =>
          formActions.setFieldValue(field?.name, field?.value || '')
        )
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])

  const [fieldDate, setFieldDate] = React.useState({})
  const handleDateChange = (date, name) => {
    setFieldDate(prevState => ({ ...prevState, [name]: date }))
    formActions.setFieldValue(name, date || '')
  }

  const getAdditionalField = (field) => {
    switch (field?.type) {
      case 'text':
        return <FormTextField key={field?.name} formActions={formActions} classes={classes} name={field?.name || ''}
                              label={field?.title || ''}
                              required placeholder={field?.placeholder || ''}/>
      case 'text_area':
        return <FormTextField key={field?.name} formActions={formActions} classes={classes} name={field?.name || ''}
                              label={field?.title || ''} row lines={3}
                              required placeholder={field?.placeholder || ''}/>
      case 'select':
      case 'multi_select':
        return <FormSelectField key={field?.name} formActions={formActions} classes={classes} name={field?.name || ''}
                                list={field?.additional || []} multiple={field?.type === 'multi_select'}
                                label={field?.title || ''} required placeholder={field?.placeholder || ''}/>

      case 'date':
        return <FormDateField key={field?.name} formActions={formActions} classes={classes} name={field?.name || ''}
                              label={field?.title || ''} handleChange={(date) => handleDateChange(date, field?.name)}
                              required value={!!Object.keys(fieldDate || {}).length ? fieldDate[field?.name] : null}/>

      default:
        return ''
    }
  }
  return (
    <LanguageConsumer>
      {({ locale }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
          <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

            <FormSelectField formActions={formActions} classes={classes} name={'status'}
                             labelKey={'Fields.Labels.status'} required type={'ticketStatus'}/>


            {currentData?.additional_fields?.length && currentData?.additional_fields.map(field => getAdditionalField(field))}

            <div className={classes.actionButtonsWrapper}>
              <Button type="submit" variant='contained' color='primary'
                      className={classes.addRecordButton} id='addDialogRecordButton'
                      onClick={formActions.handleSubmit}
                      disabled={formActions.isSubmitting || !formActions.isValid}
              >
                <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
              </Button>
              <Button variant='outlined' color='primary'
                      onClick={cancelDialog}>
                <FormattedMessage id='AddNewDialog.Cancel'/>
              </Button>
            </div>
            {formActions.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </MuiPickersUtilsProvider>
      )}
    </LanguageConsumer>
  )
}

export default TicketUserCaseForm
