import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewCustomerFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormPhoneField from './Fields/FormPhoneField'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import FormSwitchField from './Fields/FormSwitchField'

const salesFilter = { 'role': 'sales' }
const CustomersForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  /* eslint-disable no-unused-vars */
  const [operatorsFilter, setOperatorsFilter] = React.useState(!!currentData?.operators?.length ? { id: currentData?.operators?.map(item => item.id) || null } : null)
  const [customerFilter, setCustomerFilter] = React.useState({ customer_id: currentData?.id || 'null' })
  /* eslint-enable no-unused-vars */
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      name: currentData?.name || '',
      lead: currentData?.lead || '',
      organization_id: currentData?.organization_id || '',
      commercial_reg_number: currentData?.commercial_reg_number || '',
      tax_card_number: currentData?.tax_card_number || '',
      phone_number: '+20' + (!!currentData?.phone_number ? currentData?.phone_number.replace(/^\+20/g, '') : ''),
      sales_id: currentData?.sales?.id || null,
      authorized_contact_id: currentData?.authorized_contact?.id || null,
      type_id: currentData?.type?.id || null,
      operators: operatorsFilter?.id || [],
      verified: currentData?.verified || false,
      address: currentData?.address || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewCustomerFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  const NumberChange = (event, fieldName) => {
    const re = /^[0-9\b]+$/
    if (event.target.value === '' || re.test(event.target.value)) {
      formActions.setFieldValue(fieldName, event.target.value)
    }
  }

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'name'} labelKey={'Fields.Labels.Name'}
                     required/>

      <FormTextField formActions={formActions} classes={classes} name={'lead'} labelKey={'Fields.Labels.lead'}
                     required/>

      <FormTextField formActions={formActions} classes={classes} name={'organization_id'}
                     labelKey={'Fields.Labels.organization_id'}
                     required/>

      <FormTextField formActions={formActions} classes={classes} name={'tax_card_number'}
                     labelKey={'Fields.Labels.tax_card_number'}
                     required/>

      <FormTextField formActions={formActions} classes={classes} name={'commercial_reg_number'}
                     labelKey={'Fields.Labels.commercial_reg_number'}
                     required onChange={(event) => NumberChange(event, 'commercial_reg_number')}/>

      <FormPhoneField formActions={formActions} classes={classes} name={'phone_number'}
                      labelKey={'Fields.Labels.phone_number'}
                      required/>


      <ListingProvider entityName={'users'} filter={salesFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'sales_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.sales'} required/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'contacts'} filter={customerFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'authorized_contact_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'} addMore
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.authorizedContact'} required/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'operators'} initFilter={operatorsFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'operators'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.operators'} required multiple addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'customer-types'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'type_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.type'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>


      <FormSwitchField formActions={formActions} classes={classes} name={'verified'}
                       labelKey={'Fields.Labels.verified'} labelPrefix={'verified'}/>

      <FormTextField formActions={formActions} classes={classes} name={'address'}
                     labelKey={'Fields.Labels.address'}
                     required row lines={3}/>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default CustomersForm
