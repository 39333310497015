import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewVehicleProductFormValidation } from '../../../utils/validationSchema'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { LanguageConsumer } from '../../../context/LanguageContext'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import FormSwitchField from './Fields/FormSwitchField'
import FormSelectField from './Fields/FormSelectField'
import FormDateField from './Fields/FormDateField'

const productsFilter = { 'type': 'configurable' }
const productsSimpleFilter = { 'type': 'simple' }
const VehicleProductsForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const [productImeisFilter, setProductImeisFilter] = React.useState(!!currentData?.product?.id ? { 'product_id': currentData?.product?.id } : {})
  /* eslint-disable no-unused-vars */
  const [sensorsFilter, setOperatorsFilter] = React.useState({ 'type': 'simple', ...(!!currentData?.sensors?.length ? { id: currentData?.sensors?.map(item => item.id) || null } : {}) })
  /* eslint-enable no-unused-vars */

  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      product_id: currentData?.product?.id || null,
      product_imei_id: currentData?.product_imei?.id || null,
      vehicle_id: currentData?.vehicle?.id || null,
      installation_order_id: currentData?.installation_order?.id || null,
      operator_id: currentData?.operator?.id || '',
      sensor_ids: !!currentData?.sensors?.length ? currentData?.sensors?.map(item => item.id) : [],
      sim_number: currentData?.sim_number || '',
      sim_serial: currentData?.sim_serial || '',
      status: currentData?.status || '',
      installation_date: '',
      needs_configuration: !!currentData && !!Object.keys(currentData).length ? currentData.needs_configuration || false : true
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewVehicleProductFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })
  const [installationDate, setInstallationDate] = React.useState(currentData?.installation_date ? new Date(currentData?.installation_date) : null)

  React.useEffect(() => {
      if (!!installationDate) {
        formActions.setFieldValue('installation_date', installationDate || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [installationDate])

  const handleDateChange = (date, setDate) => {
    setDate(date)
  }

  const handleProductImeisFilter = (value) => {
    setProductImeisFilter({ 'product_id': value })
  }

  const NumbersChange = (event, fieldName) => {
    const re = /^[0-9]+$/
    if (event.target.value === '' || re.test(event.target.value)) {
      formActions.setFieldValue(fieldName, event.target.value)
    }
  }

  return (
    <LanguageConsumer>
      {({ locale }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
          <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

            <ListingProvider entityName={'products'} filter={productsFilter}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'product_id'}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       setFilter={handleProductImeisFilter}
                                       searchable={'name'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.product'} required addMore/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <ListingProvider entityName={'imeis'} filter={productImeisFilter}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'product_imei_id'}
                                       disabled={!formActions.values.product_id}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       searchable={'name'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.imei'} required addMore/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <ListingProvider entityName={'vehicles'}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'vehicle_id'}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       searchable={'id'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.vehicle'} required addMore/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <ListingProvider entityName={'operators'}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'operator_id'}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       searchable={'name'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.operator'} required addMore/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <ListingProvider entityName={'products'} filter={productsSimpleFilter} initFilter={sensorsFilter}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'sensor_ids'}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       searchable={'name'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.sensors'} required multiple addMore/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <ListingProvider entityName={'installation-orders'}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'installation_order_id'}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       searchable={'id'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.installation_order'}/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <FormSelectField formActions={formActions} classes={classes} name={'status'}
                             labelKey={'Fields.Labels.status'}
                             required type={'vehicleProductStatus'}/>

            <FormTextField formActions={formActions} classes={classes} name={'sim_number'}
                           labelKey={'Fields.Labels.sim_number'} required
                           onChange={(event) => NumbersChange(event, 'sim_number')}/>

            <FormTextField formActions={formActions} classes={classes} name={'sim_serial'}
                           labelKey={'Fields.Labels.sim_serial'}
                           required/>

            <FormDateField formActions={formActions} classes={classes} name={'installation_date'}
                           labelKey={'Fields.Labels.end_date'}
                           required value={installationDate}
                           handleChange={(date) => handleDateChange(date, setInstallationDate)}/>

            <FormSwitchField formActions={formActions} classes={classes} name={'needs_configuration'}
                             labelKey={'Fields.Labels.needs_configuration'} labelPrefix={'needs_configuration'}/>

            <div className={classes.actionButtonsWrapper}>
              <Button type="submit" variant='contained' color='primary'
                      className={classes.addRecordButton} id='addDialogRecordButton'
                      onClick={formActions.handleSubmit}
                      disabled={formActions.isSubmitting || !formActions.isValid}
              >
                <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
              </Button>
              <Button variant='outlined' color='primary'
                      onClick={cancelDialog}>
                <FormattedMessage id='AddNewDialog.Cancel'/>
              </Button>
            </div>
            {formActions.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </MuiPickersUtilsProvider>
      )}
    </LanguageConsumer>
  )
}

export default VehicleProductsForm
