import React from 'react'
import HomePageContent from '../../components/HomePageContent'
import AccountLayout from '../../components/AccountLayout'
import {SEO} from '../../components/Seo/seo'
import { useIntl } from 'react-intl'

const Home = () => {
  const intl = useIntl()
  return (
    <>
      <SEO title={intl.formatMessage({ id: 'Page.Home.title' })}
           pathname={window.location.origin}
           titleTemplate={intl.formatMessage({ id: 'QHub' })}
           description={intl.formatMessage({ id: 'Page.Home.title' })}
      />
      <AccountLayout content={<HomePageContent/>}/>
    </>
  )
}

export default Home
