import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { GeneralConsumer } from '../../context/GeneralContext'
import { useStyles } from './Styles'
import { NamedRoutes } from '../../routes'
import NavCollapse from './navCollapse'
import { AuthConsumer } from '../../context/AuthContext'
import HomeIcon from '@material-ui/icons/Home'


const MainNavigation = () => {
  const classes = useStyles()
  return (
    <GeneralConsumer>{({ toggleMobileMenuDrawer }) => (
      !!NamedRoutes?.entities?.length &&
      <AuthConsumer>
        {({ menuParents, activateMenuParent, userPermissions }) => (
          <div className={classes.mainNavigationWrapper}>
            <nav className={classes.mainNavigation}>
              <ul>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    to={NamedRoutes.home}
                    onClick={() => toggleMobileMenuDrawer(false)}>
                    <span><HomeIcon/></span>
                    <FormattedMessage id='MainNavigation.link.dashboard'/>
                  </NavLink>
                </li>
                {NamedRoutes.entities.map((item, index) => {
                    let entityPermission = !!item.isParent ? [] : userPermissions.filter(permission => [`create ${item.name}`, `view ${item.name}`, `delete ${item.name}`, `update ${item.name}`].includes(permission))
                    return (
                      !item.hidden &&
                      (!!item.isParent ?
                          <NavCollapse key={index} item={item} closeDrawer={() => toggleMobileMenuDrawer(false)}
                                       isOpen={!!menuParents[item.name]} activateMenuParent={activateMenuParent}
                                       userPermissions={userPermissions}/>
                          :
                          !!entityPermission?.length &&
                          <li key={index}>
                            <NavLink
                              exact
                              activeClassName='active'
                              to={item.path}
                              onClick={() => toggleMobileMenuDrawer(false)}
                            >
                              {!!item.icon && <span>{item.icon}</span>}
                              <FormattedMessage id={`MainNavigation.link.${item.name}`}/>
                            </NavLink>
                          </li>
                      )
                    )
                  }
                )}
              </ul>
            </nav>
          </div>
        )}
      </AuthConsumer>
    )}
    </GeneralConsumer>
  )
}

export default MainNavigation
