import React, { createContext } from 'react'
import { useHistory } from 'react-router-dom'
import CookiesStorageService from '../Services/CookiesStorageService'
import { NamedRoutes } from '../routes'
import AuthServices from '../Services/Consumers/AuthServices'
import { changePasswordPayload, forgotPasswordPayload, loginPayload } from '../Services/Payloads/AdminPayload'
import { useIntl } from 'react-intl'
import { handleNotificationAlert } from '../utils/helpers'

const AuthContext = createContext({})

const AuthProvider = ({ children }) => {
  const history = useHistory()
  const intl = useIntl()
  const [isAuth, setIsAuth] = React.useState(false)
  const [hasToken, setHasToken] = React.useState(!!CookiesStorageService.getAccessToken())
  const [logoutLoader, setLogoutLoader] = React.useState(false)
  const [getUserLoader, setGetUserLoader] = React.useState(false)
  const [userData, setUserData] = React.useState({})
  const [userPermissions, setUserPermissions] = React.useState([])
  const [userDataFailure, setUserDataFailure] = React.useState(false)
  const [menuParents, setMenuParents] = React.useState({})

  React.useEffect(() => {
      let parents = {}
      NamedRoutes.entities.filter((item) => !!item.isParent).map(entity => parents[entity.name] = false)
      setMenuParents(parents)
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const activateMenuParent = (entity, isActive) => {
    setMenuParents(prevState => ({ ...prevState, [entity]: isActive }))
  }

  const handleLogin = (values, FormikActions) => {
    CookiesStorageService.clearToken()
    AuthServices.login(loginPayload(values), FormikActions.setSubmitting, FormikActions,
      intl.formatMessage({ id: 'login.FormErrorAlert' })).then((result) => {
      if (!!result && !!result.data && !!result.data.data) {
        CookiesStorageService.setToken({
          accessToken: result.data.data.token,
          refreshToken: result.data.data.refresh_token
        })
        setIsAuth(true)
        setUserData(result.data.data)
        handleNotificationAlert({
          type: 'done',
          message: intl.formatMessage({ id: 'login.FormSuccessAlert' }),
          color: 'success'
        }, 'success')

        setTimeout(() => history.replace(NamedRoutes.home), 200)
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })

  }
  React.useEffect(() => {
      loadCurrentUserData()
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasToken])


  const loadCurrentUserData = () => {
    if (!isAuth && !!hasToken && !(!!userData && !!Object.keys(userData).length)) {
      setGetUserLoader(true)
      AuthServices.getCurrentUser('', setUserDataFailure).then((result) => {
        if (!!result && !!result.data && !!result.data.data) {
          if (!(!!userData && !!Object.keys(userData).length)) {
            setIsAuth(true)
            setUserData(result.data.data)
            setUserPermissions(!!result.data.data?.permissions?.length ? result.data.data?.permissions?.map(permission => permission?.name?.toLowerCase()) : [])
          }
        }
      }).then(() => {
        setGetUserLoader(false)
      })
    }
  }

  React.useEffect(() => {
      if (!!hasToken && !!isAuth && !!userDataFailure) {
        setIsAuth(false)
        CookiesStorageService.clearToken()
        setHasToken(false)
        history.push(NamedRoutes.auth.login)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDataFailure])

  /** States for forgot Password Success **/
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = React.useState(false)

  const resetForgotPasswordStatus = () => {
    setForgotPasswordSuccess(false)
  }
  const handleForgotPassword = (values, FormikActions) => {
    resetForgotPasswordStatus()
    AuthServices.forgotPassword(forgotPasswordPayload(values), FormikActions.setSubmitting, FormikActions,
      intl.formatMessage({ id: 'forgotPassword.FormErrorAlert' })).then((result) => {
      if (!!result && !!result.data && !!result.data.status && result.data.status.code === 200) {
        handleNotificationAlert({
          type: 'done',
          message: intl.formatMessage({ id: 'forgotPassword.FormSuccessAlert' }),
          color: 'success'
        }, 'success')
        setForgotPasswordSuccess(true)
      }
    }).then(() => {
      FormikActions.setSubmitting(false)
    })
  }

  /** States for change Password Success **/
  const [changePasswordSuccess, setChangePasswordSuccess] = React.useState(false)

  const resetChangePasswordStatus = () => {
    setChangePasswordSuccess(false)
  }
  const handleChangePassword = (values, FormikActions) => {
    let search = window.location.search
    let params = new URLSearchParams(search)
    let token = params.get('token')
    if (!!token && token.toString()) {
      resetChangePasswordStatus()
      AuthServices.changePassword(changePasswordPayload(values, token), FormikActions.setSubmitting, FormikActions,
        intl.formatMessage({ id: 'changePassword.FormErrorAlert' })).then((result) => {
        if (!!result && !!result.data && !!result.data.status && result.data.status.code === 200) {
          handleNotificationAlert({
            type: 'done',
            message: intl.formatMessage({ id: 'changePassword.FormSuccessAlert' }),
            color: 'success'
          }, 'success')
          setChangePasswordSuccess(true)
        }
      }).then(() => {
        FormikActions.setSubmitting(false)
      })
    } else {
      handleNotificationAlert({
        type: 'defence',
        message: intl.formatMessage({ id: 'changePassword.token.FormErrorAlert' }),
        color: 'error'
      }, 'error')
      history.push(NamedRoutes.auth.forgotPassword)
    }
  }

  const handleLogOut = () => {
    setLogoutLoader(true)
    setIsAuth(false)
    CookiesStorageService.clearToken()
    setHasToken(false)
    history.push(NamedRoutes.auth.login)

    // AuthServices.loginOut(setLogoutLoader).then((result) => {
    //   if (!!result && !!result.data && !!result.data.status && result.data.status.code === 200) {
    //     setIsAuth(false)
    //     CookiesStorageService.clearToken()
    //     setHasToken(false)
    //     history.push(NamedRoutes.auth.login)
    //   }
    // }).then(() => {
    //   setLogoutLoader(false)
    // })
  }
  return (
    <AuthContext.Provider value={{
      userData,
      userPermissions,
      isAuth,
      handleLogOut,
      handleLogin,
      getUserLoader,
      logoutLoader,

      handleForgotPassword,
      resetForgotPasswordStatus,
      forgotPasswordSuccess,

      handleChangePassword,
      resetChangePasswordStatus,
      changePasswordSuccess,

      menuParents,
      activateMenuParent
    }}>
      {children}
    </AuthContext.Provider>
  )
}
const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
