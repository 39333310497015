import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewServiceRequestFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormSelectField from './Fields/FormSelectField'
import FormNumberField from './Fields/FormNumberField'
import FormListAddMore from './Fields/FormListAddMore'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import OrderPDFContent from '../OrderPDFContent'

const ServiceRequestsForm = ({ onSubmit, currentData, cancelDialog, currentAction, entityName }) => {
  const classes = useStyles()
  const [contactFilter, setContactFilter] = React.useState({})
  const [remaining, setRemaining] = React.useState(0)
  const [injectedFormValues, setInjectedFormValues] = React.useState({})
  const formActions = useFormik({
    initialValues: {
      id: '',
      customer_id: '',
      customer_contact_id: '',
      vehicle_count: 0,
      type: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewServiceRequestFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.resetForm()
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('customer_id', currentData?.customer?.id || '')
        formActions.setFieldValue('customer_contact_id', currentData?.customer_contact?.id || '')
        formActions.setFieldValue('vehicle_count', currentData?.vehicle_count || 0)
        formActions.setFieldValue('type', currentData?.type || '')

        setRemaining(Math.max((currentData.vehicle_count || 0) - (currentData.products_count || 0), 0))
        setInjectedFormValues({ ...injectedFormValues, [entityName]: currentData?.id })
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])

  const handleCustomerContactFilter = (value) => {
    setContactFilter({ 'customer_id': value })
  }

  const handleInjectedFormValues = (name, value) => {
    setInjectedFormValues({ ...injectedFormValues, [name]: value })
  }

  return (
    <>
      <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
        <ListingProvider entityName={'customers'}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_id'} list={listingData}
                                   loading={listingLoading} entityKey={'id'}
                                   setFilter={handleCustomerContactFilter}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={'Fields.Labels.customer'} required addMore/>
            )}
          </ListingConsumer>
        </ListingProvider>

        <ListingProvider entityName={'contacts'} filter={contactFilter}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_contact_id'}
                                   list={listingData} loading={listingLoading} entityKey={'id'}
                                   disabled={!formActions.values.customer_id}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={'Fields.Labels.customer_contact'} required addMore/>
            )}
          </ListingConsumer>
        </ListingProvider>


        <FormNumberField formActions={formActions} classes={classes} name={'vehicle_count'}
                         labelKey={'Fields.Labels.vehicle_count'}
                         required/>

        <FormSelectField formActions={formActions} classes={classes} name={'type'}
                         labelKey={'Fields.Labels.serviceRequestType'} disabled={!!currentData?.type}
                         required type={'serviceRequestType'}/>

        <ListingConsumer>
          {({ updateCurrentSelectedItem }) => (
            <FormListAddMore labelKey={'Fields.Labels.serviceRequestSchedules'} entityName={'service-request-schedules'}
                             id={currentData?.id}
                             currentData={currentData}
                             formActions={formActions} classes={classes} parentEntityName={entityName}
                             onSubmit={onSubmit}
                             updateParentEntity={updateCurrentSelectedItem}
                             injectedFormValues={injectedFormValues}
                             handleInjectedFormValues={handleInjectedFormValues}
                             list={currentData?.schedules || []}
                             type={formActions.values.type}
                             recursiveChildren={[
                               {
                                 labelKey: `Fields.Labels.${formActions.values.type || 'installation'}Orders`,
                                 entityName: `${formActions.values.type || 'installation'}-orders`,
                                 listItemKey: `${formActions.values.type}_orders`,
                                 remaining: remaining,
                                 statusUpdate: formActions.values.type === 'maintenance',
                                 checkStatusUpdateKey: 'id',
                                 print: true,
                                 printContent: OrderPDFContent
                               }
                             ]}
            />
          )}
        </ListingConsumer>


        <div className={classes.actionButtonsWrapper}>
          <Button type="submit" variant='contained' color='primary'
                  className={classes.addRecordButton} id='addDialogRecordButton'
                  onClick={formActions.handleSubmit}
                  disabled={formActions.isSubmitting || !formActions.isValid}
          >
            <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
          </Button>
          <Button variant='outlined' color='primary'
                  onClick={cancelDialog}>
            <FormattedMessage id='AddNewDialog.Cancel'/>
          </Button>
        </div>
        {formActions.isSubmitting && <FormLoader loading={true}/>}
      </form>
    </>
  )
}

export default ServiceRequestsForm
