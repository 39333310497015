import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewInstallationOrderFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormListAddMore from './Fields/FormListAddMore'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import FormSelectField from './Fields/FormSelectField'

const userFilter = { 'role': 'Technician' }
const MaintenanceOrderForm = ({ onSubmit, currentData, cancelDialog, currentAction, entityName, type, injectedFormValues, handleInjectedFormValues, remaining, updateParentEntity, parentEntityName }) => {
  const classes = useStyles()
  const [products, setProducts] = React.useState([])
  const formActions = useFormik({
    initialValues: {
      id: '',
      service_request_id: '',
      service_request_schedule_id: '',
      technician_id: '',
      status: '',
      products: [],
      remaining: remaining
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewInstallationOrderFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, false, null, true, null, updateParentEntity, parentEntityName)
    })
  })
  // React.useEffect(() => {
  //     setUsersFilter({ 'role': 'Technician' })
  //   }, // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [])

  React.useEffect(() => {
      if (!!Object.keys(injectedFormValues || {}).length) {
        formActions.setFieldValue('service_request_id', injectedFormValues['service-requests'] || '')
        formActions.setFieldValue('service_request_schedule_id', injectedFormValues['service-request-schedules'] || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [injectedFormValues])


  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('status', currentData?.status || '')
        formActions.setFieldValue('technician_id', currentData?.technician?.id || '')
        formActions.setFieldValue('products', currentData?.products || [])
        setProducts(currentData?.products || [])
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])


  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
      <ListingProvider entityName={'users'} filter={userFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'technician_id'}
                                 list={listingData}
                                 loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.technician'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <FormSelectField formActions={formActions} classes={classes} name={'status'} labelKey={'Fields.Labels.status'}
                       required type={'status'}/>

      <FormListAddMore labelKey={'Fields.Labels.products'}
                       entityName={'maintenance-order-products'}
                       id={currentData?.id}
                       currentData={currentData}
                       listCRUD
                       handleInjectedFormValues={handleInjectedFormValues}
                       injectedFormValues={injectedFormValues}
                       statusUpdate={entityName === 'maintenance-orders'}
                       checkStatusUpdateKey='maintenance_id'
                       disableStatusAdd={currentData?.status === 'closed'}
                       disableAdd={currentData?.status === 'closed'}
                       disableEdit={currentData?.status === 'closed'}
                       disableDelete={currentData?.status === 'closed'}
                       formActions={formActions} classes={classes} parentEntityName={entityName}
                       onSubmit={onSubmit}
                       updateParentEntity={(value) => formActions.setFieldValue('products', value)}
                       list={products}
      />

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default MaintenanceOrderForm
