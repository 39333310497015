import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button } from '@material-ui/core'
import { useStyles } from '../../utils/FormStyles'
import { ListingConsumer } from '../../context/ListingContext'
import Configuration from '../../Services/Api/Configuration'


const ShowEntityImage = () => {
  const classes = useStyles()
  return (
    <ListingConsumer>
      {({ currentSelectedRow, cancelDialog }) => (
        <Box p={4} className={classes.DeleteWrapper}>

          <img alt={currentSelectedRow?.name} className={classes.nidImage}
               src={Configuration.STORAGE_BASE_URL + currentSelectedRow?.nid_photo}/>

          <div className={classes.actionButtonsWrapper}>
            <Button variant='outlined' color='primary'
                    onClick={cancelDialog}>
              <FormattedMessage id='AddNewDialog.Close'/>
            </Button>
          </div>
        </Box>
      )}
    </ListingConsumer>
  )
}

export default ShowEntityImage
