import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewProductQuantityFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const ProductQuantitiesForm = ({ onSubmit, currentData, cancelDialog, currentAction, updateParentEntity, parentEntityName }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: '',
      quantity: '',
      inventory: {}
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewProductQuantityFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, false, null, true, null, updateParentEntity, parentEntityName)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.resetForm()
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('quantity', currentData?.quantity || '')
        formActions.setFieldValue('inventory', currentData?.inventory || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])


  const NumberChange = (event, fieldName) => {
    const re = /^[0-9\b]+$/
    if (event.target.value === '' || re.test(event.target.value)) {
      formActions.setFieldValue(fieldName, event.target.value)
    }
  }

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'quantity'}
                     labelKey={'Fields.Labels.quantity'}
                     required onChange={(event) => NumberChange(event, 'quantity')}/>

      <ListingProvider entityName={'inventories'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'inventory'}
                                 list={listingData} loading={listingLoading} allEntity entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.inventory'} required/>
          )}
        </ListingConsumer>
      </ListingProvider>


      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default ProductQuantitiesForm
