import React from 'react'
import { FormattedMessage } from 'react-intl'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { FullBodyLoader } from '../Loader/FullBodyLoader'
import { AuthConsumer } from '../../context/AuthContext'
import { useStyles } from './Styles'
import LanguageSwitcher from '../LanguageSwitcher'

const MobileMenuUserActions = ({ toggleMobileMenuDrawer }) => {
  const classes = useStyles()

  return (
    <AuthConsumer>
      {({ handleLogOut, logoutLoader }) => (
        <div className={classes.navigationDrawerUserMenu}>
          <LanguageSwitcher isMobile otherClasses={classes.logoutButton}/>
          <button onClick={() => handleLogOut()} className={classes.logoutButton}>
            <ExitToAppIcon/>
            <FormattedMessage id='UserMenu.button.logout'/>
          </button>
          {!!logoutLoader && <FullBodyLoader loading={true}/>}
        </div>
      )}
    </AuthConsumer>
  )
}

export default MobileMenuUserActions
