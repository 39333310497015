import React from 'react'
import LoginPageContent from '../../../components/Authentication/Login'
import {SEO} from '../../../components/Seo/seo'
import { NamedRoutes } from '../../../routes'
import { useIntl } from 'react-intl'

const Login = () => {
  const intl = useIntl()
  return (
    <>
      <SEO title={intl.formatMessage({ id: 'Page.Login.title' })}
           pathname={window.location.origin + NamedRoutes.auth.login}
           titleTemplate={intl.formatMessage({ id: 'QHub' })}
           description={intl.formatMessage({ id: 'Page.Login.title' })}
      />
      <LoginPageContent/>
    </>
  )
}

export default Login
