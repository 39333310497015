import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './Styles'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { Grid } from '@material-ui/core'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import { LanguageConsumer } from '../../../context/LanguageContext'
import OrderPDFHeader from './header'


const OrderDetailsPDFContent = ({ id, content, entityName }) => {
  const classes = useStyles()
  let fields = [
    { key: 'companyName', value: content?.service_request?.customer?.name },
    { key: 'address', value: content?.service_request_schedule?.address },
    { key: 'address_description', value: content?.service_request_schedule?.address_description },
    { key: 'customerNumber', value: content?.service_request?.customer_contact?.mobile },
    { key: 'vehicle_count', value: content?.service_request_schedule?.vehicle_count },
    { key: 'start_date', value: content?.service_request_schedule?.start_date },
    { key: 'end_date', value: content?.service_request_schedule?.end_date },
    { key: 'technician', value: content?.technician?.name }
  ]
  return (
    <LanguageConsumer>
      {({ locale }) => (
        <div id={id} className={classes.pdfContent}>
          <OrderPDFHeader content={content} entityName={entityName}/>
          <div className={classes.RequestDataWrapper}>
            <h3 className={classes.RequestHeading}><BookmarkBorderIcon/><FormattedMessage
              id='Order.PDF.Details.title'/></h3>
            <Grid container className={classes.RequestInfoWrapper}>
              {fields?.map((field, index) => (
                !!field.value &&
                <Grid item xs={12} key={index} className={classes.infoItemWrapper}>
                  <Grid item xs={2} className={classes.infoItemLabel}>
                    <FormattedMessage id={`Fields.label.PDF.view.${field.key}`}/>
                  </Grid>
                  <Grid item xs={10} className={classes.infoItemValue}>
                    {['start_date', 'end_date'].includes(field.key) ?
                      <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                              format="DD MMMM YYYY, h:mm A">{field.value}</Moment>
                      : (['customerNumber'].includes(field.key) ?
                        <span className={classes.phoneWrapper}>{field.value}</span> :
                        field.value)
                    }
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </LanguageConsumer>
  )
}

export default OrderDetailsPDFContent
