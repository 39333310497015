import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { FormLoader } from '../Loader/FormLoader'
import { useStyles } from '../../utils/FormStyles'
import { ListingConsumer } from '../../context/ListingContext'

const DeleteEntity = () => {
  const classes = useStyles()
  return (
    <ListingConsumer>
      {({ currentSelectedRow, cancelDialog, dialogActionSubmit, entityName, deleteItemLoading }) => (
        <Box p={4} className={classes.DeleteWrapper}>
          {!!deleteItemLoading && <FormLoader loading={true}/>}

          <WarningIcon className={classes.DeleteWarningIcon}/>
          <p><FormattedMessage id={`DeleteEntity.${entityName}.DeleteMessage`}
                               values={{ name: currentSelectedRow?.name || currentSelectedRow?.id || '' }}/></p>

          <div className={classes.actionButtonsWrapper}>
            <Button type="submit" variant='contained' color='primary'
                    className={classes.addRecordButton} id='addDialogRecordButton'
                    onClick={dialogActionSubmit}
            >
              <FormattedMessage id={`ActionsMenu.button.delete`}/>
            </Button>
            <Button variant='outlined' color='primary'
                    onClick={cancelDialog}>
              <FormattedMessage id='AddNewDialog.Cancel'/>
            </Button>
          </div>
        </Box>
      )}
    </ListingConsumer>
  )
}

export default DeleteEntity
