import React from 'react'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { BrowserRouter as Router } from 'react-router-dom'
import { createGenerateClassName, createMuiTheme, fade } from '@material-ui/core/styles'
import { StylesProvider, ThemeProvider as MuiThemeProvider, jssPreset } from '@material-ui/styles'
import { LocalStorageService } from '../Services/LocalStorageService'
import Configuration from '../Services/Api/Configuration'

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })
const generateClassName = createGenerateClassName()
let { DefaultFont, DefaultDirection } = Configuration

const ThemeProvider = ({ children }) => {
  const [font, setFont] = React.useState(DefaultFont)
  const [storageDirection, setStorageDirection] = React.useState(DefaultDirection)

  let isSubscribed = false
  React.useEffect(() => {
    // eslint-disable-next-line
    isSubscribed = true
    LocalStorageService.get('QHUBFontName').then((storageFont) => {
      if (isSubscribed) {
        setFont(storageFont)
      }
    })
    LocalStorageService.get('QHUBDirection').then((storageDirection) => {
      if (isSubscribed) {
        setStorageDirection(storageDirection)
      }
    })
    return () => isSubscribed = false
  }, [])

  let theme = createMuiTheme({
    direction: storageDirection,
    palette: {
      primary: {
        lighter: '#F6F8F3',
        light: '#4AB176',
        main: '#4AB176',
        dark: '#084E02',
        contrastText: '#fff'
      },
      secondary: {
        light: '#ffd35e',
        main: '#d0a22c',
        dark: '#9b7400',
        contrastText: '#fff'
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff'
      },
      success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: fade('#000', 0.87)
      },
      info: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#1976d2',
        contrastText: '#fff'
      },
      warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: fade('#000', 0.87)
      },
      text: {
        primary: '#393737',
        secondary: '#92929d',
        hint: '#98a9bc'
      },
      colors: {
        offWhite: '#F1F1F1',
        body: '#F5F5F5',
        table: '#E8E8E8',
        footer: '#6d6d6d',
        border: '#E7E7E7',
        border2: '#74727f'
      }
    },
    typography: {
      'fontFamily': font,
      'htmlFontSize': 16,
      'fontSize': 14,
      'fontWeightLight': 300,
      'fontWeightRegular': 400,
      'fontWeightBold': 700
    },
    customShadows: {
      widget:
        '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      widgetDark:
        '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      widgetWide:
        '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
    },
    customBreakpoints: {
      mdl: 1200
    }
  })

  return (
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <Router>
          {children}
        </Router>
      </MuiThemeProvider>
    </StylesProvider>
  )
}

export { ThemeProvider }
