import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { Typography } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { AddNewFormTemplateFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import FormTextField from './Fields/FormTextField'
import FormSelectField from './Fields/FormSelectField'
import FormSwitchField from './Fields/FormSwitchField'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const FormTemplatesForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const intl = useIntl()
  const classes = useStyles()
  const [fields, setFields] = React.useState([])
  const [fieldsValidArr, setFieldsValidArr] = React.useState([])
  const [fieldsCompleted, setFieldsCompleted] = React.useState(true)
  const [fieldsAdditionalCompleted, setFieldsAdditionalCompleted] = React.useState([])
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      name: currentData?.name || '',
      fields: currentData?.fields || []
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewFormTemplateFormValidation,
    onSubmit: (values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    }
  })
  React.useEffect(() => {
      // if (!!Object.keys(currentData || {})?.length) {
      if (!!currentData?.fields?.length) {
        setFields(currentData.fields)
      } else if (!fields?.length) {
        tabsAddNewRecord()
      }
      // }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])

  const checkAllDataCompleted = (currentData) => {
    let valid = true
    let data = !!currentData ? currentData : fields
    if (!!data.length) {
      data.forEach((item, index) => {
        let additionalValid = true
        if (!item.title || !item.name || !item.type || (['select', 'multi_select'].includes(item.type) && !item.additional?.length)) {
          valid = false
        }
        if (!!item.additional?.length) {
          item.additional.forEach((additional) => {
            if (!additional.key || !additional.value) {
              additionalValid = false
            }
          })
        }
        let completed = [...fieldsAdditionalCompleted]
        completed[index] = additionalValid
        setFieldsAdditionalCompleted(completed)

        if (!additionalValid) {
          valid = false
        }
      })
    }
    setFieldsCompleted(valid)

    if (!!valid) {
      formActions.setFieldValue('fields', data)
    }

    return valid
  }

  const handleFieldRecordChange = (e, valueIndex, fieldName, exactVal) => {
    if (!!e) {
      e.preventDefault()
      e.stopPropagation()
    }

    let validations = [...fieldsValidArr]

    if (!['placeholder', 'required'].includes(fieldName)) {
      if (!e?.target?.value && e?.target?.value !== 0) {
        if (!!validations[valueIndex] && !!Object.keys(validations[valueIndex]).length) {
          validations[valueIndex][fieldName] = true
        } else {
          validations[valueIndex] = {}
          validations[valueIndex][fieldName] = true
        }
      } else {
        if (!!validations.length && !!validations[valueIndex] && !!Object.keys(validations[valueIndex]).length && !!validations[valueIndex][fieldName]) {
          delete (validations[valueIndex][fieldName])
          if (!Object.keys(validations[valueIndex]).length) {
            validations.splice(valueIndex, 1)
            // delete (validations[valueIndex])
          }
        }
      }
    }
    let exactDataValue = [...fields]
    exactDataValue[valueIndex][fieldName] = ['required'].includes(fieldName) ? exactVal ?? !!e.target.checked : e.target.value?.toString()
    checkAllDataCompleted(exactDataValue)
    setFieldsValidArr(validations)
    setFields(exactDataValue)
  }

  const handleFieldAdditionalRecordChange = (e, index, valueIndex, fieldName) => {
    if (!!e) {
      e.preventDefault()
      e.stopPropagation()
    }

    let validations = [...fieldsValidArr]

    if (!e?.target?.value && e?.target?.value !== 0) {
      if (!!validations[index] && !!Object.keys(validations[index]).length && !!validations[index]?.additional &&
        !!validations[index]['additional'][valueIndex] && !!Object.keys(validations[index]['additional'][valueIndex]).length) {
        validations[index]['additional'][valueIndex][fieldName] = true
      } else if (validations[index]) {
        validations[index]['additional'][valueIndex] = {}
        validations[index]['additional'][valueIndex][fieldName] = true
      }
    } else {
      if (!!validations.length && !!validations[index]?.additional && !!validations[index]?.additional[valueIndex] &&
        !!Object.keys(validations[index]['additional'][valueIndex]).length && !!validations[index]['additional'][valueIndex][fieldName]) {
        delete (validations[index]['additional'][valueIndex][fieldName])
        if (!Object.keys(validations[index]['additional'][valueIndex]).length) {
          validations[index]['additional'].splice(valueIndex, 1)
          // delete (validations[valueIndex])
        }
        if (!!validations[index]?.additional && !Object.keys(validations[index]['additional']).length) {
          delete (validations[index]['additional'])
        }
      }
    }
    let exactDataValue = [...fields]
    exactDataValue[index]['additional'][valueIndex][fieldName] = e.target.value?.toString()
    checkAllDataCompleted(exactDataValue)
    setFieldsValidArr(validations)
    setFields(exactDataValue)
  }

  const dataRemoveRecord = (e, valueIndex) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    let validations = [...fieldsValidArr]
    if (!!validations && !!validations.length && !!Object.keys(validations).length
      && !!validations[valueIndex] && !!Object.keys(validations[valueIndex]).length
    ) {
      validations.splice(valueIndex, 1)
    }
    setFieldsValidArr(validations)

    // let exactData = calculationsData[type]
    let exactDataValue = [...fields]
    exactDataValue.splice(valueIndex, 1)
    setFields(exactDataValue)
    checkAllDataCompleted(exactDataValue)
  }

  const tabsAddNewRecord = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    setFields(prevState => [...prevState, {
      title: '',
      name: '',
      type: '',
      additional: [],
      placeholder: '',
      required: false
    }])
    setFieldsCompleted(false)
  }

  const additionalAddNewRecord = (e, index) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    let oldFields = [...fields]
    oldFields[index]['additional'].push({
      key: '',
      value: ''
    })
    setFields(oldFields)
    let completed = [...fieldsAdditionalCompleted]
    completed[index] = false
    setFieldsAdditionalCompleted(completed)
  }

  const additionalRemoveRecord = (e, index, valueIndex) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    let validations = [...fieldsValidArr]
    if (!!validations && !!validations.length && !!Object.keys(validations).length && !!validations[index]?.additional
      && !!validations[index]?.additional[valueIndex] && !!Object.keys(validations[index]['additional'][valueIndex]).length
    ) {
      validations[index]['additional'].splice(valueIndex, 1)
    }
    if (!!validations[index]?.additional && !Object.keys(validations[index]?.additional || {}).length) {
      delete (validations[index]['additional'])
    }
    setFieldsValidArr(validations)

    // let exactData = calculationsData[type]
    let exactDataValue = [...fields]
    exactDataValue[index]['additional'].splice(valueIndex, 1)
    setFields(exactDataValue)
    checkAllDataCompleted(exactDataValue)
  }
  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'name'} labelKey={'Fields.Labels.Name'}
                     required row/>

      <div className={classes.FormFieldsMoreWrapper}>
        <Typography variant='h6' className={classes.FormFieldTitle}><FormattedMessage id={'Fields.Fields.title'}/><span
          className={'required'}>*</span>:</Typography>
        {fields?.map((field, index) => (
            <div key={index} className={classes.FormRowWrapper + ` ${classes.FormRowList}${!index ? ' noMarTop' : ''}`}>
              <FormTextField formActions={formActions} classes={classes}
                             name={`fields-${index}-title`} labelKey={'Fields.Labels.title'}
                             required onChange={e => handleFieldRecordChange(e, index, 'title')}
                             otherAttributes={{
                               value: field?.title || '',
                               error: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.title,
                               helperText: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.title && intl.formatMessage({ id: 'validationSchema.required' })
                             }}/>
              <FormTextField formActions={formActions} classes={classes}
                             name={`fields-${index}-name`} labelKey={'Fields.Labels.name'}
                             required onChange={e => handleFieldRecordChange(e, index, 'name')}
                             otherAttributes={{
                               value: field?.name || '',
                               error: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.name,
                               helperText: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.name && intl.formatMessage({ id: 'validationSchema.required' })
                             }}/>
              <FormSelectField formActions={formActions} classes={classes} type={'customFieldTypes'}
                               name={`fields-${index}-type`} labelKey={'Fields.Labels.type'}
                               required onChange={e => handleFieldRecordChange(e, index, 'type')}
                               otherAttributes={{
                                 value: field?.type || '',
                                 error: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.type
                                 // helperText: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.type && intl.formatMessage({ id: 'validationSchema.required' })
                               }}/>

              <FormTextField formActions={formActions} classes={classes}
                             name={`fields-${index}-placeholder`} labelKey={'Fields.Labels.placeholder'}
                             onChange={e => handleFieldRecordChange(e, index, 'placeholder')}
                             otherAttributes={{
                               value: field?.placeholder || '',
                               error: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.placeholder,
                               helperText: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.placeholder && intl.formatMessage({ id: 'validationSchema.required' })
                             }}/>
              {['select', 'multi_select'].includes(field.type) &&
              <div className={classes.FormFieldsMoreWrapper + ' additional'}>
                <Typography variant='h6' className={classes.FormFieldTitle}><FormattedMessage
                  id={'Fields.Additional.title'}/><span
                  className={'required'}>*</span>:</Typography>
                {field?.additional?.map((additional, additionalIndex) => (
                  <div key={`${index}-${additionalIndex}`}
                       className={classes.FormRowWrapper + ` additional-row`}>
                    <FormTextField formActions={formActions} classes={classes}
                                   name={`fields-${index}-additional-${additionalIndex}-key`}
                                   labelKey={'Fields.Labels.additional.key'}
                                   required
                                   onChange={e => handleFieldAdditionalRecordChange(e, index, additionalIndex, 'key')}
                                   otherAttributes={{
                                     value: additional?.key || '',
                                     error: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.additional && !!fieldsValidArr[index]?.additional[additionalIndex] && !!fieldsValidArr[index]?.additional[additionalIndex]?.key,
                                     helperText: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.additional && !!fieldsValidArr[index]?.additional[additionalIndex] && !!fieldsValidArr[index]?.additional[additionalIndex]?.key && intl.formatMessage({ id: 'validationSchema.required' })
                                   }}/>
                    <FormTextField formActions={formActions} classes={classes}
                                   name={`fields-${index}-additional-${additionalIndex}-value`}
                                   labelKey={'Fields.Labels.additional.value'}
                                   required
                                   onChange={e => handleFieldAdditionalRecordChange(e, index, additionalIndex, 'value')}
                                   otherAttributes={{
                                     value: additional?.value || '',
                                     error: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.additional && !!fieldsValidArr[index]?.additional[additionalIndex] && !!fieldsValidArr[index]?.additional[additionalIndex]?.value,
                                     helperText: !!fieldsValidArr[index] && !!fieldsValidArr[index]?.additional && !!fieldsValidArr[index]?.additional[additionalIndex] && !!fieldsValidArr[index]?.additional[additionalIndex]?.value && intl.formatMessage({ id: 'validationSchema.required' })
                                   }}/>

                    <div className={classes.removeWrapper}>
                      <Button variant="contained" color="secondary" className={classes.removeRecord}
                              startIcon={<DeleteForeverIcon/>}
                              onClick={e => additionalRemoveRecord(e, index, additionalIndex)}>
                        <FormattedMessage id="FormTemplates.fields.remove"/>
                      </Button>
                    </div>
                  </div>
                ))}
                <Button className={classes.AddNewRecord} variant="contained" color="primary"
                        onClick={e => additionalAddNewRecord(e, index)}
                        disabled={!!fieldsValidArr[index]?.additional?.filter(valid => !!valid).length || (!!fieldsAdditionalCompleted?.length && !fieldsAdditionalCompleted[index])}>
                  <FormattedMessage id="FormTemplates.fields.additional.addRecord"/>
                </Button>
              </div>
              }
              <FormSwitchField formActions={formActions} classes={classes}
                               name={`fields-${index}-required`} labelKey={'Fields.Labels.required'}
                               valueLabelKey={`is_required.${!!field?.required}`}
                               onChange={e => handleFieldRecordChange(e, index, 'required')}
                               otherSwitchAttributes={{
                                 checked: !!field.required,
                                 onClick: e => handleFieldRecordChange(e, index, 'required', !field?.required)
                               }}/>
              <div className={classes.removeWrapper}>
                <Button variant="contained" color="secondary" className={classes.removeRecord}
                        startIcon={<DeleteForeverIcon/>}
                        onClick={e => dataRemoveRecord(e, index)}>
                  <FormattedMessage id="FormTemplates.fields.remove"/>
                </Button>
              </div>

            </div>
          )
        )
        }
        <Button className={classes.AddNewRecord} variant="contained" color="primary"
                onClick={e => tabsAddNewRecord(e)}
                disabled={!!fieldsValidArr?.filter(valid => !!valid).length || !fieldsCompleted}>
          <FormattedMessage id="FormTemplates.fields.addRecord"/>
        </Button>
      </div>
      <div
        className={classes.actionButtonsWrapper}>
        < Button
          type='submit'
          variant='contained'
          color='primary'
          className={classes.addRecordButton}
          id='addDialogRecordButton'
          onClick={formActions.handleSubmit}
          disabled={formActions.isSubmitting || !formActions.isValid}>
          < FormattedMessage
            id={`AddNewDialog.AddNewRecord.${currentAction}`
            }
          />
        </Button>
        <Button variant="outlined" color="primary" onClick={cancelDialog}>
          <FormattedMessage id="AddNewDialog.Cancel"/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default FormTemplatesForm
