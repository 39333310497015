import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { AuthConsumer } from '../../../context/AuthContext'
import Logo from '../../Logo'
import LoginForm from './LoginForm'
import { useStyles } from '../FormStyles'

const LoginPageContent = () => {
  const classes = useStyles()

  return (
    <section className={classes.authPageContent}>
      <Container maxWidth={'lg'}>
        <Grid container>
          <Grid item xs={12}>
            <Logo className={'auth'}/>
            <AuthConsumer>
              {({ handleLogin, getUserLoader }) => (
                <LoginForm onSubmit={handleLogin} getUserLoader={getUserLoader}/>
              )}</AuthConsumer>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default LoginPageContent
