import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewTicketFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const TicketsForm = ({ onSubmit, currentData, cancelDialog, userData, currentAction }) => {
  const classes = useStyles()
  const [contactFilter, setContactFilter] = React.useState(!!currentData?.customer_id ? { 'customer_id': currentData?.customer_id } : {})
  const [ticketTypeFilter, setTicketTypeFilter] = React.useState(!!currentData?.ticket_category_id ? { 'ticket_category_id': currentData?.ticket_category_id } : {})
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      title: currentData?.title || '',
      body: currentData?.body || '',
      ticket_category_id: currentData?.ticket_category_id || '',
      ticket_type_id: currentData?.ticket_type_id || '',
      customer_id: currentData?.customer_id || '',
      customer_contact_id: currentData?.customer_contact_id || '',
      customer_vehicle_id: currentData?.customer_vehicle_id || '',
      created_by: userData?.id || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewTicketFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  const handleCustomerContactFilter = (value) => {
    setContactFilter({ 'customer_id': value })
    formActions.setFieldValue('customer_contact_id', '')
    formActions.setFieldValue('customer_vehicle_id', '')
  }
  const handleTicketTypeFilter = (value) => {
    setTicketTypeFilter({ 'ticket_category_id': value })
    formActions.setFieldValue('ticket_type_id', '')
  }

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'title'} labelKey={'Fields.Labels.title'}
                     required row/>
      <FormTextField formActions={formActions} classes={classes} name={'body'}
                     labelKey={'Fields.Labels.body'} required row lines={2}/>


      {currentAction === 'create' &&
      <>
        <ListingProvider entityName={'ticket-categories'}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={formActions} classes={classes} name={'ticket_category_id'}
                                   list={listingData} loading={listingLoading} entityKey={'id'}
                                   setFilter={handleTicketTypeFilter}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={'Fields.Labels.ticket_category'} required addMore/>
            )}
          </ListingConsumer>
        </ListingProvider>

        <ListingProvider entityName={'ticket-types'} filter={ticketTypeFilter}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={formActions} classes={classes} name={'ticket_type_id'}
                                   list={listingData} loading={listingLoading} entityKey={'id'}
                                   disabled={!formActions.values.ticket_category_id}
                                   parentValue={formActions.values.ticket_category_id}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={'Fields.Labels.ticket_type'} required addMore/>
            )}
          </ListingConsumer>
        </ListingProvider>

        <ListingProvider entityName={'customers'}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_id'}
                                   list={listingData} loading={listingLoading} entityKey={'id'}
                                   setFilter={handleCustomerContactFilter}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={'Fields.Labels.customer'} required addMore/>
            )}
          </ListingConsumer>
        </ListingProvider>

        <ListingProvider entityName={'contacts'} filter={contactFilter}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_contact_id'}
                                   list={listingData} loading={listingLoading} entityKey={'id'}
                                   disabled={!formActions.values.customer_id}
                                   parentValue={formActions.values.customer_id}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={'Fields.Labels.customer_contact'} required addMore/>
            )}
          </ListingConsumer>
        </ListingProvider>

        <ListingProvider entityName={'vehicles'} filter={contactFilter}>
          <ListingConsumer>
            {({ listingData, listingLoading, handleListingFilterSubmit }) => (
              <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_vehicle_id'}
                                   list={listingData} loading={listingLoading} entityKey={'id'}
                                   disabled={!formActions.values.customer_id}
                                   parentValue={formActions.values.customer_id}
                                   searchable={'name'} loadEntities={handleListingFilterSubmit}
                                   labelKey={'Fields.Labels.vehicle'} required addMore/>
            )}
          </ListingConsumer>
        </ListingProvider>
      </>
      }
      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default TicketsForm
