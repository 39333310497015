import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TextField,
  Chip,
  InputLabel, Checkbox, Button, FormControl
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { ButtonLoader } from '../../../Loader/ButtonLoader'
import { ListingConsumer } from '../../../../context/ListingContext'
import AddNewForm from '../index'
import AddNewDialog from '../../../SearchBar/Dialog/AddNewDialog'
import { AuthConsumer } from '../../../../context/AuthContext'
import { useDebouncedCallback } from 'use-debounce'

const FormEntityLoadField = ({ formActions, classes, name, labelKey, list, loading, required, addMore, isSearch, multiple, entityKey = 'name', allEntity, disabled, searchable, loadEntities, setFilter, setReturnedObj, row, parentValue }) => {
  const intl = useIntl()
  const [initData, setInitData] = React.useState(true)
  const [selectedValue, setSelectedValue] = React.useState(!!multiple ? [] : null)
  const [oldSelectedValue, setOldSelectedValue] = React.useState([])

  const uniqueArray = (a) => {
    let newArray = []
    a?.forEach(item => {
      if (!newArray.map(o => o.id)?.includes(item.id)) {
        newArray.push(item)
      }
    })
    return newArray
  }

  React.useEffect(() => {
      if (!!list?.length && (!!initData || !!parentValue)) {
        if (!!multiple) {
          // console.log(uniqueArray([...list, ...oldSelectedValue])?.filter((item) => formActions.values[name]?.map(entity => !!Object.keys(entity || {})?.length ? entity[entityKey] : entity)?.includes(item[entityKey])))
          setSelectedValue(list?.filter((item) => formActions.values[name]?.map(entity => !!Object.keys(entity || {})?.length ? entity[entityKey] : entity)?.includes(item[entityKey])))
        } else {
          let matched = []
          if (!!allEntity) {
            matched = list.filter((item) => formActions.values[name][entityKey] === item[entityKey])
          } else {
            matched = list.filter((item) => formActions.values[name] === item[entityKey])
          }
          setSelectedValue(matched?.length ? matched[0] : null)
          if (!!setReturnedObj && matched?.length) {
            setReturnedObj(matched[0])
          }
        }
        setInitData(false)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [list, parentValue])


  const debouncedSearchChange = useDebouncedCallback((value) => {
    loadEntities({ [searchable]: value })
  }, 300)

  return (
    !!isSearch ?
      <FormControl className={classes.formControl + ' select-control'} variant="outlined">
        <Autocomplete
          multiple={!!multiple}
          limitTags={1}
          id={name}
          loading={!!loading}
          loadingText={<ButtonLoader loading={true}/>}
          options={uniqueArray([...oldSelectedValue, ...list]) || []}
          value={selectedValue || null}
          disabled={disabled || !!loading}
          // freeSolo
          disableCloseOnSelect={!!multiple}
          getOptionLabel={(option) => option?.name || ''}
          onChange={(event, value) => {
            setSelectedValue(value)
            if (!!setReturnedObj) {
              setReturnedObj(value)
            }
            if (!!multiple) {
              setOldSelectedValue(uniqueArray([...oldSelectedValue, ...value]))
            }
            formActions.setFieldTouched(name, true, true)
            if (!!allEntity) {
              formActions.setFieldValue(name, !!multiple ? (!!value?.length ? value : []) : (value || ''))
            } else {
              formActions.setFieldValue(name, !!multiple ? (!!value?.length ? value.map(entity => entity[entityKey]) : []) : !!Object.keys(value || {})?.length ? (value[entityKey] || '') : (value || ''))
            }
            if (!!setFilter) {
              if (!!allEntity) {
                setFilter(!!multiple ? (!!value?.length ? value : []) : (value || ''))
              } else {
                setFilter(!!multiple ? (!!value?.length ? value.map(entity => entity[entityKey]) : []) : !!Object.keys(value || {})?.length ? (value[entityKey] || '') : (value || ''))
              }
            }
          }}
          onInputChange={(event, newInputValue, reason) => {
            if (event?.type === 'change') {
              debouncedSearchChange(newInputValue)
            }
            if (newInputValue === '' && !!setReturnedObj) {
              setReturnedObj({})
            }
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              {!!multiple &&
              <Checkbox
                color={'primary'}
                checked={selectedValue?.length ? selectedValue?.map(entity => !!Object.keys(entity || {})?.length ? entity[entityKey] : entity).includes(option[entityKey] || '') : false}
              />
              }
              {option.name}
            </React.Fragment>
          )}
          renderTags={(value, getTagProps) =>
            !!multiple ?
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  size={'small'}
                  label={option.name}
                  color={'primary'}
                  {...getTagProps({ index })}
                />
              )) : value
          }
          renderInput={(params) => (
            <div className={'rltv'}>
              {!!loading && <ButtonLoader loading={true}/>}
              <TextField {...params} variant="outlined"
                // placeholder={intl.formatMessage({ id: labelKey })}
                         label={intl.formatMessage({ id: labelKey })}
                // name={name}
                         disabled={!!loading}
                         margin="dense"
                         InputLabelProps={{ shrink: !!multiple ? !!formActions.values[name]?.length : !!params?.inputProps?.value }}
                // onChange={formActions.handleChange}
                // onBlur={formActions.handleBlur}
                         helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
                         error={formActions.errors[name] && formActions.touched[name] !== undefined}
              />
            </div>
          )}
        />
      </FormControl>
      :
      <div className={classes.FormItem + (!!row ? ` ${classes.FormRow}` : '')}>
        <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <div className={classes.autoCompleteWrapper}>
          <Autocomplete
            multiple={!!multiple}
            id={name}
            loading={!!loading}
            loadingText={<ButtonLoader loading={true}/>}
            options={uniqueArray([...oldSelectedValue, ...list]) || []}
            value={selectedValue}
            disabled={disabled || !!loading}
            // freeSolo
            disableCloseOnSelect={!!multiple}
            getOptionLabel={(option) => option?.name || null}
            onInputChange={(event, newInputValue) => {
              if (event?.type === 'change') {
                debouncedSearchChange(newInputValue)
              }
              if (newInputValue === '' && !!setReturnedObj) {
                setReturnedObj({})
              }
            }}
            onChange={(event, value) => {
              setSelectedValue(value)
              if (!!setReturnedObj) {
                setReturnedObj(value)
              }
              if (!!multiple) {
                setOldSelectedValue(uniqueArray([...oldSelectedValue, ...value]))
              }
              formActions.setFieldTouched(name, true, true)
              if (!!allEntity) {
                formActions.setFieldValue(name, !!multiple ? (!!value?.length ? value : []) : value)
              } else {
                formActions.setFieldValue(name, !!multiple ? (!!value?.length ? value.map(entity => entity[entityKey]) : []) : !!Object.keys(value || {})?.length ? (value[entityKey] || '') : (value || ''))
              }
              if (!!setFilter) {
                if (!!allEntity) {
                  setFilter(!!multiple ? (!!value?.length ? value : []) : (value || ''))
                } else {
                  setFilter(!!multiple ? (!!value?.length ? value.map(entity => entity[entityKey]) : []) : !!Object.keys(value || {})?.length ? (value[entityKey] || '') : (value || ''))
                }
              }
            }}

            renderOption={(option, { selected }) => (
              <React.Fragment>
                {!!multiple &&
                <Checkbox
                  color={'primary'}
                  checked={selectedValue?.length ? selectedValue?.map(entity => !!Object.keys(entity || {})?.length ? entity[entityKey] : entity).includes(option[entityKey] || '') : false}
                />
                }
                {option.name}
              </React.Fragment>
            )}
            renderTags={(value, getTagProps) =>
              !!multiple ?
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    color={'primary'}
                    {...getTagProps({ index })}
                  />
                )) : value
            }
            renderInput={(params) => (
              <div className={'rltv'}>
                {!!loading && <ButtonLoader loading={true}/>}
                <TextField {...params} variant="outlined"
                           placeholder={intl.formatMessage({ id: labelKey })}
                  // name={name}
                           disabled={!!loading}
                  // onChange={formActions.handleChange}
                  //          onBlur={formActions.handleBlur}
                           helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
                           error={formActions.errors[name] && formActions.touched[name] !== undefined}
                />
              </div>
            )}
          />
          <AuthConsumer>
            {({ userPermissions }) => (
              <ListingConsumer>
                {({ openDialog, entityName, dialogActionSubmit, currentDialogAction, isDialogOpen, cancelDialog }) => (
                  !!addMore && !!userPermissions.includes(`create ${entityName}`) &&
                  <>
                    <Button size={'small'} disableRipple color={'primary'} onClick={() => openDialog()}
                            className={classes.addNewEntityButton}>
                      <AddBoxIcon/>
                      <FormattedMessage id={`EntityPageContent.${entityName}.SearchBar.dialogTitle.create`}/>
                    </Button>
                    <AddNewDialog
                      children={<AddNewForm entityName={entityName}
                                            onSubmit={dialogActionSubmit}
                                            currentAction={currentDialogAction}
                                            cancelDialog={cancelDialog}/>}
                      dialogAction={currentDialogAction}
                      DialogTitle={<FormattedMessage
                        id={`EntityPageContent.${entityName}.SearchBar.dialogTitle.${currentDialogAction}`}/>}
                      isDialogOpen={isDialogOpen}
                      cancelDialog={cancelDialog}
                    />
                  </>
                )}
              </ListingConsumer>
            )}
          </AuthConsumer>
        </div>
      </div>

  )
}

export default FormEntityLoadField
