import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    height: '100%'
  },
  accountLayoutContainer: {
    height: '100%',
    padding: theme.spacing(0)
  },
  accountLayoutGridContainer: {
    height: '100%'
  },
  accountLayoutContent: {
    padding: `${theme.typography.pxToRem(16)}`,
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.up(theme.customBreakpoints.mdl)]: {
      height: '100%',
      padding: theme.spacing(1, 3),
      paddingLeft: `${theme.typography.pxToRem(264)}`
    }
  }
}))
