import React from 'react'
import { useStyles } from './Styles'

const PageTitle = ({ title, count, loading, noCount }) => {
  const classes = useStyles()

  return (
    <div className={classes.pageTitleWrapper}>
      <h1 className={classes.pageTitle}>{title}</h1>
      {!noCount &&
      <span className={classes.pageTitleListCount}>{(!!loading && !count) ? '...' : count}</span>}
    </div>
  )
}

export default PageTitle
