import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewContactFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormPhoneField from './Fields/FormPhoneField'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import FormUploadField from './Fields/FormUploadField'
import FormSwitchField from './Fields/FormSwitchField'

const ContactsForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      name: currentData?.name || '',
      email: currentData?.email || '',
      mobile: '+20' + (!!currentData?.mobile ? currentData?.mobile.replace(/^\+20/g, '') : ''),
      customer_id: currentData?.customer?.id || '',
      authorized: currentData?.authorized || false,
      nid_photo: '',
      nid_number: currentData?.nid_number || '',
      description: currentData?.description || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewContactFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, true)
    })
  })

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'name'} labelKey={'Fields.Labels.Name'}
                     required/>
      <FormTextField formActions={formActions} classes={classes} name={'email'} labelKey={'Fields.Labels.email'}
                     required
                     email/>
      <FormPhoneField formActions={formActions} classes={classes} name={'mobile'} labelKey={'Fields.Labels.mobile'}
                      required/>

      <ListingProvider entityName={'customers'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.customer'}/>
          )}
        </ListingConsumer>
      </ListingProvider>
      <FormTextField formActions={formActions} classes={classes} name={'nid_number'}
                     labelKey={'Fields.Labels.nidNumber'}
                     required/>

      <FormSwitchField formActions={formActions} classes={classes} name={'authorized'}
                       labelKey={'Fields.Labels.authorized'} labelPrefix={'authorized'}/>

      <FormUploadField formActions={formActions} classes={classes} name={'nid_photo'}
                       labelKey={'Fields.Labels.nidPhoto'} types={['image/jpeg', 'image/jpg', 'image/png']}
                       currentData={currentData} required={!!formActions.values.authorized} row/>

      <FormTextField formActions={formActions} classes={classes} name={'description'}
                     labelKey={'Fields.Labels.description'}
                     required row lines={2}/>


      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default ContactsForm
