import React from 'react'
import { useStyles } from './Styles'
import { FormattedMessage } from 'react-intl'

const TicketState = ({ state, status, isDialog }) => {
  const classes = useStyles()
  const isDialogClass = !!isDialog ? 'innerState' : ''
  return (
      <span className={`state-wrapper ${classes.stateWrapper} ${state} ${isDialogClass}`}>
        {!!status ? state : <FormattedMessage id={`ticketState.${state}`}/>}
      </span>
  )
}

export default TicketState
