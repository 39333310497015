import React from 'react'
import {Container, Grid, Toolbar, AppBar} from '@material-ui/core'
import {mdl} from '../../utils/ResponsiveUtility'
import DesktopView from './DesktopView'
import MobileView from './MobileView'
import {useStyles} from './Styles'

const Header = () => {
  const classes = useStyles()
  const [width, setWidth] = React.useState(document.body.clientWidth)
  const [state, setState] = React.useState('')
  const Mobile = (width < mdl)
  const DesktopAndUp = (width >= mdl)

  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    const resizeHeaderOnScroll = () => {
      let distanceY = window.pageYOffset || document.documentElement.scrollTop
      let shrinkOn = 50

      if (distanceY > shrinkOn) {
        setState('shrink')
      } else {
        setState('')
      }
    }
    window.addEventListener('scroll', resizeHeaderOnScroll)
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('scroll', resizeHeaderOnScroll)
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <AppBar position="fixed" className={`${classes.header} ${state}`} id='header' elevation={0}>
      <Toolbar className={classes.headerToolbar}>
        <Container maxWidth={false}>
          <Grid container justify='space-between' alignItems='center' className={classes.headerGridContainer}>
            {DesktopAndUp && <DesktopView/>}
            {Mobile && <MobileView/>}
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  )
}
export default Header
