import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { mdl } from '../../utils/ResponsiveUtility'
import MainNavigation from '../MainNavigation'
import { useStyles } from './Styles'
import { AuthConsumer } from '../../context/AuthContext'

const AccountLayout = ({ content }) => {
  const classes = useStyles()
  const [width, setWidth] = React.useState(document.body.clientWidth)
  const DesktopAndUp = (width >= mdl)

  React.useEffect(() => {
    const getWidth = () => {
      setWidth(document.body.clientWidth)
    }
    window.addEventListener('resize', getWidth)
    return () => {
      window.removeEventListener('resize', getWidth)
    }
  }, [width])

  return (
    <div className={classes.contentWrapper}>
      <Container maxWidth={false} className={classes.accountLayoutContainer}>
        <Grid container className={classes.accountLayoutGridContainer}>
          {DesktopAndUp &&
          <AuthConsumer>
            {({ currentRoleName }) => (
              <MainNavigation userRoleName={currentRoleName}/>
            )}
          </AuthConsumer>}
          <div className={classes.accountLayoutContent}>{content}</div>
        </Grid>
      </Container>
    </div>
  )
}

export default AccountLayout
