import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NoMatch from './pages/NoMatch'
import PrivateRoute from './utils/PrivateRoute'
import AnonymousRoute from './utils/AnonymousRoute'
import Home from './pages/Home'
import Login from './pages/Auth/Login'
import EntityListingPage from './pages/EntityListingPage'
import PeopleIcon from '@material-ui/icons/People'
import ReportsPageContent from './components/ReportsPageContent'
import { AuthConsumer } from './context/AuthContext'

export const NamedRoutes = {
  home: '/',
  entities: [
    { name: 'reports', path: '/reports', endpoint: '/tickets/report', icon: <PeopleIcon/> },
    {
      name: 'customersParent', icon: <PeopleIcon/>, isParent: true, children: [
        { name: 'customers', path: '/customers', endpoint: '/customers', icon: <PeopleIcon/> },
        { name: 'contacts', path: '/contacts', endpoint: '/contacts', icon: <PeopleIcon/> },
        { name: 'vehicles', path: '/vehicles', endpoint: '/vehicles', icon: <PeopleIcon/> },
        { name: 'vehicle-products', path: '/vehicle-products', endpoint: '/vehicle-products', icon: <PeopleIcon/> }
      ]
    },
    { name: 'calllogs', path: '/call-logs', endpoint: '/calllogs', icon: <PeopleIcon/> },
    { name: 'service-requests', path: '/service-requests', endpoint: '/service-requests', icon: <PeopleIcon/> },
    {
      name: 'service-request-schedules-list',
      path: '/service-request-schedules',
      endpoint: '/service-request-schedules',
      icon: <PeopleIcon/>
    },
    {
      name: 'installation-orders-list',
      path: '/installation-orders',
      endpoint: '/installation-orders',
      icon: <PeopleIcon/>
    },
    {
      name: 'maintenance-orders-list',
      path: '/maintenance-orders',
      endpoint: '/maintenance-orders',
      icon: <PeopleIcon/>
    },
    { name: 'tickets', path: '/tickets', endpoint: '/tickets', icon: <PeopleIcon/> },
    {
      name: 'service-request-schedules',
      path: '/service-request-schedules',
      endpoint: '/service-request-schedules',
      icon: <PeopleIcon/>,
      hidden: true
    },
    {
      name: 'installation-orders',
      path: '/installation-orders',
      endpoint: '/installation-orders',
      icon: <PeopleIcon/>,
      hidden: true
    },
    {
      name: 'maintenance-orders',
      path: '/maintenance-orders',
      endpoint: '/maintenance-orders',
      icon: <PeopleIcon/>,
      hidden: true
    },
    {
      name: 'maintenance-order-status-tracks',
      path: '/maintenance-order-status-tracks',
      endpoint: '/maintenance-order-status-tracks',
      icon: <PeopleIcon/>,
      hidden: true
    },
    {
      name: 'maintenance-product-status-tracks',
      path: '/maintenance-product-status-tracks',
      endpoint: '/maintenance-product-status-tracks',
      icon: <PeopleIcon/>,
      hidden: true
    },
    {
      name: 'installation-order-products',
      path: '/installation-order-products',
      endpoint: '/installation-order-products',
      statusTrack: '/maintenance-product-status-tracks',
      icon: <PeopleIcon/>,
      hidden: true
    },
    {
      name: 'ticket-user-cases',
      path: '/ticket-user-cases',
      endpoint: '/ticket-user-cases',
      icon: <PeopleIcon/>,
      hidden: true
    },
    {
      name: 'inventoriesParent', icon: <PeopleIcon/>, isParent: true, children: [
        { name: 'inventories', path: '/inventories', endpoint: '/inventories', icon: <PeopleIcon/> },
        { name: 'products', path: '/products', endpoint: '/products', icon: <PeopleIcon/> },
        { name: 'imeis', path: '/imeis', endpoint: '/imeis', icon: <PeopleIcon/> }
      ]
    },
    {
      name: 'usersParent', icon: <PeopleIcon/>, isParent: true, children: [
        { name: 'users', path: '/users', endpoint: '/users', icon: <PeopleIcon/> },
        { name: 'permissions', path: '/permissions', endpoint: '/permissions', icon: <PeopleIcon/>, hidden: true },
        { name: 'roles', path: '/roles', endpoint: '/roles', icon: <PeopleIcon/> },
        { name: 'teams', path: '/teams', endpoint: '/teams', icon: <PeopleIcon/> }
      ]
    },
    {
      name: 'settings', icon: <PeopleIcon/>, isParent: true, children: [
        { name: 'customer-types', path: '/customer-types', endpoint: '/customer-types', icon: <PeopleIcon/> },
        { name: 'operators', path: '/operators', endpoint: '/operators', icon: <PeopleIcon/> },
        { name: 'brands', path: '/brands', endpoint: '/brands', icon: <PeopleIcon/> },
        { name: 'vehicle-brands', path: '/vehicle-brands', endpoint: '/vehicle-brands', icon: <PeopleIcon/> },
        {
          name: 'maintenance-providers',
          path: '/maintenance-providers',
          endpoint: '/maintenance-providers',
          icon: <PeopleIcon/>
        },
        { name: 'ticket-categories', path: '/ticket-categories', endpoint: '/ticket-categories', icon: <PeopleIcon/> },
        { name: 'ticket-types', path: '/ticket-types', endpoint: '/ticket-types', icon: <PeopleIcon/> },
        { name: 'ticket-cases', path: '/ticket-cases', endpoint: '/ticket-cases', icon: <PeopleIcon/> },
        {
          name: 'form-templates',
          path: '/form-templates',
          endpoint: '/form-templates',
          icon: <PeopleIcon/>
        }
      ]
    }

  ],
  auth: {
    login: '/login',
    changePassword: '/change-password',
    forgotPassword: '/forgot-password'
  }
}

export const Routes = () => {
  return (
    <AuthConsumer>
      {({ userPermissions }) => (
        <Switch>
          <PrivateRoute exact path={NamedRoutes.home} component={Home}/>

          {!!NamedRoutes?.entities?.length && NamedRoutes.entities.map(entity => {
              return !!entity.isParent ?
                !!entity?.children?.length && entity?.children?.map(child =>
                !child.hidden &&
                <PrivateRoute exact key={child.name} path={child.path} component={EntityListingPage}
                              entityName={child.name} userPermissions={userPermissions}/>)
                : (!entity.hidden &&
                  <PrivateRoute exact key={entity.name} path={entity.path}
                                component={entity.name === 'reports' ? ReportsPageContent : EntityListingPage}
                                entityName={entity.name} userPermissions={userPermissions}/>)
            }
          )}
          <AnonymousRoute exact path={NamedRoutes.auth.login} component={Login}/>
          <Route component={NoMatch}/>
        </Switch>
      )}
    </AuthConsumer>
  )
}
