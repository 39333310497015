import React from 'react'
import { Link } from 'react-router-dom'
import logo from './../../images/logo.png'
import { NamedRoutes } from '../../routes'
import { useStyles } from './Styles'

const Logo = ({ className }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.logo} inner ${!!className && className}`}>
      <div className={classes.logoWrapper}>
        <Link to={NamedRoutes.home}>
          <img alt='Logo' className={classes.logoImage} src={logo}/>
        </Link>
      </div>
    </div>
  )
}

export default Logo
