import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { LanguageConsumer } from '../../../context/LanguageContext'
import Moment from 'react-moment'
import TicketState from '../../State'
import Countdown, { zeroPad } from 'react-countdown'
import moment from 'moment'
import AddNewDialog from '../../SearchBar/Dialog/AddNewDialog'
import AddNewForm from './index'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import LabelImportantIcon from '@material-ui/icons/LabelImportant'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'

// Renderer callback with condition
const CountDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return '00:00:00'
  } else {
    // Render a countdown
    return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
  }
}
const ViewTicket = ({ listingHeader, currentData, loadCurrentData, currentDataLoading }) => {
  const classes = useStyles()
  const tooltipValue = (value, fields) => {
    let intersection = fields.filter(x => Object.keys(value || {}).includes(x))
    return !!intersection?.length ? (!!fields?.length && <Tooltip
      title={
        <React.Fragment>
          {fields.map((userProp, index) => (
            !!value[userProp] &&
            <div key={index} className={classes.userDataWrapper}>
              <label><FormattedMessage id={`Fields.Labels.${userProp}`}/>:</label> <span>{value[userProp]}</span>
            </div>
          ))}
        </React.Fragment>
      }
      arrow
    >
      <span>{value?.name || ''}</span>
    </Tooltip>) : value?.name || ''
  }
  const getColumnValue = (key, value) => {
    if (!!key) {
      switch (key) {
        case 'remaining_sla':
          return !!value ? <Countdown
            date={Date.now() + moment.duration(value).asMilliseconds()}
            renderer={CountDownRenderer}/> : '00:00:00'


        case 'ticketStatus':
          return !!value && <div className={classes.stateWrapper}><TicketState state={value} status/></div>

        case 'state':
          return !!value && <div className={classes.stateWrapper}><TicketState state={value}/></div>

        case 'created_by':
          return !!value && !!value?.name && tooltipValue(value, ['email', 'mobile', 'gender'])

        case 'is_reopened':
          return <FormattedMessage id={`is_reopened.${value}`}/>


        case 'open_date':
          return <LanguageConsumer>
            {({ locale }) => (
              <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                      format="DD-MM-YYYY hh:mm:ss A">{value}</Moment>
            )}
          </LanguageConsumer>
        default:
          return value
      }
    } else {
      return ''
    }
  }
  return (
    <>
      <div className={classes.EntityInfoContainer}>
        <div className={classes.EntityInfoWrapper}>
          {listingHeader.map((column, index) => (
            !!currentData[column.id] &&
            <Grid item xs={12} sm={6} md={6} key={index} className={classes.infoItemWrapper}>
              <Grid item xs={12} sm={5} className={classes.infoItemLabel}>
                {!!column.label && <FormattedMessage id={column.label}/>}
              </Grid>
              <Grid item xs={12} sm={7} className={classes.infoItemValue}>
                {getColumnValue(column.id, currentData[column.id])}
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
      {!!currentDataLoading && <FormLoader loading={true}/>}
      {!!currentData?.id &&
      <ListingProvider entityName={'ticket-user-cases'} filter={{ ticket_id: currentData?.id }}>
        <ListingConsumer>
          {({ listingData, dialogActionSubmit, currentSelectedRow, currentDialogAction, isDialogOpen, cancelDialog, openDialog, listingHeader, listingLoading }) => (
            <div className={classes.FormRow + ' ' + classes.FormRowList}>
              {!!listingLoading && <FormLoader loading={true}/>}
              <div className={classes.FormListHeader}>
                <Typography variant="h5" component="h3"><FormattedMessage
                  id={'Fields.Labels.ticket_user_cases'}/></Typography>
                <AddNewDialog
                  children={(currentDialogAction !== 'delete') &&
                  <AddNewForm entityName={'ticket-user-cases'}
                              updateParentEntity={loadCurrentData}
                              currentData={currentSelectedRow}
                              onSubmit={dialogActionSubmit}
                              currentAction={currentDialogAction}
                              cancelDialog={cancelDialog}/>}
                  dialogAction={currentDialogAction}
                  DialogTitle={<FormattedMessage
                    id={`EntityPageContent.ticket-user-cases.SearchBar.dialogTitle.edit`}
                    values={{ name: currentSelectedRow?.name || currentSelectedRow?.id || '' }}/>}
                  isDialogOpen={isDialogOpen}
                  cancelDialog={cancelDialog}
                />
              </div>
              {!!listingData?.length &&
              <div className={classes.EntityListContainer}>
                {listingData?.map((item) => (
                  item?.status?.toString() !== 'not_assigned' &&
                  <Accordion key={item.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className={classes.ItemHeadingWrapper}>
                        <Typography className={classes.ItemHeading + ' case'}><LabelImportantIcon/>{item?.name}
                          <TicketState state={item?.state}/></Typography>
                        {item?.status?.toString() === 'open' &&
                        <div className={classes.EntityActionsWrapper}>

                          <IconButton color="primary" aria-label="Edit" onClick={(e) => {
                            !!e?.preventDefault && e.preventDefault()
                            !!e?.stopPropagation && e.stopPropagation()
                            openDialog('edit', item, item.id)
                          }}>
                            <EditIcon/>
                          </IconButton>
                        </div>
                        }
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.EntityInfoContainer}>
                        <div className={classes.EntityInfoWrapper}>
                          {listingHeader.map((column, index) => (
                            (!!item[column.id] || !!['is_reopened'].includes(column.id)) &&
                            <Grid item xs={12} sm={6} md={6} key={index} className={classes.infoItemWrapper}>
                              <Grid item xs={12} sm={5} className={classes.infoItemLabel}>
                                {!!column.label && <FormattedMessage id={column.label}/>}
                              </Grid>
                              <Grid item xs={12} sm={7} className={classes.infoItemValue}>
                                {getColumnValue(column.id, item[column.id])}
                              </Grid>
                            </Grid>
                          ))}
                          {!!item?.additional?.length && item?.additional?.map((field, index) => (
                            !!field?.value &&
                            <Grid item xs={12} sm={6} md={6} key={index} className={classes.infoItemWrapper}>
                              <Grid item xs={12} sm={5} className={classes.infoItemLabel}>
                                {field?.name}
                              </Grid>
                              <Grid item xs={12} sm={7} className={classes.infoItemValue}>
                                {field?.value}
                              </Grid>
                            </Grid>
                          ))}

                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
              }
            </div>
          )}
        </ListingConsumer>
      </ListingProvider>
      }
    </>
  )
}

export default ViewTicket
