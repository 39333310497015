import React from 'react'
import MobileMenu from '../MobileMenu'
import {useStyles} from './Styles'
import Logo from '../Logo'

const MobileView = () => {
  const classes = useStyles()

  return (
    <>
      <Logo className={classes.mobileView}/>
      <MobileMenu/>
    </>
  )
}

export default MobileView
