import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TextField,
  FormControl,
  InputLabel, InputAdornment
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const FormTextField = ({ formActions, classes, name, labelKey, label, required, isSearch, email, row, lines, onChange, otherAttributes = {}, placeholder }) => {
  const intl = useIntl()
  return (
    !!isSearch ?
      <FormControl className={classes.formControl} variant="outlined">
        <TextField
          label={label ?? intl.formatMessage({ id: labelKey })}
          id={name}
          type={!!email ? 'email' : 'text'}
          margin="dense"
          variant="outlined"
          name={name}
          InputProps={{
            startAdornment:
              <InputAdornment position="start">
                <SearchIcon/>
              </InputAdornment>
          }}
          InputLabelProps={{ shrink: !!formActions.values[name] }}
          onChange={onChange ?? formActions.handleChange}
          onBlur={formActions.handleBlur}
          value={formActions.values[name]}
          helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
          error={formActions.errors[name] && formActions.touched[name] !== undefined}
        />
      </FormControl>
      :
      <div className={classes.FormItem + (!!row ? ` ${classes.FormRow}` : '')}>
        <InputLabel htmlFor={name}>{label ?? <FormattedMessage id={labelKey}/>}{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <FormControl variant="outlined">
          <TextField
            required={required}
            fullWidth
            multiline={(lines || 1) > 1}
            rows={lines || 1}
            margin="dense"
            type={!!email ? 'email' : 'text'}
            name={name}
            id={name}
            variant="outlined"
            placeholder={placeholder ?? (label ?? intl.formatMessage({ id: labelKey }))}
            onChange={onChange ?? formActions.handleChange}
            onBlur={formActions.handleBlur}
            value={formActions.values[name]}
            helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
            error={formActions.errors[name] && formActions.touched[name] !== undefined}
            {...otherAttributes}
          />
        </FormControl>
      </div>
  )
}

export default FormTextField
