import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { useStyles } from './Styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { Collapse } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const NavCollapse = ({ item, closeDrawer, isOpen, activateMenuParent, userPermissions }) => {

  const classes = useStyles()

  const [open, setOpen] = React.useState(isOpen || false)
  const [show, setShow] = React.useState(false)

  const handleClick = () => {
    activateMenuParent(item.name, !open)
    setOpen(!open)
  }

  React.useEffect(() => {
      if (!!item?.children?.length) {
        item?.children?.forEach(child => {
          let entityPermission = userPermissions.filter(permission => [`create ${child.name}`, `view ${child.name}`, `delete ${child.name}`, `update ${child.name}`].includes(permission))
          setShow(prevState => !!entityPermission?.length || prevState)
        })
      }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [userPermissions])
  return (
    !!show &&
    <li>
      <Button onClick={handleClick} disableRipple className={classes.navCollapseBtn}>
        <div>
          {!!item.icon && <span>{item.icon}</span>}
          <FormattedMessage id={`MainNavigation.link.${item.name}`}/>
        </div>
        {open ? <ExpandLess/> : <ExpandMore/>}
      </Button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ul className={classes.navCollapseList}>
          {!!item?.children?.length && item.children.map((child, index) => {
            let entityPermission = userPermissions.filter(permission => [`create ${child.name}`, `view ${child.name}`, `delete ${child.name}`, `update ${child.name}`].includes(permission))
            return (
              !child.hidden && !!entityPermission?.length &&
              <li key={index}>
                <NavLink
                  exact
                  activeClassName="active"
                  to={child.path}
                  onClick={closeDrawer}
                >
                  {!!child.icon && <span>{child.icon}</span>}
                  {child.label}
                  <FormattedMessage id={`MainNavigation.link.${child.name}`}/>
                </NavLink>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </li>
  )
}

export default NavCollapse
