import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { fade, withStyles, Menu, IconButton, Button } from '@material-ui/core'
import { ListingConsumer } from '../../../context/ListingContext'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useStyles } from './Styles'
import domtoimage from 'dom-to-image'
import jsPDF from 'jspdf'
import PrintIcon from '@material-ui/icons/Print'
import OrderPDFContent from '../../EntityPageContent/OrderPDFContent'

const StyledMenu = withStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(0),
    boxShadow: `0 8px 16px -4px ${fade(theme.palette.common.white, 0.16)}`,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0),
    textAlign: 'left',
    '& .MuiMenu-list': {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 176,
      border: `1px solid ${theme.palette.colors.border}`
    }
  }
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))

const ActionsMenu = ({ selectedRow, id, entityName, userPermissions, setPdfLoading }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [show, setShow] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isMenuView, setIsMenuView] = React.useState(false)
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'header-account-menu'

  const generatePDF = async (e) => {
    !!e?.preventDefault && e.preventDefault()
    !!e?.stopPropagation && e.stopPropagation()
    setPdfLoading(true)
    const ids = ['contentPdfContent']
    const pdfPages = await generatePDFPages(ids)

    pdfPages.autoPrint({ variant: 'non-conform' })
    const printBlob = window.URL.createObjectURL(pdfPages.output('blob'))
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: printBlob
    }).click()
    window.URL.revokeObjectURL(printBlob)
    handleMenuClose()
    setPdfLoading(false)
  }

  const generatePDFPages = async (ids) => {
    const pdf = new jsPDF('p', 'px', 'a4', true)
    pdf.setProperties({
      title: intl.formatMessage({ id: `${entityName}.Print.Title` }, { id: id }),
      subject: intl.formatMessage({ id: `${entityName}.Print.Title` }, { id: id }),
      author: 'QHUB',
      creator: 'QHUB'
    })
    const pdfWidth = pdf.internal.pageSize.getWidth()

    const pagesContent = ids.map((id) => {
      const node = document.getElementById(id)
      return !!node ? domtoimage.toPng(node) : false
    })
    await Promise.all(pagesContent)
    pagesContent.forEach((pdfPage, index) =>
      !!pdfPage && pdfPage.then(function(dataUrl) {
        if (index !== 0) pdf.addPage()
        const imgProps = pdf.getImageProperties(dataUrl)
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST')
      }))
    return pdf
  }

  const handleProfileMenuOpen = (event) => {
    setIsMenuView(true)
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
    setTimeout(() => setIsMenuView(false), 300)
  }
  React.useEffect(() => {
      if (!!userPermissions?.length) {
        let entityPermission = userPermissions.filter(permission => [`delete ${entityName}`, `update ${entityName}`].includes(permission))
        setShow(entityName === 'tickets' || !!entityPermission?.length)
      }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [userPermissions])

  return (
    !!show &&
    <>
      <IconButton
        aria-label={<FormattedMessage id='ActionsMenu.label'/>}
        aria-controls={menuId}
        aria-haspopup="true"
        className={classes.actionMenuButton}
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <MoreHorizIcon color='primary'/>
      </IconButton>
      {!!isMenuView &&
      <>
        <StyledMenu
          id={menuId}
          anchorEl={anchorEl}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <ListingConsumer>
            {({ openDialog }) => (
              <>
                {!!['tickets', 'customers'].includes(entityName) &&
                <Button onClick={() => openDialog('show', selectedRow, id, handleMenuClose)}
                        className={classes.buttonAction}>
                  <VisibilityIcon/>
                  <FormattedMessage id='ActionsMenu.button.show'/>
                </Button>
                }
                {!!['installation-orders-list', 'maintenance-orders-list'].includes(entityName) && <>
                  <Button onClick={generatePDF} className={classes.buttonAction}>
                    <PrintIcon/>
                    <FormattedMessage id='ActionsMenu.button.print'/>
                  </Button>

                  <OrderPDFContent content={selectedRow} entityName={entityName}/>
                </>
                }
                {!!userPermissions.includes(`update ${entityName}`) &&
                <Button onClick={() => openDialog('edit', selectedRow, id, handleMenuClose)}
                        className={classes.buttonAction}>
                  <EditIcon/>
                  <FormattedMessage id='ActionsMenu.button.edit'/>
                </Button>
                }
                {!!['vehicle-products'].includes(entityName) &&
                <Button onClick={() => openDialog('transfer', selectedRow, id, handleMenuClose)}
                        className={classes.buttonAction}>
                  <VisibilityIcon/>
                  <FormattedMessage id='ActionsMenu.button.transfer'/>
                </Button>
                }
                {!!userPermissions.includes(`delete ${entityName}`) &&
                <Button onClick={() => openDialog('delete', selectedRow, id, handleMenuClose)}
                        className={classes.buttonAction}>
                  <DeleteForeverIcon/>
                  <FormattedMessage id='ActionsMenu.button.delete'/>
                </Button>
                }
              </>
            )}
          </ListingConsumer>
        </StyledMenu>
      </>
      }
    </>
  )
}

export default ActionsMenu
