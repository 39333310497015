import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import Configuration from '../Services/Api/Configuration'
import ListingServices from '../Services/Consumers/ListingServices'
import { FormattedMessage, useIntl } from 'react-intl'
import { filterData, handleNotificationAlert } from '../utils/helpers'
import { arrangeListingData, ListingHeaders } from '../utils/ListingHelpers'
import { ListingDialogSubmitPayload, ListingParamsPayload } from '../Services/Payloads/AdminPayload'
import Moment from 'react-moment'
import { LanguageConsumer } from './LanguageContext'

const ListingContext = createContext({})

const ListingProvider = ({ entityName, endpoint, list, listArr, initListArr = [], filter, initFilter, listCRUD, locale, children }) => {
  const intl = useIntl()
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  React.useEffect(() => {
      if (!!entityName) {
        if (!initFilter) {
          setListingPage(0)
        }
        setListingHeader(ListingHeaders[entityName])
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])

  const [listingSort, setListingSort] = React.useState('')
  const [listingRowsPerPage, setListingRowsPerPage] = React.useState(10)
  const [listingHeader, setListingHeader] = React.useState([])
  const [listingLoading, setListingLoading] = React.useState(false)
  const [listingExportLoading, setListingExportLoading] = React.useState(false)
  const [listingData, setListingData] = React.useState([])
  const [currentSelectedRow, setCurrentSelectedRow] = React.useState({})
  const [currentDialogAction, setCurrentDialogAction] = React.useState('')
  const [listingPage, setListingPage] = React.useState(-1)
  const [listingCount, setListingCount] = React.useState(0)
  const [needRefreshListingData, setNeedRefreshListingData] = React.useState(false)
  const [listingFiltersBackup, setListingFiltersBackup] = React.useState(filter || null)

  const handleListingFilterSubmit = (values, FormikActions) => {
    if (!!values && !!Object.keys(values).length) {
      setListingFiltersBackup({ ...filter, ...values })
    }
  }

  React.useEffect(() => {
      if (!!listingFiltersBackup || !!listArr) {
        if (listingPage === 0) {
          getListingData()
        }
        setListingPage(0)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [listingFiltersBackup, listArr])

  React.useEffect(() => {
      if (!!filter && !!Object.keys(filter || {}).length && !initFilter) {
        setListingFiltersBackup(filter)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter])

  React.useEffect(() => {
      if (!!initFilter && !!Object.keys(initFilter || {}).length) {
        setListingFiltersBackup(initFilter)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [initFilter])

  React.useEffect(() => {
      if (listingPage > -1) {
        getListingData()
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [listingPage, listingRowsPerPage, listingSort])

  const handleChangeListingPage = (event, newPage) => {
    setListingPage(newPage)
  }

  const handleChangeListingRowsPerPage = (event) => {
    setListingRowsPerPage(+event.target.value)
    setListingPage(0)
  }

  const handleChangeListingSort = (sortBy, order) => {
    if (!!sortBy && !!order) {
      setListingSort(`${order === 'desc' ? '-' : ''}${sortBy}`)
      setListingPage(0)
    }
  }

  const getListingData = () => {
    if (!!Configuration.isAuth()) {
      if (!!listArr) {
        setListingCount(listArr?.length)
        setListingData(arrangeListingData(entityName, listArr, null, locale))
      } else {
        setListingLoading(true)
        ListingServices.getListingData(entityName, filterData(ListingParamsPayload(entityName, listingPage + 1, listingRowsPerPage, listingSort, listingFiltersBackup, list)),
          intl.formatMessage({ id: `listing.Error.${entityName}` }), endpoint).then((result) => {
          if (!!result && !!result.data && !!result.data.data) {
            setListingCount(result.data.meta.total)
            let newData = arrangeListingData(entityName, result.data.data, null, locale)
            if (!!initListArr?.length) {
              let initialData = arrangeListingData(entityName, initListArr, null, locale)
              let ids = new Set(initialData.map(d => d.id))
              newData = [...initialData, ...arrangeListingData(entityName, result.data.data, null, locale).filter(d => !ids.has(d.id))]
            }
            setListingData(newData)
          }
        }).then(() => {
          setListingLoading(false)
        })
      }
    }
  }

  const exportListing = (extension = 'xlsx') => {
    if (!!Configuration.isAuth()) {
      setListingExportLoading(true)
      ListingServices.getListingExportData(entityName, filterData(listingFiltersBackup || {}),
        intl.formatMessage({ id: `listing.Error.export.${entityName}` })).then((result) => {
        if (!!result && !!result.data) {
          const type = result.headers['content-type']
          const blob = new Blob([result.data], { type: type, encoding: 'UTF-8' })
          const url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          const contentDisposition = result.headers['content-disposition']
          let fileName = `${entityName}.${extension}`
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
            if (fileNameMatch.length === 2)
              fileName = fileNameMatch[1]
          }
          link.setAttribute('download', fileName) //or any other extension
          document.body.appendChild(link)
          link.click()
          link.remove()
          window.URL.revokeObjectURL(url)
        }
      }).then(() => {
        setListingExportLoading(false)
      })
    }
  }

  const [deleteItemLoading, setDeleteItemLoading] = React.useState(false)

  const getNameFromEntityItem = (entity, action, newData, oldData) => {
    let data = {}
    if (action === 'create') {
      switch (entity) {
        case 'service-requests':
        case 'installation-orders':
        case 'maintenance-orders':
          data = { name: newData.name || newData.id || '' }
          break
        case 'installation-order-products':
          data = { name: newData?.product?.name || newData.id || '' }
          break
        case 'maintenance-order-status-tracks':
        case 'maintenance-product-status-tracks':
          data = {
            name: newData?.status ? <FormattedMessage id={`statusTrack.${newData?.status}`}/> : newData.id || ''
          }
          break

        case 'imeis':
          data = { name: newData.imei || newData.id || '' }
          break

        case 'service-request-schedules':
        case 'service-request-schedules-list':
          data = {
            name: <LanguageConsumer>
              {({ locale }) => (
                <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                        format="Do MMMM YYYY, h:mm A">{newData.start_date}</Moment>
              )}
            </LanguageConsumer>
          }
          break
        default:
          data = { name: newData.name || newData.title || newData.id || '' }
          break
      }
    } else {

      switch (entity) {
        case 'service-requests':
        case 'installation-orders':
        case 'maintenance-orders':
          data = { name: oldData.name || oldData.id || '' }
          break
        case 'installation-order-products':
          data = { name: oldData?.product?.name || oldData.id || '' }
          break
        case 'imeis':
          data = { name: oldData.imei || oldData.id || '' }
          break
        case 'service-request-schedules':
        case 'service-request-schedules-list':
          data = {
            name: <LanguageConsumer>
              {({ locale }) => (
                <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                        format="Do MMMM YYYY, h:mm A">{oldData.start_date}</Moment>
              )}
            </LanguageConsumer>
          }
          break
        default:
          data = { name: oldData.name || oldData.title || oldData.id || '' }
          break
      }
    }

    return data
  }

  const dialogActionSubmit = (values, FormikActions, attachmentType, closeConfirmDialog, submitFromChild, successOpenDialog, updateParentEntity, parentEntityName, anyCallback, shouldCloseDialog) => {
    if (!!Configuration.isAuth()) {
      let entityDisplayName = getNameFromEntityItem(entityName, currentDialogAction, values, currentSelectedRow)

      if (currentDialogAction === 'delete') {
        setDeleteItemLoading(true)
      }

      // if (!['company', 'socialInsuranceCompany', 'bank', 'reason', 'vehicleColor'].includes(entityName) && 'sort' !== currentDialogAction) {
      //   data = filterData(data)
      // }
      // console.log(currentSelectedRow)
      if (!!listCRUD) {
        if (!!submitFromChild) {
          updateCurrentSelectedItem(arrangeListingData(entityName, [values]), entityName, ['service-requests', 'installation-orders-list'].includes(entityName))
          if (!!updateParentEntity) {
            setTimeout(() => updateParentEntity(listingData), 500)
          }
          setTimeout(() => {
            cancelDialog()
            setDeleteItemLoading(false)
            if (!!FormikActions) {
              FormikActions.setSubmitting(false)
            }
            if (!!anyCallback) {
              anyCallback()
            }
          }, 200)
        }
      } else {
        let data = ListingDialogSubmitPayload(entityName, currentDialogAction, values, currentSelectedRow, attachmentType)
        ListingServices.handleDialogSubmit(entityName, currentDialogAction, setDeleteItemLoading, FormikActions,
          data, currentSelectedRow?.id?.toString() || '',
          intl.formatMessage({ id: `listing.Error.${entityName}.${currentDialogAction}` }, entityDisplayName),
          (entityName === 'vehicle') ? { 'content-type': 'multipart/form-data' } : '', attachmentType).then((result) => {
          if (!!result && ((!!result.data && !!result.data.data) || ['200', '201', '204'].includes(result?.status?.toString()))) {
            handleNotificationAlert({
              type: 'done',
              message: intl.formatMessage({ id: `listing.Success.${entityName}.${currentDialogAction}` }, entityDisplayName),
              color: 'success'
            }, 'success')
            setDeleteItemLoading(false)
            if (!!FormikActions) {
              FormikActions.setSubmitting(false)
            }

            if (currentDialogAction === 'delete' && ['service-request-schedules', 'installation-orders', 'maintenance-orders'].includes(entityName)) {
              deleteRecord(currentSelectedRow, currentSelectedRow?.id, updateParentEntity)
            }

            if (!!submitFromChild) {
              if (!!successOpenDialog) {
                successOpenDialog()
              }
              updateCurrentSelectedItem(arrangeListingData(entityName, [result.data.data]), entityName, entityName === 'service-requests', ['maintenance-order-status-tracks', 'maintenance-product-status-tracks'].includes(entityName))
              if (!!updateParentEntity) {
                let data = result.data.data[getEntityKey(parentEntityName)]
                if (['maintenance-order-status-tracks', 'maintenance-product-status-tracks'].includes(entityName)) {
                  data = result.data.data
                } else {
                  let currentEntityName = entityName?.replace('-', '_')
                  if (!!data && !data[currentEntityName]) {
                    data[currentEntityName] = listingData
                  }
                }
                updateParentEntity(arrangeListingData(entityName, [data]), parentEntityName, parentEntityName === 'service-requests')
              }
              if (['maintenance-orders', 'maintenance-order-status-tracks', 'maintenance-product-status-tracks', 'installation-order-products'].includes(entityName) || !!shouldCloseDialog) {
                setTimeout(() => cancelDialog(), 200)
              }
            } else {
              /** Close Dialog**/
              if (!!closeConfirmDialog) {
                closeConfirmDialog()
                setTimeout(() => cancelDialog(), 200)
              } else {
                cancelDialog()
              }
              /** Reload Listing after Add/Edit/Delete**/
              setTimeout(() => {
                if (listingPage === 0) {
                  getListingData()
                }
                setListingPage(0)
              }, 500)
            }
          }
        }).then(() => {
          setDeleteItemLoading(false)
          if (!!FormikActions) {
            FormikActions.setSubmitting(false)
          }
          if (!!anyCallback) {
            anyCallback()
          }
        })
      }
    }
  }

  const getEntityKey = (entityName) => {
    let entityKey = entityName
    switch (entityName) {
      case 'service-requests':
        entityKey = 'service_request'
        break
      case 'service-request-schedules':
        entityKey = 'service_request_schedule'
        break
      default:
        break
    }
    return entityKey
  }
  const openDialog = (type, selectedRow, id, handleMenuClose, updateParentEntity) => {
    if (!!handleMenuClose) {
      handleMenuClose()
    }
    setCurrentSelectedRow(selectedRow || {})
    setCurrentDialogAction(type || 'create')
    if (type === 'delete' && !!listCRUD) {
      deleteRecord(selectedRow, id, updateParentEntity)
    } else {
      setIsDialogOpen(!isDialogOpen)
    }
  }

  const cancelDialog = () => {
    setIsDialogOpen(false)
    if (!!needRefreshListingData) {
      setNeedRefreshListingData(false)
      setTimeout(() => {
        if (listingPage === 0) {
          getListingData()
        }
        setListingPage(0)
      }, 500)
    }
  }

  const [currentDataLoading, setCurrentDataLoading] = React.useState(false)
  const loadCurrentData = () => {
    if (!!Configuration.isAuth() && !!currentSelectedRow?.id) {
      setCurrentDataLoading(true)
      ListingServices.getSingleEntity(entityName, currentSelectedRow?.id, intl.formatMessage({ id: `listing.Error.${entityName}` }))
        .then((result) => {
          if (!!result && !!result.data && !!result.data.data) {
            let newData = arrangeListingData(entityName, [result.data.data])
            if (!!newData?.length) {
              newData = newData[0]
              setCurrentSelectedRow(newData)
            }
          }
        }).then(() => {
        setCurrentDataLoading(false)
      })
    }
  }

  const updateCurrentSelectedItem = (newData, entityName, needRefreshListing, noEditSwitch) => {
    if (!!newData?.length) {
      newData = newData[0]
      if (!!newData && !!Object.keys(newData || {})?.length) {
        newData.id = newData?.id || listingData?.length + 1
        if (!!Object.keys(newData || {}).length) {
          setCurrentSelectedRow({ ...currentSelectedRow, ...newData } || {})
        }
        switch (entityName) {
          case 'service-requests':
            setNeedRefreshListingData(true)
            break
          case 'service-request-schedules':
          case 'installation-orders':
          case 'maintenance-orders':
          case 'installation-order-products':
          case 'maintenance-order-products':
          case 'product-quantities':
            let dataIndex = listingData?.map(item => item?.id?.toString()).indexOf(newData?.id?.toString())
            if (currentDialogAction === 'delete') {
              if (dataIndex > -1) {
                let data = listingData
                data.splice(dataIndex, 1)
                setListingData(data)
              }
            } else {
              let dataArranged = arrangeListingData(entityName, [newData])
              if (!!dataArranged?.length) {
                if (dataIndex > -1) {
                  let data = listingData
                  data[dataIndex] = dataArranged[0]
                  setListingData(data)
                } else {
                  let data = listingData
                  data.push(newData)
                  setListingData(data)
                }
              }
            }


            break
          default:
            break
        }
        if (!noEditSwitch) {
          setCurrentDialogAction('edit')
        }
      }
    }
  }

  const deleteRecord = (selectedRow, id, updateParentEntity) => {
    let dataIndex = listingData?.map(item => item?.id?.toString()).indexOf(selectedRow?.id?.toString())
    if (dataIndex > -1) {
      let data = listingData
      data.splice(dataIndex, 1)
      setListingData(data)
    }
    if (!!updateParentEntity) {
      setTimeout(() => updateParentEntity(listingData), 500)
    }
  }
  return (
    <ListingContext.Provider value={{
      entityName,

      listingData,
      listingHeader,
      listingLoading,

      listingCount,
      listingRowsPerPage,
      listingPage,
      handleChangeListingPage,
      handleChangeListingRowsPerPage,
      handleChangeListingSort,


      isDialogOpen,
      openDialog,
      cancelDialog,

      updateCurrentSelectedItem,
      currentSelectedRow,
      currentDialogAction,
      handleListingFilterSubmit,

      dialogActionSubmit,
      deleteItemLoading,

      exportListing,
      listingExportLoading,

      loadCurrentData,
      currentDataLoading
    }}>
      {children}
    </ListingContext.Provider>
  )
}

ListingProvider.propTypes = {
  entityName: PropTypes.string,
  children: PropTypes.any
}
const ListingConsumer = ListingContext.Consumer

export { ListingProvider, ListingConsumer }
