import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  FormControl,
  InputLabel, FormHelperText, Button
} from '@material-ui/core'
import Configuration from '../../../../Services/Api/Configuration'
import { ListingConsumer, ListingProvider } from '../../../../context/ListingContext'
import AddNewDialog from '../../../SearchBar/Dialog/AddNewDialog'
import ShowEntityImage from '../../../ShowEntityImage'

const FormUploadField = ({ formActions, classes, name, labelKey, required, row, types, currentData }) => {
  const intl = useIntl()

  const handleFileChange = (event, fieldName) => {
    event.preventDefault()
    let files = event.currentTarget.files
    if (files && files.length > 0) {
      if (!!formActions.errors[fieldName]) {
        formActions.setFieldError(fieldName, null)
      }
      let file = files[0]
      if (types?.includes(file.type)) {
        if (file.size <= Configuration.UPLOAD_FILE_SIZE) {
          formActions.setFieldValue(fieldName, file)
          if (!!setFilesName) {
            setFilesName(event.target.value.replace(/.*(\/|\\)/, ''))
          }
        } else {
          formActions.setFieldTouched(fieldName, true, true)
          setTimeout(() => formActions.setFieldError(fieldName, intl.formatMessage({ id: 'validationSchema.largeImage' })), 50)
        }
      } else {
        formActions.setFieldTouched(fieldName, true, true)
        setTimeout(() => formActions.setFieldError(fieldName, intl.formatMessage({ id: 'validationSchema.unSupported' })), 50)

      }
    }
  }
  const [filesName, setFilesName] = React.useState(intl.formatMessage({ id: labelKey }))
  return (
    <div className={classes.FormItem + (!!row ? ` ${classes.FormRow}` : '')}>
      <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
      <span className={'required'}>*</span>}:</InputLabel>
      <FormControl variant="outlined" margin="dense">
        <div className={classes.fileUploadWrapper} data-text={filesName}>
          <input
            accept={types?.join(',')}
            className={classes.fileUpload}
            name={name}
            id={name}
            type="file"
            onChange={(event) => handleFileChange(event, name)}
          />
        </div>
        {!!currentData && !!currentData[name] &&
        <div>
          <ListingProvider entityName={'contacts'} listArr={[]}>
            <ListingConsumer>
              {({ openDialog, isDialogOpen, cancelDialog }) => (
                <>
                  <Button color="primary" variant={'text'}
                          onClick={() => openDialog('showImage', currentData, currentData.id)}>
                    <FormattedMessage id={labelKey}/>
                  </Button>
                  <AddNewDialog
                    children={<ShowEntityImage/>}
                    dialogAction={'showImage'}
                    DialogTitle={<FormattedMessage id={`EntityPageContent.contacts.SearchBar.dialogTitle.showImage`}
                                                   values={{ name: currentData?.name || '' }}/>}
                    isDialogOpen={isDialogOpen}
                    cancelDialog={cancelDialog}
                  />
                </>
              )}
            </ListingConsumer>
          </ListingProvider>
        </div>
        }
        {formActions.errors[name] && formActions.touched[name] && (
          <FormHelperText error={true}>{formActions.errors[name]}</FormHelperText>
        )}
      </FormControl>
    </div>
  )
}

export default FormUploadField
