import axios from 'axios'
import Configuration from './Api/Configuration'
import { log } from '../utils/helpers'
import { LocalStorageService } from './LocalStorageService'

let { BASE_URL } = Configuration


axios.defaults.baseURL = BASE_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
// axios.defaults.headers.common['Accept'] = 'application/json'

/**
 *  Add a request interceptor
 */
axios.interceptors.request.use((config) => {
  LocalStorageService.get('CRDLanguage').then((appLanguage) => {
    config.headers['Accept-Language'] = appLanguage
  })
  return config
}, (error) => { log(error) })

