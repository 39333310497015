import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    '&.formOnDialog': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    '& .MuiAlert-root': {
      width: '100%'
    }
  },
  FormItem: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('md')]: {
      width: '48%',
      '.additional-row &': {
        width: '41%'
      }
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.primary,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        margin: 0,
        minHeight: 40,
        minWidth: '22%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(2)
      }
    },
    '&$FormRow .MuiInputLabel-root': {
      [theme.breakpoints.up('sm')]: {
        minWidth: '15%'
      }
    },
    '& .MuiFormControlLabel-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .MuiOutlinedInput-root': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: theme.spacing(0.5)
    },
    '& .MuiFormControl-root, & .MuiAutocomplete-root': {
      flex: '1 1 auto',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '& .MuiFormGroup-root[role="radiogroup"]': {
      flexDirection: 'row',
      '& .MuiFormControlLabel-root': {
        marginBottom: theme.spacing(0)
      }
    },
    '& a': {
      display: 'block',
      width: '100%',
      textAlign: 'right',
      '&.normal': {
        display: 'inline-block',
        width: 'auto',
        textAlign: 'inherit'
      }
    },
    '& .edit-slug-btn': {
      marginRight: theme.spacing(-0.75),
      padding: theme.spacing(0.75)
    }
  },
  autoCompleteWrapper: {
    flex: '1 1 auto',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  FormInner: {
    justifyContent: 'center',
    '& $FormItem': {
      [theme.breakpoints.up('md')]: {
        width: '67%'
      }
    }
  },
  addNewEntityButton: {
    float: theme.direction === 'ltr' ? 'right' : 'left',
    textTransform: 'none',
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'flex-end'

    }
  },
  FormItemSelect: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  FormRow: {
    width: '100%'
  },
  FormRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%'
  },

  PhoneInputWrapper: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    textAlign: theme.direction === 'ltr' ? 'left' : 'right',
    flex: '1 1 auto',
    // width: '100%',
    '& .react-tel-input': {
      fontFamily: 'inherit',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& .react-tel-input .form-control': {
      width: '100%',
      paddingTop: theme.spacing(1.325),
      paddingBottom: theme.spacing(1.125),
      paddingRight: theme.spacing(5.625),
      '&[disabled]': {
        cursor: 'pointer'
        // backgroundColor: theme.palette.colors.body
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
      }
    },
    '& $PhoneInputButton': {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      '& .react-tel-input': {
        fontFamily: 'inherit',
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0)
      }
    },
    '& .country-list': {
      direction: 'ltr',
      textAlign: 'left',

      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: 'rtl',
        textAlign: 'right',
        display: 'inline-block'
      }
    }
  },
  FormRowList: {
    backgroundColor: theme.palette.colors.offWhite,
    padding: theme.spacing(2),
    '&:not(.noMarTop)': {
      marginTop: theme.spacing(3)
    }
  },
  FormListHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1)
  },
  EntityListContainer: {
    width: '100%',
    padding: theme.spacing(2, 1),
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: 50
  },
  ItemHeadingWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  EntityActionsWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  ItemHeading: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(0),
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.primary.main,
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(30)
    },
    '&.case .state-wrapper': {
      marginLeft: theme.spacing(2)
    }
  },
  EntityInfoWrapper: {
    width: '100%',
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    marginBottom: theme.spacing(2),
    '&.center': {
      justifyContent: 'center'
    }
  },
  infoItemWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    // fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      '&:nth-of-type(2n+1)': {
        backgroundColor: theme.palette.colors.table
      }
    },
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(4n+1),&:nth-of-type(4n+2)': {
        backgroundColor: theme.palette.colors.table
      }
    }
  },
  infoItemLabel: {
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.spacing(2)
  },
  EntityInfoContainer: {
    width: '100%'
  },
  EntityPermissionContainer: {
    padding: theme.spacing(3)
  },
  entityPermissionWrapper: {
    marginBottom: theme.spacing(2)
  },
  entityPermissionContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  entityPermissionListWrapper: {
    '& label:not(:first-child)': {
      marginLeft: theme.spacing(2)
    }
  },

  FileUpload: {
    '& input': {
      display: 'none'
    }
  },
  fileUploadWrapper: {
    position: 'relative',
    width: '100%',
    height: 40,
    boxShadow: `0px 0px 5px 2px ${fade(theme.palette.colors.border2, 0.2)}`,
    borderRadius: theme.spacing(0.75),

    '&:after': {
      content: 'attr(data-text)',
      fontSize: theme.typography.pxToRem(14),
      position: 'absolute',
      top: 0,
      left: 0,
      background: theme.palette.common.white,
      padding: theme.spacing(1.25, 2),
      display: 'block',
      width: 'calc(100% - 65px)',
      pointerEvents: 'none',
      zIndex: 20,
      height: 40,
      lineHeight: theme.typography.pxToRem(18),
      color: '#999',
      borderRadius: theme.spacing(0.625, 1.25, 1.25, 0.625),
      fontWeight: theme.typography.fontWeightRegular,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    },
    '&:before': {
      content: `${theme.direction === 'ltr' ? '\'Upload\'' : '\'إرفاق\''}`,
      position: 'absolute',
      top: 0,
      right: 0,
      display: 'inline-block',
      height: 40,
      transition: 'all 0.3s ease-in-out',
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      zIndex: 25,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(38),
      padding: theme.spacing(0, 3),
      textTransform: 'uppercase',
      pointerEvents: 'none',
      borderRadius: theme.spacing(0, 0.625, 0.625, 0)
    },

    '&:hover': {
      '&:before': {
        background: theme.palette.primary.dark
      }
    },
    '& input': {
      opacity: 0,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 99,
      height: 40,
      margin: 0,
      padding: 0,
      display: 'block',
      cursor: 'pointer',
      width: '100%'
    }
  },


  actionButtonsWrapper: {
    padding: theme.spacing(0),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    '& button': {
      lineHeight: 1.3,
      padding: theme.spacing(1.25, 2.75),
      borderRadius: theme.spacing(1.25),
      [theme.breakpoints.up('sm')]: {
        minWidth: 146
      },
      '& + button': {
        marginLeft: theme.spacing(2)
      }
    }
  },
  DeleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(80),
    color: theme.palette.warning.main
  },

  chip: {
    margin: theme.spacing(0.5)
  },
  stateWrapper: {
    display: 'flex'
  },
  statusButton: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(1)
  },
  FormFieldsMoreWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& $FormRowList': {
      marginBottom: theme.spacing(2)
    },
    '&.additional': {
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.primary.main}`,
      padding: theme.spacing(2)
    }
  },
  FormFieldTitle: {
    // marginBottom: theme.spacing(-2),
    width: '70%'
  },
  removeWrapper: {
    marginTop: theme.spacing(2)
  },
  AddNewRecord: {
    flex: '1 1 auto'
  },
  removeRecord: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  tabsRoot: {
    boxShadow: `0px 2px 4px -1px ${fade(theme.palette.common.black, 0.2)}, 0px 4px 5px 0px ${fade(theme.palette.common.black, 0.14)}, 0px 1px 10px 0px ${fade(theme.palette.common.black, 0.12)}`,
    marginTop: theme.spacing(4),
    '& .MuiTabs-indicator': {
      height: theme.spacing(0.5)
    },
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      fontSize: theme.typography.pxToRem(16)
    },
    '& > div[role="tabpanel"] .listing-wrapper': {
      minHeight: 192
    }
  }
}))
