import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  formCard: {
    maxWidth: 426,
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    border: `1px solid ${theme.palette.colors.border}`,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1.5)
    }
  },
  formCardTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(2.25),
    textAlign: 'center',
    margin: 0,
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.lighter,
    boxShadow: `0 0 0 1px ${theme.palette.colors.border}`
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(5.25, 2.25),
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px,12px) scale(1)'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(0.625),
      backgroundColor: theme.palette.common.white,
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      border: 'solid 1px #e7e7e7',
      transition: 'all 0.3s ease-in-out',
      '& legend': {
        lineHeight: 0
      }
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: theme.spacing(1.125),
      paddingBottom: theme.spacing(1.125)
    },
    '& .MuiAlert-root': {
      width: '100%',
      marginBottom: theme.spacing(3),
      borderRadius: theme.spacing(0.5),
      boxShadow: '0 8px 16px -4px rgba(0, 0, 0, 0.16)',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43
    }
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  formInputLabel: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transform: 'translate(0,0) scale(1)',
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.45,
    marginBottom: theme.spacing(.375)
  },
  actionButtonsWrapper: {
    padding: theme.spacing(0),
    textAlign: 'right',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    flexDirection: 'column'
  },
  addRecordButton: {
    '&.MuiButton-root': {
      textTransform: 'capitalize',
      borderRadius: theme.spacing(0.625),
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.3,
      minWidth: 146,
      width: '100%',
      textAlign: 'center',
      color: theme.palette.common.white,
      padding: theme.spacing(1.625, 2.75),
      '&:disabled': {
        opacity: 0.7,
        cursor: 'not-allowed'
      },
      '&:hover, &:focus': {
        opacity: 0.75
      }
    }
  },
  changePasswordLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    marginTop: theme.spacing(1.75)
  },
  authPageContent: {
    '& .auth': {
      flexDirection: 'column',
      '& a': {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        marginBottom: theme.spacing(1),
        fontSize: theme.typography.pxToRem(20),
        maxWidth: 308,
        display: 'block'
      }
    }
  },
  DeleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'pre-line',
    '& p': {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
      marginTop: theme.spacing(2)
    }
  },
  DeleteWarningIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(80),
    color: theme.palette.warning.main
  },
  ApproveIcon: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(80),
    color: theme.palette.success.main
  },
  returnLink: {
    '&:hover, &:focus': {
      color: theme.palette.common.white
    }
  }
}))
