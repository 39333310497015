import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TextField,
  FormControl,
  InputLabel, InputAdornment
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import InputMask from 'react-input-mask'

const FormTextFieldWithMask = ({ formActions, classes, name, labelKey, required, isSearch, email, row, lines, onChange, otherAttributes = {}, mask, formatChars }) => {
  const intl = useIntl()
  return (
    !!isSearch ?
      <FormControl className={classes.formControl} variant="outlined">
        <InputMask
          mask={mask}
          required={required}
          value={formActions.values[name]}
          disabled={false}
          maskChar="_"
          name={name}
          id={name}
          formatChars={formatChars}
          onChange={onChange ?? formActions.handleChange}
          onBlur={formActions.handleBlur}
        >
          {() => <TextField
            required={required}
            fullWidth
            multiline={(lines || 1) > 1}
            rows={lines || 1}
            margin="dense"
            type={!!email ? 'email' : 'text'}
            name={name}
            id={name}
            variant="outlined"
            InputProps={{
              startAdornment:
                <InputAdornment position="start">
                  <SearchIcon/>
                </InputAdornment>
            }}
            placeholder={intl.formatMessage({ id: labelKey })}
            InputLabelProps={{ shrink: !!formActions.values[name] }}
            helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
            error={formActions.errors[name] && formActions.touched[name] !== undefined}
          />}
        </InputMask>


      </FormControl>
      :
      <div className={classes.FormItem + (!!row ? ` ${classes.FormRow}` : '')}>
        <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <FormControl variant="outlined">
          <InputMask
            mask={mask}
            value={formActions.values[name]}
            disabled={false}
            maskChar="_"
            name={name}
            id={name}
            required={required}
            formatChars={formatChars}
            onChange={onChange ?? formActions.handleChange}
            onBlur={formActions.handleBlur}
          >
            {() => <TextField
              required={required}
              fullWidth
              multiline={(lines || 1) > 1}
              rows={lines || 1}
              margin="dense"
              type={!!email ? 'email' : 'text'}
              name={name}
              id={name}
              variant="outlined"
              placeholder={intl.formatMessage({ id: labelKey })}
              error={formActions.errors[name] && formActions.touched[name] !== undefined}
              helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
              {...otherAttributes}
            />}
          </InputMask>

        </FormControl>
      </div>
  )
}

export default FormTextFieldWithMask
