import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewVehicleProductTransferFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const productsFilter = { 'type': 'simple' }
const VehicleProductsTransferForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  /* eslint-disable no-unused-vars */
  const [sensorsFilter, setOperatorsFilter] = React.useState({ 'type': 'simple', ...(!!currentData?.sensors?.length ? { id: currentData?.sensors?.map(item => item.id) || null } : {}) })
  /* eslint-enable no-unused-vars */

  const formActions = useFormik({
    initialValues: {
      vehicle_product_id: currentData?.id || '',
      vehicle_id: currentData?.vehicle?.id || null,
      sensor_ids: !!currentData?.sensors?.length ? currentData?.sensors?.map(item => item.id) : []
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewVehicleProductTransferFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <ListingProvider entityName={'vehicles'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'vehicle_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'id'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.vehicle'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'products'} filter={productsFilter} initFilter={sensorsFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'sensor_ids'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.sensors'} required multiple addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default VehicleProductsTransferForm
