import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  headerRowWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  pdfContentWrapper: {
    overflow: 'hidden',
    height: 0,
    zIndex: -1,
    position: 'fixed',
    top: 0,
    left: 0,
    // background: 'white',
    // maxHeight: '100vh',
    // overflow: 'auto',
    // zIndex: 5000
  },
  pdfContent: {
    width: 1240,
    height: 1754,
    position: 'relative',
    padding: theme.spacing(5),
    '& .auth': {
      flexDirection: 'column',
      '& a': {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        marginBottom: theme.spacing(2.75),
        fontSize: theme.typography.pxToRem(20),
        maxWidth: 308,
        display: 'block'
      }
    }
  },
  infoItemWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    padding: theme.spacing(1.25),
    '&:nth-of-type(2n+1)': {
      backgroundColor: theme.palette.primary.lighter
    },
    '&.adv-amount': {
      color: theme.palette.error.main
    }
  },

  pdfTitle: {
    fontSize: theme.typography.pxToRem(24)
  },
  RequestDataWrapper: {
    paddingTop: theme.spacing(1.5),
    '& $infoItemWrapper:not(.status)': {
      backgroundColor: 'transparent',
      '&:nth-of-type(2n+1)': {
        backgroundColor: theme.palette.primary.lighter
      }
    },
    '& $infoItemWrapper.status': {
      backgroundColor: 'transparent',
      '&:nth-of-type(2n+1)': {
        backgroundColor: theme.palette.primary.lighter
      }
    }
  },
  RequestHeading: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: theme.spacing(3.5, 0, 2),
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.primary.main,
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(30)
    }
  },
  phoneWrapper: {
    direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  advAmountIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5)
  },
  infoItemLabel: {
    paddingRight: theme.spacing(2),
    '&.status': {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      '& span': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.dark,
        marginRight: theme.spacing(0.75)
      }
    }
  },
  infoItemValue: {
    fontWeight: theme.typography.fontWeightBold
  },
  infoItemPhone: {
    display: 'inline-block',
    direction: theme.direction === 'rtl' ? 'rtl' : 'ltr'
  }
}))
