import React from 'react'
import { Box } from '@material-ui/core'
import ViewTicket from './ViewTicket'
import ViewCustomer from './ViewCustomer'

const ViewEntity = ({ entityName, loadCurrentData, currentDataLoading, listingHeader, currentData }) => {
  const searchFiltersSwitcher = () => {
    switch (entityName) {
      case 'tickets':
        return <ViewTicket loadCurrentData={loadCurrentData}
                           currentDataLoading={currentDataLoading}
                           listingHeader={listingHeader}
                           currentData={currentData}/>
      case 'customers':
        return <ViewCustomer loadCurrentData={loadCurrentData}
                             currentDataLoading={currentDataLoading}
                             listingHeader={listingHeader}
                             currentData={currentData}/>
      default:
        return ''
    }
  }
  return (
    <Box p={3}>
      {!!entityName && searchFiltersSwitcher()}
    </Box>
  )
}

export default ViewEntity
