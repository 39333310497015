import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewMaintenanceOrderProductsFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const MaintenanceOrderProductsForm = ({ onSubmit, currentData, cancelDialog, currentAction, updateParentEntity, otherUpdateParentEntity, parentEntityName }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: '',
      vehicle_product: {}
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewMaintenanceOrderProductsFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, false, null, true, null, updateParentEntity, parentEntityName)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.resetForm()
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('vehicle_product', {
          id: currentData?.id,
          product: currentData?.product,
          vehicle: currentData?.vehicle
        } || {})
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])


  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <ListingProvider entityName={'vehicle-products'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'vehicle_product'}
                                 list={listingData} loading={listingLoading} allEntity entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 row labelKey={'Fields.Labels.product'} required/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default MaintenanceOrderProductsForm
