import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { GeneralConsumer } from '../../context/GeneralContext'
import MobileMenuUserActions from './MobileMenuUserActions'
import MainNavigation from '../MainNavigation'
import { useStyles } from './Styles'
import { AuthConsumer } from '../../context/AuthContext'
import { Avatar } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const MobileMenu = () => {
  const classes = useStyles()

  return (
    <GeneralConsumer>{({ mobileMenuState, toggleMobileMenuDrawer }) => (
      <>
        <div>
          <IconButton
            edge="end"
            className={classes.menuButton}
            aria-label="open drawer"
            onClick={() => toggleMobileMenuDrawer(true)}
          >
            <MenuIcon/>
          </IconButton>
        </div>
        <Drawer className={classes.navigationDrawerWrapper} anchor='right' open={mobileMenuState}
                onClose={() => toggleMobileMenuDrawer(false)}>
          <div className={classes.navigationDrawer}>
            <IconButton
              edge="end"
              className={classes.menuCloseButton}
              color="inherit"
              aria-label="close drawer"
              onClick={() => toggleMobileMenuDrawer(true)}
            >
              <CloseIcon/>
            </IconButton>
            <AuthConsumer>
              {({ userData }) => (
                <div className={classes.userNameWrapper}>
                  <Avatar alt={userData?.name?.toUpperCase()} src="/broken-image.jpg"
                          className={classes.userAvatar}/>
                  <span className={classes.username}>{(!!userData && !!Object.keys(userData).length) ? (
                    `${userData?.name || userData.email}`
                  ) : <FormattedMessage id='UserMenu.username'/>}</span>
                </div>
              )}
            </AuthConsumer>
            <MainNavigation/>
            <MobileMenuUserActions toggleMobileMenuDrawer={toggleMobileMenuDrawer}/>
          </div>
        </Drawer>
      </>
    )}
    </GeneralConsumer>
  )
}

export default MobileMenu
