import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  searchBarWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: theme.spacing(0.875),
    padding: theme.spacing(2.25, 1.5),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    '&.alignEnd': {
      justifyContent: 'flex-end'
    }
  },
  searchBarAddNewButton: {
    '&.MuiButton-root': {
      minWidth: 146,
      minHeight: 37,
      lineHeight: 1.5,
      flex: '0 0 auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      '&.export': {
        minWidth: 120
      }
    },
    '&:not(.with-sort)': {
      '&.MuiButton-root': {
        marginRight: theme.spacing(2)
      }
    },
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  searchBarSortingWrapper: {
    display: 'flex',
    flex: '0 0 auto',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      flex: '1 1 auto'
    }
  },
  searchBarSortButton: {
    '&.MuiButton-root': {
      minHeight: 37,
      lineHeight: 1.5,
      flex: '0 0 auto',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),

      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2)
      }
    }
  }
}))
