import React from 'react'
import { FormattedMessage } from 'react-intl'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { useStyles } from './Styles'
import { Grid } from '@material-ui/core'
import Logo from '../../Logo'
import { LanguageConsumer } from '../../../context/LanguageContext'

const OrderPDFHeader = ({ content, entityName }) => {
  const classes = useStyles()
  let fields = [
    { key: 'id', value: content.id },
    { key: 'status', value: content.status },
    { key: 'status_track', value: content.status_track },
    { key: 'PrintDate', value: '' }
  ]
  return (
    <LanguageConsumer>
      {({ locale }) => (
        <>
          <h2 align={'center'} className={classes.pdfTitle}><FormattedMessage
            id={`${entityName}.Print.Title`}
            values={{ id: content.id }}/></h2>
          <div className={classes.headerRowWrapper}>
            <Grid item xs={6}>
              {fields.map((field, index) => (
                (!!field.value || ['PrintDate'].includes(field.key)) &&
                <Grid item xs={12} className={classes.infoItemWrapper} key={index}>
                  <Grid item xs={3} className={classes.infoItemLabel}>
                    <FormattedMessage id={`Fields.label.PDF.view.${field.key}`}/>
                  </Grid>
                  <Grid item xs={9} className={classes.infoItemValue}>
                    {['PrintDate'].includes(field.key) ?
                      <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'} format="Do MMMM YYYY - h:mm A"/> :
                      (['status_track'].includes(field.key) ?
                        <FormattedMessage id={`statusTrack.${field.value}`}/> :
                        (['status'].includes(field.key) ?
                          <FormattedMessage id={`status.${field.value}`}/>
                          : field.value))
                    }
                  </Grid>
                </Grid>))}
            </Grid>
            <Grid item xs={5}>
              <Logo/>
            </Grid>
          </div>
        </>
      )}
    </LanguageConsumer>
  )
}

export default OrderPDFHeader
