import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { Button } from '@material-ui/core'
import { AddNewServiceRequestScheduleFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import { LanguageConsumer } from '../../../context/LanguageContext'
import FormNumberField from './Fields/FormNumberField'
import FormSwitchField from './Fields/FormSwitchField'
import FormListAddMore from './Fields/FormListAddMore'
import FormDateTimeField from './Fields/FormDateTimeField'
import OrderPDFContent from '../OrderPDFContent'

const ServiceRequestSchedulesForm = ({ onSubmit, currentData, cancelDialog, currentAction, entityName, type, injectedFormValues, handleInjectedFormValues, updateParentEntity, parentEntityName }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: '',
      service_request_id: '',
      start_date: '',
      end_date: '',
      address: '',
      address_description: '',
      is_needs_visit: false,
      vehicle_count: 0,
      note: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewServiceRequestScheduleFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers, false, null, true, null, updateParentEntity, parentEntityName, null, true)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(injectedFormValues || {}).length) {
        formActions.setFieldValue('service_request_id', injectedFormValues['service-requests'] || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [injectedFormValues])
  React.useEffect(() => {
      if (!!Object.keys(currentData || {}).length) {
        formActions.setFieldValue('id', currentData?.id)
        formActions.setFieldValue('address', currentData?.address || '')
        formActions.setFieldValue('address_description', currentData?.address_description || '')
        formActions.setFieldValue('note', currentData?.note || '')
        formActions.setFieldValue('vehicle_count', currentData?.vehicle_count || 0)
        formActions.setFieldValue('is_needs_visit', !!currentData?.is_needs_visit || false)
        handleInjectedFormValues(entityName, currentData?.id)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])


  const [startDate, setStartDate] = React.useState(currentData?.start_date ? new Date(currentData?.start_date) : null)
  const [endDate, setEndDate] = React.useState(currentData?.end_date ? new Date(currentData?.end_date) : null)

  const handleDateChange = (date, setDate) => {
    setDate(date)
    // formActions.setFieldValue('archiving_date', date || '')
  }
  React.useEffect(() => {
      if (!!startDate) {
        formActions.setFieldValue('start_date', startDate || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate])

  React.useEffect(() => {
      if (!!endDate) {
        formActions.setFieldValue('end_date', endDate || '')
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [endDate])

  return (
    <LanguageConsumer>
      {({ locale }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
          <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

            <FormDateTimeField formActions={formActions} classes={classes} name={'start_date'}
                               labelKey={'Fields.Labels.start_date'} disablePast
                               required value={startDate}
                               handleChange={(date) => handleDateChange(date, setStartDate)}/>


            <FormDateTimeField formActions={formActions} classes={classes} name={'end_date'}
                               labelKey={'Fields.Labels.end_date'} disablePast
                               required value={endDate} handleChange={(date) => handleDateChange(date, setEndDate)}/>

            <FormNumberField formActions={formActions} classes={classes} name={'vehicle_count'}
                             labelKey={'Fields.Labels.vehicle_count'}
                             required/>

            <FormSwitchField formActions={formActions} classes={classes} name={'is_needs_visit'}
                             labelKey={'Fields.Labels.is_needs_visit'} labelPrefix={'needs_visit'}/>

            <FormTextField formActions={formActions} classes={classes} name={'address'}
                           labelKey={'Fields.Labels.address'}
                           required row lines={2}/>

            <FormTextField formActions={formActions} classes={classes} name={'address_description'}
                           labelKey={'Fields.Labels.address_description'}
                           required row lines={2}/>

            <FormTextField formActions={formActions} classes={classes} name={'note'}
                           labelKey={'Fields.Labels.note'}
                           required row lines={4}/>

            <ListingConsumer>
              {({ updateCurrentSelectedItem }) => (
                <FormListAddMore labelKey={`Fields.Labels.${type || 'installation'}Orders`}
                                 entityName={`${type || 'installation'}-orders`}
                                 id={currentData?.id}
                                 currentData={currentData}
                                 formActions={formActions} classes={classes} parentEntityName={entityName}
                                 onSubmit={onSubmit}
                                 print
                                 printContent={OrderPDFContent}
                                 statusUpdate={type === 'maintenance'}
                                 checkStatusUpdateKey='id'
                                 injectedFormValues={injectedFormValues}
                                 handleInjectedFormValues={handleInjectedFormValues}
                                 updateParentEntity={updateCurrentSelectedItem}
                                 list={currentData[`${type}_orders`] || []}
                />
              )}
            </ListingConsumer>

            <div className={classes.actionButtonsWrapper}>
              <Button type="submit" variant='contained' color='primary'
                      className={classes.addRecordButton} id='addDialogRecordButton'
                      onClick={formActions.handleSubmit}
                      disabled={formActions.isSubmitting || !formActions.isValid}
              >
                <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
              </Button>
              <Button variant='outlined' color='primary'
                      onClick={cancelDialog}>
                <FormattedMessage id='AddNewDialog.Cancel'/>
              </Button>
            </div>
            {formActions.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </MuiPickersUtilsProvider>
      )}
    </LanguageConsumer>
  )
}

export default ServiceRequestSchedulesForm
