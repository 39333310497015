import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  searchFilter: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: '1 1 auto',
      flexWrap: 'wrap',
      justifyContent: 'center',
      // flexDirection: 'column',
      marginTop: theme.spacing(1)
    }
  },
  formControl: {
    maxWidth: 220,
    width: '100%',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0),
      width: '100%',
      maxWidth: 'inherit'
    },
    [theme.breakpoints.between(theme.customBreakpoints.mdl, 'md')]: {
      '& .MuiInputLabel-formControl': {
        fontSize: theme.typography.pxToRem(14)
      }
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: theme.spacing(0.625),
      background: theme.palette.common.white,
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main
        }
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[300]
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.grey[300]
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      // paddingTop: theme.spacing(1.125),
      // paddingBottom: theme.spacing(1.125)
    },
    '&.select-control': {
      '& .MuiTextField-root, & .MuiSelect-root': {
        [theme.breakpoints.up('md')]: {
          minWidth: 150
        },
        [theme.breakpoints.up('lg')]: {
          minWidth: 165
        }
      }
    },
    '&:not(.select-control) .MuiInputLabel-marginDense:not(.MuiInputLabel-shrink)': {
      transform: 'translate(43px, 11px) scale(1)'
    }
  },
  PhoneInputWrapper: {
    direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
    textAlign: theme.direction === 'ltr' ? 'left' : 'right',
    flex: '1 1 auto',
    // width: '100%',
    '& .react-tel-input': {
      fontFamily: 'inherit',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    '& p': {
      whiteSpace: 'pre-line'
    },
    // '& .flag-dropdown': {
    //   display: 'none'
    // },
    '& .react-tel-input .form-control': {
      width: '100%',
      paddingTop: theme.spacing(1.325),
      paddingBottom: theme.spacing(1.125),
      paddingLeft: theme.spacing(5.625),
      '&[disabled]': {
        cursor: 'pointer',
        pointerEvents: 'none'
        // backgroundColor: theme.palette.colors.body
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
      }
    },
    '& $PhoneInputButton': {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      '& .react-tel-input': {
        fontFamily: 'inherit',
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0)
      }
    },
    '& .country-list': {
      direction: theme.direction === 'ltr' ? 'ltr' : 'ltr',
      textAlign: theme.direction === 'ltr' ? 'left' : 'left',

      '& .search': {
        paddingLeft: theme.spacing(1.25)
      },
      '& .search-box': {
        marginLeft: theme.spacing(1)
      },
      '& .country-name': {
        marginRight: theme.spacing(1)
      },
      '& .dial-code': {
        direction: theme.direction === 'ltr' ? 'ltr' : 'rtl',
        textAlign: theme.direction === 'ltr' ? 'left' : 'right',
        display: 'inline-block'
      }
    }
  },
  phoneLabel: {
    '&.MuiInputLabel-shrink': {
      padding: theme.spacing(1),
      background: `linear-gradient(180deg, ${fade(theme.palette.colors.body, 1)} 0%, ${fade(theme.palette.colors.body, 1)} 62%, ${fade(theme.palette.common.white, 0)} 62%, ${fade(theme.palette.common.white, 0)} 100%)`
    }
  },
  searchFilterSubmit: {
    '&.MuiButton-root': {
      padding: theme.spacing(1, 3.25),
      minWidth: 120,
      minHeight: 37,
      lineHeight: 1.5,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5)
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1)
    }
  }
}))
