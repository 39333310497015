import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button, Typography, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core'
import { AddNewRoleFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import FormTextField from './Fields/FormTextField'
import { NamedRoutes } from '../../../routes'

const RolesForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const [currentPermissions, setCurrentPermissions] = React.useState([])
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      name: currentData?.name || '',
      permissions: []
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewRoleFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  React.useEffect(() => {
      if (!!Object.keys(currentData || {})?.length) {
        setCurrentPermissions(currentData?.permissions?.length ? currentData?.permissions?.map(permission => permission?.name || '') : [])
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData])

  let entityChildren = NamedRoutes.entities.filter((item) => !!item.isParent).map(item => item?.children).reduce((a, b) => a.concat(b), [])
  let entities = [...new Set([...NamedRoutes.entities, ...entityChildren])].filter((entity) => !!entity?.endpoint && !entity.hidden)

  const handlePermissionChange = (event, permission, entity) => {
    let permissions = currentPermissions
    if (!!event.target.checked) {
      permissions.push(`${permission} ${entity}`)
    } else {
      let index = permissions.indexOf(`${permission} ${entity}`)
      if (index > -1) {
        permissions.splice(index, 1)
      }
    }
    setCurrentPermissions(permissions)
    formActions.setFieldValue('permissions', permissions)
  }
  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'name'} labelKey={'Fields.Labels.Name'}
                     required row/>

      {!!entities?.length &&
      <div className={classes.FormRow + ' ' + classes.FormRowList + ' ' + classes.EntityPermissionContainer}>
        <Typography variant="h5" component="h3"><FormattedMessage id={'Fields.Labels.permissions'}/><span
          className={'required'}>*</span>:</Typography>
        <ul>
          {entities?.map((entity, index) =>
            <li className={classes.entityPermissionWrapper} key={index}>
              <div className={classes.entityPermissionContainer}>
                <Typography variant="h6" component="h5"><FormattedMessage
                  id={`Permission.Entity.${entity?.name}`}/></Typography>
                <div className={classes.entityPermissionListWrapper}>
                  {['View', 'Create', 'Update', 'Delete'].map((permission, perIndex) =>
                    <FormControlLabel
                      key={`${index}-${perIndex}`}
                      control={
                        <Checkbox
                          checked={currentPermissions?.includes(`${permission} ${entity?.name}`)}
                          onChange={(event) => handlePermissionChange(event, permission, entity?.name)}
                          name={`permission-${entity?.name}-${permission}`}
                          color="primary"
                        />
                      }
                      label={permission}
                    />
                  )}
                </div>
              </div>
            </li>
          )}
        </ul>
        {formActions.errors.permissions && formActions.touched.permissions && (
          <FormHelperText error={true}>{formActions.errors.permissions}</FormHelperText>
        )}
      </div>
      }

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default RolesForm
