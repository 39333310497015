import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewTeamFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const supervisorFilter = { 'role': 'supervisor' }
const leaderFilter = { 'role': 'leader' }
const TeamsForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      title: currentData?.title || '',
      supervisor_id: currentData?.supervisor?.id || '',
      leader_id: currentData?.leader?.id || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewTeamFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'title'} labelKey={'Fields.Labels.title'}
                     required/>

      <ListingProvider entityName={'users'} filter={supervisorFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'supervisor_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.supervisor'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'users'} filter={leaderFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'leader_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.leader'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default TeamsForm
