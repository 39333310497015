import React from 'react'
import { Button } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import AddNewDialog from './Dialog/AddNewDialog'
import { ButtonLoader } from '../Loader/ButtonLoader'
import { useStyles } from './Styles'
import { FormattedMessage } from 'react-intl'
import SearchFilterForm from './SearchFilters/SearchFilterForm'
import { AuthConsumer } from '../../context/AuthContext'

const SearchBar = ({
                     buttonLabel, dialogTitle, dialogBody, dialogAction, handleListingFilterSubmit,
                     cancelDialog, isDialogOpen, openDialog, searchFilters, hasExport, exportListing,
                     listingExportLoading, hasCKEditor, hasSorting, entityName
                   }) => {
  const classes = useStyles()

  const getInitials = () => {
    let initials = { keyword: '' }
    if (!!searchFilters?.length) {
      searchFilters?.forEach(column => initials[column.searchId || column.id] = column.searchInitVal || '')
    }
    return initials
  }
  return (
    <AuthConsumer>
      {({ userPermissions }) => (
        <div className={classes.searchBarWrapper + (!buttonLabel ? ' alignEnd' : '')}>
          {!!hasExport ?
            <div className={classes.searchBarSortingWrapper}>
              <Button variant='contained'
                      color='primary'
                      disabled={!!listingExportLoading}
                      onClick={() => !!hasExport && exportListing()}
                      className={classes.searchBarAddNewButton + ' export'}>
                <CloudDownloadIcon/>
                <FormattedMessage id='ContentPageContent.SearchBar.Export.buttonLabel'/>
                {!!listingExportLoading && <ButtonLoader loading={true}/>}
              </Button>

              {!!buttonLabel && !!userPermissions.includes(`create ${entityName}`) &&
              <Button variant='contained'
                      color='primary'
                      onClick={() => openDialog()}
                      className={classes.searchBarAddNewButton + (!!hasSorting ? ' with-sort' : '')}>
                {buttonLabel}
              </Button>}

            </div> :
            (!!buttonLabel && (!!userPermissions.includes(`create ${entityName}`) ?
              <Button variant='contained'
                      color='primary'
                      onClick={() => openDialog()}
                      className={classes.searchBarAddNewButton + (!!hasSorting ? ' with-sort' : '')}>
                {buttonLabel}
              </Button> : <div/>))
          }
          <AddNewDialog
            children={dialogBody}
            dialogAction={dialogAction}
            DialogTitle={dialogTitle}
            isDialogOpen={isDialogOpen}
            cancelDialog={cancelDialog}
            hasCKEditor={hasCKEditor}
          />
          {!!searchFilters &&
          <SearchFilterForm searchFilters={searchFilters} initials={getInitials()}
                            onSubmit={handleListingFilterSubmit}/>}
        </div>
      )}
    </AuthConsumer>
  )
}

export default SearchBar
