import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '&:not(.inner)': {
      marginTop: theme.spacing(2.5),
      padding: theme.spacing(2.5)
    }
  },
  fullWidth: {
    width: '100%'
  },
  container: {
    // maxHeight: 440,
    // borderTop: `1px solid ${theme.palette.colors.border}`,
    // borderRight: `1px solid ${theme.palette.colors.border}`,
    // borderLeft: `1px solid ${theme.palette.colors.border}`,
    position: 'relative',
    '& .MuiTableCell-stickyHeader': {
      // textAlign: 'center',
      // fontSize: theme.typography.pxToRem(12),
      // fontWeight: theme.typography.fontWeightBold,
      // borderLeft: `1px solid ${theme.palette.colors.border}`,
      // backgroundColor: theme.palette.primary.lighter,
      // padding: theme.spacing(1.125, 2),
      whiteSpace: 'nowrap'
    },
    '& .MuiTableCell-body': {
      // padding: theme.spacing(1.5, 2)
    },
    '& .MuiTableCell-root': {
      // textAlign: 'center',
      // borderLeft: `1px solid ${theme.palette.colors.border}`,
      '.notification &': {
        textAlign: 'left',
        borderLeft: `none`
      }
    },
    '& tr.MuiTableRow-root:nth-of-type(even)': {
      // backgroundColor: theme.palette.primary.lighter
    }
  },
  userDataWrapper: {
    padding: theme.spacing(0.75),
    display: 'flex',
    '& label': {
      minWidth: 65,
      marginRight: theme.spacing(0.5)
    }
  },
  TableChip: {
    margin: theme.spacing(0.5)
  },
  CollapseList: {
    paddingLeft: theme.spacing(3),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0)
  },
  CollapseBtnWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  CollapseBtn: {
    padding: theme.spacing(0)
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  phoneWrapper: {
    direction: theme.direction === 'rtl' ? 'rtl' : 'ltr',
    display: 'inline-block',
    whiteSpace: 'nowrap'
  },
  colorBoxWrapper: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    transition: 'all 0.3s ease-in-out',
    marginRight: theme.spacing(1.5)
  },
  colorWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rolesList: {
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
    '& li': {
      whiteSpace: 'nowrap'
    }
  },
  sortRow: {
    cursor: 'move !important',
    '&.dragging': {
      display: 'flex',
      width: '100%',
      backgroundColor: `${theme.palette.colors.table} !important`,
      alignItems: 'center',
      '& td': {
        flex: '1 1 auto',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  },
  sortNumberWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'move',
    fontSize: theme.typography.pxToRem(20),
    '& span': {
      // width: 35,
      height: 30,
      padding: theme.spacing(0.5, 1.25),
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.spacing(1),
      marginRight: theme.spacing(1.5)
    }
  },
  nidImage: {
    maxWidth: 100,
    maxHeight: 70,
    cursor: 'pointer'
  }
}))
