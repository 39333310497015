import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  mainNavigationWrapper: {
    [theme.breakpoints.up(theme.customBreakpoints.mdl)]: {
      position: 'fixed',
      left: 0,
      height: 'calc(100vh - 64px)',
      overflowY: 'auto',
      width: theme.typography.pxToRem(240),
      padding: `${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)}`,
      background: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'flex-start'
    }
  },
  navCollapseList: {},
  mainNavigation: {
    width: '100%',
    '& ul': {
      listStyle: 'none',
      margin: theme.spacing(0),
      padding: theme.spacing(0),
      '&$navCollapseList': {
        paddingLeft: theme.spacing(1.5)
      }
    },
    '& a, & button': {
      color: theme.palette.primary.dark,
      padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(0)}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      wordBreak: 'break-word',
      width: '100%',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: 'capitalize',
      textDecoration: 'none',
      lineHeight: 1.36,
      [theme.breakpoints.down(theme.customBreakpoints.mdl)]: {
        color: theme.palette.common.white
      },
      '& i, & span': {
        paddingRight: theme.spacing(2.5),
        fontSize: theme.typography.pxToRem(18)
      },
      '& span': {
        lineHeight: 0.5,
        verticalAlign: 'middle'
      },
      '&.active': {
        color: theme.palette.secondary.main,
        [theme.breakpoints.down(theme.customBreakpoints.mdl)]: {
          color: theme.palette.text.primary
        }
      },
      '&:hover, &:focus': {
        color: theme.palette.secondary.main,
        [theme.breakpoints.down(theme.customBreakpoints.mdl)]: {
          color: theme.palette.text.primary
        }
      }
    }
  },
  navCollapseBtn: {
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      paddingRight: 0,
      lineHeight: 1.36,
      '& > div': {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center'
      }
    },
    '&:hover, &:focus': {
      backgroundColor: 'transparent'
    }
  }
}))
