import { fade, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  stateWrapper: {
    margin: theme.spacing(0.5),
    border: `1px solid ${fade(theme.palette.common.black, 0.23)}`,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1.5),
    textOverflow: 'ellipsis',
    borderRadius: theme.spacing(1.5),
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    textAlign: 'center',

    '&.closed': {
      backgroundColor: theme.palette.error.main,
      borderColor: theme.palette.error.main
    },
    '&.open': {
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.main
    },
    '&.on_track': {
      backgroundColor: '#3CB900',
      borderColor: '#3CB900'
    },
    '&.may_be_late': {
      backgroundColor: '#FFC938',
      borderColor: '#FFC938'
    },
    '&.urgent': {
      backgroundColor: '#FF9136',
      borderColor: '#FF9136'
    },
    '&.overdue': {
      backgroundColor: '#CC5B5B',
      borderColor: '#CC5B5B'
    },
    '&.innerState': {
      whiteSpace: 'normal',
      textAlign: 'center'
    }
  }
}))
