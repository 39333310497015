import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LanguageProvider } from './context/LanguageContext'
import { GeneralProvider } from './context/GeneralContext'
import {ThemeProvider} from './context/ThemeProvider'
import { AuthProvider } from './context/AuthContext'
import * as serviceWorker from './serviceWorker'
import Layout from './components/Layout'
import { Close as CloseIcon } from '@material-ui/icons'

const CloseButton = ({ closeToast, className }) => (
  <CloseIcon className={className} onClick={closeToast}/>
)


ReactDOM.render(
    <Router forceRefresh={true}>
      <LanguageProvider>
        <AuthProvider>
          <GeneralProvider>
            <ThemeProvider>
              <ToastContainer
                className={'toasts-container'}
                closeButton={
                  <CloseButton className={'notification-close-button'}/>
                }
                limit={5}
                closeOnClick={false}
                progressClassName={'notification-progress'}
              />
              <Layout/>
            </ThemeProvider>
          </GeneralProvider>
        </AuthProvider>
      </LanguageProvider>
    </Router>,
  document.getElementById('root')
)

serviceWorker.unregister()
