/**
 * General Services class
 */
import RestClient from '../Client/RestClient'
import CookiesStorageService from '../CookiesStorageService'
import { NamedRoutes } from '../../routes'


class ListingServices {

  static getListingData (entity, params, defaultErrorMessage = '', endpoint = '') {
    let config = { headers: { 'Authorization': `Bearer ${CookiesStorageService.getAccessToken()}` } }
    if (!!params && !!Object.keys(params).length) {
      config['params'] = params
    }
    let entityChildren = NamedRoutes.entities.filter((item) => !!item.isParent).map(item => item?.children).reduce((a, b) => a.concat(b), [])
    let entityObject = [...new Set([...NamedRoutes.entities, ...entityChildren])].filter((item) => item.name === entity)
    let endpointPath = !!entityObject?.length ? entityObject[0]?.endpoint || '' : ''
    return RestClient.getRequest(!!endpoint ? endpoint : endpointPath, config, defaultErrorMessage)
  }

  static getSingleEntity (entity, id, defaultErrorMessage = '') {
    let config = { headers: { 'Authorization': `Bearer ${CookiesStorageService.getAccessToken()}` } }
    let entityChildren = NamedRoutes.entities.filter((item) => !!item.isParent).map(item => item?.children).reduce((a, b) => a.concat(b), [])
    let entityObject = [...new Set([...NamedRoutes.entities, ...entityChildren])].filter((item) => item.name === entity)
    return RestClient.getRequest(!!entityObject?.length ? entityObject[0]?.endpoint + `/${id}` || '' : '', config, defaultErrorMessage)
  }

  static getListingExportData (entity, params, defaultErrorMessage = '') {
    let config = {
      headers: {
        'Authorization': `Bearer ${CookiesStorageService.getAccessToken()}`,
        'Accept': 'text/csv'
      },
      responseType: 'blob'
    }
    if (!!params && !!Object.keys(params).length) {
      config['params'] = params
    }
    return RestClient.getRequest(`/export/${entity}`, config, defaultErrorMessage)
  }

  static handleDialogSubmit (entity, action, deleteLoading = null, FormikActions = null, data = {}, id = '', defaultErrorMessage = '', extraHeaders = '', attachmentType = false) {
    let config = { headers: { ...extraHeaders, 'Authorization': `Bearer ${CookiesStorageService.getAccessToken()}` } }
    let entityChildren = NamedRoutes.entities.filter((item) => !!item.isParent).map(item => item?.children).reduce((a, b) => a.concat(b), [])
    let entityObject = [...new Set([...NamedRoutes.entities, ...entityChildren])].filter((item) => item.name === entity)
    switch (action) {
      case 'create':
        return RestClient.postRequest(!!entityObject?.length ? entityObject[0]?.endpoint || '' : '', data, config, FormikActions.setSubmitting, null, FormikActions, defaultErrorMessage)
      case 'transfer':
        return RestClient.postRequest(!!entityObject?.length ? `${entityObject[0]?.endpoint || ''}/transfer` : '', data, config, FormikActions.setSubmitting, null, FormikActions, defaultErrorMessage)
      case 'edit':
        if (!!attachmentType) {
          return RestClient.postRequest(!!entityObject?.length ? entityObject[0]?.endpoint + `/${id}` || '' : '', data, config, FormikActions.setSubmitting, null, FormikActions, defaultErrorMessage)
        } else {
          return RestClient.putRequest(!!entityObject?.length ? entityObject[0]?.endpoint + `/${id}` || '' : '', data, config, FormikActions.setSubmitting, null, FormikActions, defaultErrorMessage)
        }
      case 'delete':
        return RestClient.deleteRequest(!!entityObject?.length ? entityObject[0]?.endpoint + `/${id}` || '' : '', config, deleteLoading, null, null, defaultErrorMessage)
      default:
        break
    }
  }
}

export default ListingServices
