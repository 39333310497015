import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Button } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'

const FormConfirmation = ({ name, onSubmit, loading, entityName, cancelDialog }) => {
  const classes = useStyles()
  return (
    <Box p={4} className={classes.DeleteWrapper}>
      {!!loading && <FormLoader loading={true}/>}

      <WarningIcon className={classes.DeleteWarningIcon}/>
      <p><FormattedMessage id={`ConfirmEntity.${entityName}.ConfirmMessage`}
                           values={{ name: name || '' }}/></p>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={onSubmit}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.confirm`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
    </Box>
  )
}

export default FormConfirmation
