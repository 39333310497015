import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  TextField,
  FormControl,
  InputLabel, IconButton, InputAdornment
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const FormPassWordField = ({ formActions, classes, name, labelKey, required }) => {
  const intl = useIntl()

  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)

  return (
    <div className={classes.FormItem}>
      <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
      <span className={'required'}>*</span>}:</InputLabel>
      <FormControl variant="outlined">
        <TextField
          required={required}
          fullWidth
          margin="dense"
          type={showPassword ? 'text' : 'password'}
          name={name}
          id={name}
          variant="outlined"
          placeholder={intl.formatMessage({ id: labelKey })}
          onChange={formActions.handleChange}
          onBlur={formActions.handleBlur}
          value={formActions.values[name]}
          helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
          error={formActions.errors[name] && formActions.touched[name] !== undefined}
          InputProps={{ // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={<FormattedMessage id='Fields.Labels.passwordVisibility'/>}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleClickShowPassword}
                >
                  {showPassword ? <Visibility/> : <VisibilityOff/>}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off'
            }
          }}
        />
      </FormControl>
    </div>
  )
}

export default FormPassWordField
