import React from 'react'
import { Grid } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { AuthConsumer } from '../../context/AuthContext'
import UserMenu from '../UserMenu'
import { useStyles } from './Styles'
import Logo from '../Logo'
import { FullBodyLoader } from '../Loader/FullBodyLoader'
import { LanguageConsumer } from '../../context/LanguageContext'
import LanguageSwitcher from '../LanguageSwitcher'

const DesktopView = () => {
  const classes = useStyles()

  return (
    <Grid item className={classes.headerDesktop}>
      <Logo/>
      <span className={classes.headerTitle}><FormattedMessage id='Header.title.dashboard'/></span>
      <div className={classes.userMenuWrapper}>
        <LanguageSwitcher/>
        <AuthConsumer>
          {({ handleLogOut, isAuth, userData, logoutLoader }) => (
            <LanguageConsumer>
              {({ locale }) => (
                <>
                  <UserMenu handleLogOut={handleLogOut} isAuth={isAuth} userData={userData} locale={locale}/>
                  {!!logoutLoader && <FullBodyLoader loading={true}/>}
                </>
              )}
            </LanguageConsumer>
          )}
        </AuthConsumer>
      </div>
    </Grid>
  )
}

export default DesktopView
