import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewVehicleFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import arSA from 'date-fns/locale/ar-SA'
import enUS from 'date-fns/locale/en-US'
import { LanguageConsumer } from '../../../context/LanguageContext'
import FormDateField from './Fields/FormDateField'
import moment from 'moment'

const VehiclesForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      brand_id: currentData?.brand?.id || '',
      model_year: currentData?.model_year || new Date().getFullYear(),
      plate_number: currentData?.plate_number || '',
      motor_number: currentData?.motor_number || '',
      chassis_number: currentData?.chassis_number || '',
      customer_id: currentData?.customer?.id || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewVehicleFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })
  const [modelYear, setModelYear] = React.useState(currentData?.model_year ? new Date(`01-01-${currentData?.model_year}`) : new Date())

  const handleYearDateChange = (date) => {
    setModelYear(date)
    formActions.setFieldValue('model_year', moment(date).format('YYYY'))
  }


  return (
    <LanguageConsumer>
      {({ locale }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale === 'ar' ? arSA : enUS}>
          <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

            <ListingProvider entityName={'vehicle-brands'}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'brand_id'}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       searchable={'name'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.brand'} required/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <FormDateField formActions={formActions} classes={classes} name={'model_year'}
                           labelKey={'Fields.Labels.model_year'} format={'yyyy'}
                           atts={{ views: ['year'], showTodayButton: false }}
                           required value={modelYear} handleChange={handleYearDateChange}/>

            <FormTextField formActions={formActions} classes={classes} name={'plate_number'}
                           labelKey={'Fields.Labels.plate_number'}
                           required/>
            <FormTextField formActions={formActions} classes={classes} name={'motor_number'}
                           labelKey={'Fields.Labels.motor_number'}
                           required/>
            <FormTextField formActions={formActions} classes={classes} name={'chassis_number'}
                           labelKey={'Fields.Labels.chassis_number'}
                           required/>

            <ListingProvider entityName={'customers'}>
              <ListingConsumer>
                {({ listingData, listingLoading, handleListingFilterSubmit }) => (
                  <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_id'}
                                       list={listingData} loading={listingLoading} entityKey={'id'}
                                       searchable={'name'} loadEntities={handleListingFilterSubmit}
                                       labelKey={'Fields.Labels.customer'} required addMore/>
                )}
              </ListingConsumer>
            </ListingProvider>

            <div className={classes.actionButtonsWrapper}>
              <Button type="submit" variant='contained' color='primary'
                      className={classes.addRecordButton} id='addDialogRecordButton'
                      onClick={formActions.handleSubmit}
                      disabled={formActions.isSubmitting || !formActions.isValid}
              >
                <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
              </Button>
              <Button variant='outlined' color='primary'
                      onClick={cancelDialog}>
                <FormattedMessage id='AddNewDialog.Cancel'/>
              </Button>
            </div>
            {formActions.isSubmitting && <FormLoader loading={true}/>}
          </form>
        </MuiPickersUtilsProvider>
      )}
    </LanguageConsumer>
  )
}

export default VehiclesForm
