import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewCalllogsFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormEntityLoadField from './Fields/FormEntityLoadField'
import FormSelectField from './Fields/FormSelectField'

const CalllogsForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const [contactFilter, setContactFilter] = React.useState({})
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      title: currentData?.title || '',
      customer_id: currentData?.customer?.id || '',
      contact_id: currentData?.contact?.id || '',
      type: currentData?.type || '',
      description: currentData?.description || ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewCalllogsFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  const handleCustomerContactFilter = (value) => {
    setContactFilter({ 'customer_id': value })
  }

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'title'} labelKey={'Fields.Labels.title'}
                     required/>

      <FormSelectField formActions={formActions} classes={classes} name={'type'} labelKey={'Fields.Labels.type'}
                       required type={'calllogsType'}/>

      <ListingProvider entityName={'customers'}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'customer_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 setFilter={handleCustomerContactFilter}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.customer'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <ListingProvider entityName={'contacts'} filter={contactFilter}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'contact_id'}
                                 list={listingData} loading={listingLoading} entityKey={'id'}
                                 disabled={!formActions.values.customer_id}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.customer_contact'} required addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>

      <FormTextField formActions={formActions} classes={classes} name={'description'}
                     labelKey={'Fields.Labels.description'} required row lines={3}/>

      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default CalllogsForm
