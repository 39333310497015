import React from 'react'
import { useIntl } from 'react-intl'
import EntityPageContent from '../../components/EntityPageContent'
import { SEO } from '../../components/Seo/seo'
import { ListingProvider } from '../../context/ListingContext'
import AccountLayout from '../../components/AccountLayout'
import { AuthConsumer } from '../../context/AuthContext'
import { FullBodyLoader } from '../../components/Loader/FullBodyLoader'
import { NamedRoutes } from '../../routes'
import NotFound from '../../components/NotFound'
import { LanguageConsumer } from '../../context/LanguageContext'

const EntityListingPage = ({ entityName }) => {
  let entityChildren = NamedRoutes.entities.filter((item) => !!item.isParent).map(item => item?.children).reduce((a, b) => a.concat(b), [])
  let entities = [...new Set([...NamedRoutes.entities, ...entityChildren])]
  const intl = useIntl()
  const [render, setRender] = React.useState(true)
  const [entityObject, setEntityObject] = React.useState([])
  React.useEffect(() => {
      if (!!entityName) {
        setEntityObject(entities.filter((item) => item.name === entityName))
        setRender(false)
        setTimeout(() => setRender(true), 10)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [entityName])
  return (
    <>
      {!!entityName ?
        <>
          <SEO title={intl.formatMessage({ id: 'Page.title.' + entityName })}
               pathname={window.location.origin + entityObject[0]?.path}
               titleTemplate={intl.formatMessage({ id: 'QHub' })}
               description={intl.formatMessage({ id: 'Page.title.' + entityName })}
          />
          <AccountLayout content={
            <AuthConsumer>
              {({ isAuth }) => (
                !!isAuth ?
                  !!render && <LanguageConsumer>
                    {({ locale }) => (
                      <ListingProvider entityName={entityName} locale={locale}>
                        <EntityPageContent/>
                      </ListingProvider>
                    )}
                  </LanguageConsumer>
                  : <FullBodyLoader loading={true}/>
              )}
            </AuthConsumer>}/>
        </> :
        <NotFound/>
      }
    </>
  )
}

export default EntityListingPage
