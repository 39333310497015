import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(0.875),
    paddingBottom: theme.spacing(0.875),
    transition: 'all 0.5s ease-in-out',
    '.shrink &': {},
    [theme.breakpoints.up('lg')]: {
      // paddingRight: theme.spacing(8),
      '&.inner': {
        paddingRight: theme.spacing(0)
      }
    },
    '& a': {
      display: 'flex',
      flex: '0 0 100'
    }
  },
  logoImage: {
    width: 290,
    objectFit: 'contain',
    transition: 'all 0.5s ease-in-out',
    // '.shrink &': {
    //   width: 200
    // }
  },
}))
