import React from 'react'
import { Button, Collapse } from '@material-ui/core'
import { useStyles } from './Styles'
import { FormattedMessage } from 'react-intl'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const CollapseList = ({ list, render }) => {
  let classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  return (
    !!list && !!list.length &&
    (list.length > 2 ?
      <>
        <ul className={classes.CollapseList}>
          {list.slice(0, 2).map((listItem, idx) => (
            <li key={idx}>{render(listItem)}</li>
          ))}
        </ul>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <ul className={classes.CollapseList}>
            {list.slice(2, list.length).map((listItem, idx) => (
              <li key={idx + 2}>{render(listItem)}</li>
            ))}
          </ul>
        </Collapse>
        <div className={classes.CollapseBtnWrapper}>
          <Button className={classes.CollapseBtn} color="primary" onClick={() => setExpanded(!expanded)}>
            <FormattedMessage
              id={!!expanded ? 'Roles.List.Table.Permissions.Less' : 'Roles.List.Table.Permissions.More'}/>
            {!!!!expanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </Button>
        </div>
      </>
      :
      <ul className={classes.CollapseList}>
        {list.map((listItem, idx) => (
          <li key={idx}>{render(listItem)}</li>
        ))}
      </ul>)
  )
}
export default CollapseList