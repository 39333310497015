import React from 'react'
import { useIntl } from 'react-intl'
import { AppBar, Box, Tabs, Tab } from '@material-ui/core'
import { useStyles } from '../../../utils/FormStyles'
import { LanguageConsumer } from '../../../context/LanguageContext'
import { ListingProvider } from '../../../context/ListingContext'
import EntityPageContent from '../index'


function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-customer-tabpanel-${index}`}
      aria-labelledby={`scrollable-customer-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>{children}</Box>
      )}
    </div>
  )
}

function a11yProps (index) {
  return {
    id: `scrollable-customer-tab-${index}`,
    'aria-controls': `scrollable-customer-tabpanel-${index}`
  }
}


const CustomerTabs = ({ entities, title, filter }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div className={classes.tabsRoot}>
      <AppBar position="static" color="primary">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable customer tabs">
          {entities?.map((entityName, index) => <Tab key={index} {...a11yProps(index)}
                                                     label={intl.formatMessage({ id: 'Page.title.' + entityName })}/>)}
        </Tabs>
      </AppBar>
      <LanguageConsumer>
        {({ locale }) => (
          entities?.map((entityName, index) =>
            <TabPanel value={tabValue} index={index} key={index}>
              <ListingProvider entityName={entityName} locale={locale} filter={filter[entityName] || null}>
                <EntityPageContent inner/>
              </ListingProvider>
            </TabPanel>
          )
        )}
      </LanguageConsumer>

    </div>
  )
}

export default CustomerTabs
