import React from 'react'
import { FormattedMessage } from 'react-intl'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Avatar, withStyles, Menu, IconButton } from '@material-ui/core'
import { useStyles } from './Styles'

const UserMenu = ({ handleLogOut, userData, locale }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const menuId = 'header-account-menu'

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const StyledMenu = withStyles((theme) => ({
    paper: {
      borderRadius: theme.spacing(0),
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0),
      textAlign: 'center',
      '& .MuiMenu-list': {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  }))((props) => (
    <Menu
      elevation={2}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: locale === 'ar' ? 'left' : 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      {...props}
    />
  ))
  return (
    <>
      <IconButton
        edge="end"
        aria-label={<FormattedMessage id='UserMenu.label'/>}
        aria-controls={menuId}
        aria-haspopup="true"
        className={classes.userMenuButton}
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <Avatar alt={userData?.name?.toUpperCase()} src="/broken-image.jpg"
                className={classes.userAvatar}/>
        <span>
          {(!!userData && !!Object.keys(userData).length) ? (
            `${userData?.name || userData.email}`
          ) : <FormattedMessage id='UserMenu.username'/>}</span>
        <ArrowDropDownIcon color="inherit"/>
      </IconButton>
      <StyledMenu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {/*<Link to={NamedRoutes.auth.changePassword} className={classes.menuUserName} onClick={handleMenuClose}>*/}
        {/*  <FormattedMessage id='UserMenu.link.changePassword'/>*/}
        {/*</Link>*/}
        <button onClick={() => {
          handleMenuClose()
          handleLogOut()
        }} className={classes.logoutButton}>
          <FormattedMessage id='UserMenu.button.logout'/>
        </button>
      </StyledMenu>
    </>
  )
}

export default UserMenu
