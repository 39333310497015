import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, InputLabel, InputAdornment } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import EventIcon from '@material-ui/icons/Event'

const FormDateTimeField = ({ formActions, classes, name, labelKey, required, isSearch, value, handleChange, format, disablePast, atts = {} }) => {
  const intl = useIntl()
  return (
    !!isSearch ?
      <FormControl className={classes.formControl} variant="outlined">
        <DateTimePicker
          autoOk
          fullWidth
          value={value}
          id={name}
          name={name}
          disablePast={!!disablePast}
          margin="dense"
          variant="outlined"
          inputVariant="outlined"
          format={format ?? 'dd-MM-yyyy hh:mm a'}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <EventIcon/>
              </InputAdornment>
          }}
          onChange={handleChange}
          label={intl.formatMessage({ id: labelKey })}
          onBlur={formActions.handleBlur}
          helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
          error={formActions.errors[name] && formActions.touched[name] !== undefined}
          showTodayButton
          {...atts}
        />
      </FormControl>
      :
      <div className={classes.FormItem}>
        <InputLabel htmlFor={name}><FormattedMessage id={labelKey}/>{!!required &&
        <span className={'required'}>*</span>}:</InputLabel>
        <FormControl variant="outlined">
          <DateTimePicker
            autoOk
            fullWidth
            required={required}
            value={value}
            id={name}
            name={name}
            disablePast={!!disablePast}
            margin="dense"
            variant="outlined"
            inputVariant="outlined"
            format={format ?? 'dd-MM-yyyy hh:mm a'}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <EventIcon/>
                </InputAdornment>
            }}
            onChange={handleChange}
            onBlur={formActions.handleBlur}
            helperText={formActions.errors[name] && formActions.touched[name] && formActions.errors[name]}
            error={formActions.errors[name] && formActions.touched[name] !== undefined}
            showTodayButton
            {...atts}
          />
          {/*{formActions.errors[name] && formActions.touched[name] && (*/}
          {/*  <FormHelperText error={true}><FormattedMessage id='validationSchema.date'/></FormHelperText>*/}
          {/*)}*/}
        </FormControl>
      </div>
  )
}

export default FormDateTimeField
