import React from 'react'
import NotFound from '../../components/NotFound'

const NoMatch = () => {
  return (
    <>
      <NotFound/>
    </>
  )
}

export default NoMatch
