import React from 'react'
import OrderDetailsPDFContent from './details'
import { useStyles } from './Styles'

const OrderPDFContent = ({ content, entityName }) => {
  const classes = useStyles()
  return (
    <div className={classes.pdfContentWrapper}>
      {!!Object.keys(content || {}).length &&
      <OrderDetailsPDFContent
        id={'contentPdfContent'}
        content={content}
        entityName={entityName}/>
      }
    </div>
  )
}

export default OrderPDFContent
