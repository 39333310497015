/**
 * General Styles For The Layout
 * @type {function(*): {}}
 */
import { ArabicFont, EnglishFont } from '../../utils/Fonts'
import { fade } from '@material-ui/core'

export const useStyles = ((theme) => ({
  '@global': {
    html: { height: '100%' },
    body: {
      height: '100%',
      backgroundColor: theme.palette.colors.body
    },
    '#root': {
      height: '100%',
      whiteSpace: 'pre-line'
    },
    'a': {
      transition: 'all 0.3s ease-in-out',
      color: theme.palette.primary.main,
      '&:hover, &:focus': {
        color: theme.palette.secondary.main
      }
    },
    'button': {
      fontFamily: theme.direction === 'ltr' ? EnglishFont : ArabicFont,
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      outline: 'none !important'
    },
    'img': {
      maxWidth: '100%',
      height: 'auto',
      display: 'inline-block'
    },
    'h1, h2, h3, h4, h5, h6': {
      fontFamily: theme.direction === 'ltr' ? EnglishFont : ArabicFont,
      fontWeight: 'bold'
    },
    '.MuiDrawer-paper': {
      backgroundColor: theme.palette.common.black
    },
    '.MuiBackdrop-root': {
      backgroundColor: fade(theme.palette.common.black, 0.75),
      backdropFilter: 'blur(2px)'
    },
    '::selection': {
      color: theme.palette.common.white,
      background: theme.palette.primary.main
    },
    '.listing-wrapper': {
      position: 'relative'
    },
    '.grecaptcha-badge': {
      visibility: 'hidden'
    },
    '.toasts-container': {
      width: 400,
      marginTop: theme.spacing(6),
      right: 0
    },
    '.rltv': {
      position: 'relative'
    },
    '.required': {
      color: theme.palette.error.main,
      paddingRight: theme.spacing(0.25),
      paddingLeft: theme.spacing(0.25)
    },
    '.notification-close-button': {
      position: 'absolute',
      right: theme.spacing(2.5)
    },
    '.notification-progress-wrapper': {
      visibility: 'hidden'
    },
    '.notification-wrapper': {
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      boxShadow: 'none',
      overflow: 'visible'
    },
    '.notification-component': {
      paddingRight: theme.spacing(4)
    },
    '.Toastify__toast': {
      direction: 'ltr',
      marginBottom: theme.spacing(0)
    },
    '.Toastify__toast-body': {
      flex: ' 1 1 auto',
      padding: theme.spacing(0)
    },
    '.MuiTooltip-tooltip': {
      fontSize: theme.typography.pxToRem(16)
    }
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  content: {
    flex: '1 1 auto',
    paddingTop: theme.spacing(8)
  }
}))
