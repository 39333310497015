/** It checks if the user is authenticated, if they are,
 it renders the "component" prop. If not, it redirects
 the user to /login.
 **/
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Configuration from '../Services/Api/Configuration'
import { NamedRoutes } from '../routes'
import NoMatch from '../pages/NoMatch'

const PrivateRoute = ({ component: Component, entityName, userPermissions, ...rest }) => {
  let entity = !!entityName ? { entityName: entityName } : {}
  const [show, setShow] = React.useState(true)
  React.useEffect(() => {
      if (!!userPermissions?.length) {
        let entityPermission = userPermissions.filter(permission => [`create ${entityName}`, `view ${entityName}`, `delete ${entityName}`, `update ${entityName}`].includes(permission))
        setShow(prevState => !!entityPermission?.length || prevState)
      }
    },// eslint-disable-next-line react-hooks/exhaustive-deps
    [userPermissions])
  return (
    <Route {...rest} render={(props) => (
      Configuration.isAuth() ? (!!entityName ? (!!show ? <Component {...entity} {...props} /> : <NoMatch/>) :
        <Component {...entity} {...props} />) :
        <Redirect to={{
          pathname: NamedRoutes.auth.login,
          state: { from: props.location }
        }}/>
    )}/>
  )
}

export default PrivateRoute
