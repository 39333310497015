import React from 'react'
import * as yup from 'yup'
import { FormattedMessage } from 'react-intl'
import { parse, isDate } from 'date-fns'

function parseDateString (value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd-MM-yyyy HH:mm a', new Date())

  return parsedDate
}

export const LoginFormValidation = yup.object().shape({
  email: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    /* eslint-disable-next-line */
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      { message: <FormattedMessage id='validationSchema.validEmail'/> }),
  password: yup.string().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewUserFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  gender: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  roles: yup.array().nullable().min(1, <FormattedMessage id='validationSchema.required'/>),
  email: yup.string().required(<FormattedMessage id='validationSchema.required'/>)
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    /* eslint-disable-next-line */
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      { message: <FormattedMessage id='validationSchema.validEmail'/> }),
  password: yup.string().when('id', (val) => {
    return !!val ? yup.lazy(value => !value ? yup.string() : yup.string().required(<FormattedMessage
        id='validationSchema.required'/>)
        .min(6, <FormattedMessage id='validationSchema.passwordMinimum'/>)
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{6,}$/,
          { message: <FormattedMessage id='validationSchema.passwordSchema'/> }))
      : yup.string().required(<FormattedMessage
        id='validationSchema.required'/>)
        .min(6, <FormattedMessage id='validationSchema.passwordMinimum'/>)
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=.*[A-Z])(?=.*[a-z])[A-Za-z\d@$!%*#?&]{6,}$/,
          { message: <FormattedMessage id='validationSchema.passwordSchema'/> })
  }),
  mobile: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .min(13, <FormattedMessage id='validationSchema.required'/>)
    .max(13, <FormattedMessage id='validationSchema.required'/>)

})

export const AddNewContactFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  customer_id: yup.string(),
  nid_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.socialNumberOnlyNumbers'/> })
    .matches(/(2|3)[0-9][0-9][0-1][0-9][0-3][0-9](01|02|03|04|05|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/, {
      message: <FormattedMessage id='validationSchema.socialNumberInvalid'/>
    })
    .min(14, <FormattedMessage id='validationSchema.socialNumberLengthMin'/>)
    .max(14, <FormattedMessage id='validationSchema.socialNumberLengthMax'/>),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  email: yup.string().required(<FormattedMessage id='validationSchema.required'/>)
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    /* eslint-disable-next-line */
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      { message: <FormattedMessage id='validationSchema.validEmail'/> }),
  mobile: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .min(13, <FormattedMessage id='validationSchema.required'/>)
    .max(13, <FormattedMessage id='validationSchema.required'/>)

})
export const AddNewBrandFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewOperatorFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  code: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const AddNewTicketUserCaseFormValidation = (additionalFields) => {
  let validations = {}
  if (!!additionalFields?.length) {
    additionalFields.forEach(field => {
      validations[field?.name] = (field?.type === 'multi_select') ? yup.array().nullable().min(1, <FormattedMessage
        id='validationSchema.required'/>) : yup.string().trim().required(<FormattedMessage
        id='validationSchema.required'/>)
    })
  }
  return yup.object().shape({
    id: yup.string(),
    status: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
    ...validations
  })
}

export const AddNewCustomerTypeFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewTicketCategoryFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewTicketCaseFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  sla: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  ticket_type_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  team_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  form_template_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  sort_order: yup.number().min(1, <FormattedMessage id='validationSchema.required'/>).required(<FormattedMessage
    id='validationSchema.required'/>),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewVehicleBrandFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewMaintenanceProviderFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewTicketTypeFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  ticket_category_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewVehicleFormValidation = yup.object().shape({
  id: yup.string(),
  brand_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  model_year: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .min(4, <FormattedMessage id='validationSchema.invalidYear'/>)
    .max(4, <FormattedMessage id='validationSchema.invalidYear'/>),
  plate_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  motor_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  chassis_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  customer_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const AddNewCalllogsFormValidation = yup.object().shape({
  id: yup.string(),
  title: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  customer_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  contact_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const AddNewTicketFormValidation = yup.object().shape({
  id: yup.string(),
  title: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  body: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  ticket_category_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  ticket_type_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  customer_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  customer_contact_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  customer_vehicle_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  created_by: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const AddNewRoleFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  permissions: yup.array().nullable().min(1, <FormattedMessage id='validationSchema.required'/>)
})

export const AddNewInventoryFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  is_backorder: yup.boolean()
})

export const AddNewPermissionFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const AddNewTeamFormValidation = yup.object().shape({
  id: yup.string(),
  title: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  supervisor_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  leader_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})


export const AddNewServiceRequestScheduleFormValidation = yup.object().shape({
  id: yup.string(),
  service_request_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  start_date: yup.date().transform(parseDateString).required(<FormattedMessage id='validationSchema.required'/>),
  end_date: yup.date().transform(parseDateString).required(<FormattedMessage id='validationSchema.required'/>),

  address: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  address_description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  is_needs_visit: yup.boolean(),
  vehicle_count: yup.number().min(1, <FormattedMessage id='validationSchema.required'/>).required(<FormattedMessage
    id='validationSchema.required'/>),
  note: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const AddNewInstallationOrderFormValidation = yup.object().shape({
  id: yup.string(),
  service_request_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  service_request_schedule_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  technician_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  status: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
  // products: yup.array().nullable()
})
export const AddNewMaintenanceOrderStatusFormValidation = yup.object().shape({
  id: yup.string(),
  maintenance_order_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  status: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  reason: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})

export const AddNewServiceRequestFormValidation = yup.object().shape({
  id: yup.string(),
  customer_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  customer_contact_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  vehicle_count: yup.number().min(1, <FormattedMessage id='validationSchema.required'/>).required(<FormattedMessage
    id='validationSchema.required'/>)
})

export const AddNewImeiFormValidation = yup.object().shape({
  id: yup.string(),
  product_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  inventory_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  imei: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.OnlyNumbers'/> })
})

export const AddNewProductQuantityFormValidation = yup.object().shape({
  inventory: yup.object().shape({
    id: yup.string(),
    name: yup.string()
  }).nullable().required(<FormattedMessage id='validationSchema.required'/>),
  quantity: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.OnlyNumbers'/> })
})

export const AddNewInstallationOrderProductsFormValidation = yup.object().shape({
  installation_order_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  product: yup.object().shape({
    id: yup.string()
  }).nullable().test('product', null, (obj) => {
    if (!!obj?.id) {
      return true // everything is fine
    }
    return new yup.ValidationError(<FormattedMessage
      id='validationSchema.required'/>, null, 'product')
  }).required(<FormattedMessage id='validationSchema.required'/>),

  // imei: yup.object().when('product', (product) => {
  //   return product?.type === 'simple' ? yup.object() :
  //     yup.object().shape({
  //       id: yup.string()
  //     }).nullable().test('imei', null, (obj) => {
  //       if (!!obj?.id) {
  //         return true // everything is fine
  //       }
  //       return new yup.ValidationError(<FormattedMessage
  //         id='validationSchema.required'/>, null, 'imei')
  //     }).required(<FormattedMessage id='validationSchema.required'/>)
  // }),
  imei: yup.object().shape({
    id: yup.string()
  }).nullable().test('imei', null, (obj) => {
    if (!!obj?.id) {
      return true // everything is fine
    }
    return new yup.ValidationError(<FormattedMessage
      id='validationSchema.required'/>, null, 'imei')
  }).required(<FormattedMessage id='validationSchema.required'/>),

  vehicle: yup.object().shape({
    id: yup.string()
  }).nullable().test('vehicle', null, (obj) => {
    if (!!obj?.id) {
      return true // everything is fine
    }
    return new yup.ValidationError(<FormattedMessage
      id='validationSchema.required'/>, null, 'vehicle')
  }).required(<FormattedMessage id='validationSchema.required'/>),
  operator_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  sensor_ids: yup.array().nullable().min(1, <FormattedMessage id='validationSchema.required'/>),
  sim_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  sim_serial: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewMaintenanceOrderProductsFormValidation = yup.object().shape({
  vehicle_product: yup.object().shape({
    id: yup.string()
  }).nullable().required(<FormattedMessage id='validationSchema.required'/>)
})
export const AddNewMaintenanceOrderProductStatusFormValidation = yup.object().shape({
  id: yup.string(),
  maintenance_order_product_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  status: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  reason: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  solved_by: yup.string().when('status', (status) => {
    return !!status && status === 'solved' ? yup.string().trim().required(<FormattedMessage
      id='validationSchema.required'/>) : yup.string()
  }),

  replaced_product: yup.string().when(['status', 'solved_by'], (status, solved_by) => {
    return !!status && status === 'solved' && !!solved_by && solved_by === 'device_replacement' ? yup.object().nullable().required(
      <FormattedMessage id='validationSchema.required'/>) : yup.string()
  }),
  replaced_imei: yup.string().when(['status', 'solved_by', 'replaced_product'], (status, solved_by, replaced_product) => {
    return !!status && status === 'solved' && !!solved_by && solved_by === 'device_replacement' && !!Object.keys(replaced_product || {}).length && replaced_product?.type === 'configurable'
      ? yup.object().nullable(false).required(<FormattedMessage id='validationSchema.required'/>) : yup.string()
  }),

  replace_reason: yup.string().when(['status', 'solved_by'], (status, solved_by) => {
    return !!status && status === 'solved' && !!solved_by && solved_by === 'device_replacement' ? yup.string().trim().required(
      <FormattedMessage id='validationSchema.required'/>) : yup.string()
  })
})

export const AddNewCustomerFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  lead: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  organization_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  commercial_reg_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.OnlyNumbers'/> }),
  tax_card_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  sales_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  address: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  phone_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .min(13, <FormattedMessage id='validationSchema.required'/>)
    .max(13, <FormattedMessage id='validationSchema.required'/>),
  verified: yup.boolean()
})

export const AddNewVehicleProductFormValidation = yup.object().shape({
  id: yup.string(),
  product_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  product_imei_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  vehicle_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  // installation_order_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  operator_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  sensor_ids: yup.array().nullable().min(1, <FormattedMessage id='validationSchema.required'/>),
  sim_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  // sim_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
  //   .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.OnlyNumbers'/> }),
  sim_serial: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  // sim_serial: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
  //   .matches(/^[0-9]+$/, { message: <FormattedMessage id='validationSchema.OnlyNumbers'/> }),
  status: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  installation_date: yup.date().transform(parseDateString).required(<FormattedMessage id='validationSchema.required'/>),
  needs_configuration: yup.boolean()
})

export const AddNewVehicleProductTransferFormValidation = yup.object().shape({
  vehicle_product_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  vehicle_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  sensor_ids: yup.array().nullable().min(1, <FormattedMessage id='validationSchema.required'/>),
})

export const AddNewProductFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  type: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  serial_number: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  brand_id: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  cost: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]*\.?[0-9]*$/, { message: <FormattedMessage id='validationSchema.OnlyNumbers'/> }),

  price: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>)
    .matches(/^[0-9]*\.?[0-9]*$/, { message: <FormattedMessage id='validationSchema.OnlyNumbers'/> }),
  description: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  quantities: yup.array(),
  imeis: yup.array()
})

export const ForgotPasswordValidation = yup.object().shape({
  email: yup.string().required(<FormattedMessage id='validationSchema.required'/>)
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    /* eslint-disable-next-line */
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      { message: <FormattedMessage id='validationSchema.validEmail'/> }),
  reCaptcha: yup.string().trim().required(<FormattedMessage id='validationSchema.reCaptcha'/>)
})

export const ChangePasswordFormValidation = yup.object().shape({
  password: yup.string().min(8, <FormattedMessage id='validationSchema.passwordMinimum'/>)
    .required(<FormattedMessage id='validationSchema.required'/>),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null],
    <FormattedMessage id='validationSchema.passwordMatch'/>)
    .required(<FormattedMessage id='validationSchema.required'/>)
})

export const ChangePasswordWithEmailFormValidation = yup.object().shape({
  email: yup.string().required(<FormattedMessage id='validationSchema.required'/>)
    .email(<FormattedMessage id='validationSchema.validEmail'/>)
    /* eslint-disable-next-line */
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      { message: <FormattedMessage id='validationSchema.validEmail'/> }),
  password: yup.string().required(<FormattedMessage
    id='validationSchema.required'/>)
    .min(6, <FormattedMessage id='validationSchema.passwordMinimum'/>)
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      { message: <FormattedMessage id='validationSchema.passwordSchema'/> }),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null],
    <FormattedMessage id='validationSchema.passwordMatch'/>)
    .required(<FormattedMessage id='validationSchema.required'/>),
  reCaptcha: yup.string().trim().required(<FormattedMessage id='validationSchema.reCaptcha'/>)
})

export const AddNewFormTemplateFormValidation = yup.object().shape({
  id: yup.string(),
  name: yup.string().trim().required(<FormattedMessage id='validationSchema.required'/>),
  fields: yup.array().min(1, <FormattedMessage id='validationSchema.required'/>)
})



