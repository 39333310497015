import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { TextField, FormControl, InputLabel, Button } from '@material-ui/core'
import { LoginFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { Link } from 'react-router-dom'
import { NamedRoutes } from '../../../routes'
import { useStyles } from '../FormStyles'

const LoginForm = ({ onSubmit, getUserLoader }) => {
  const classes = useStyles()

  const formActions = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: LoginFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  return (
    <div className={classes.formCard}>
      <h1 className={classes.formCardTitle}><FormattedMessage id='loginForm.Title'/></h1>
      <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="userName" className={classes.formInputLabel}>
            <FormattedMessage id='Fields.Labels.email'/>
          </InputLabel>
          <TextField
            required
            fullWidth
            margin="dense"
            type="text"
            name='email'
            id='email'
            variant="outlined"
            onChange={formActions.handleChange}
            onBlur={formActions.handleBlur}
            value={formActions.values.email}
            helperText={formActions.errors.email && formActions.touched.email && formActions.errors.email}
            error={formActions.errors.email && formActions.touched.email !== undefined}
          />
        </FormControl>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="password" className={classes.formInputLabel}>
            <FormattedMessage id='Fields.Labels.password'/>
          </InputLabel>
          <TextField
            required
            fullWidth
            margin="dense"
            type="password"
            name='password'
            id='password'
            variant="outlined"
            onChange={formActions.handleChange}
            onBlur={formActions.handleBlur}
            value={formActions.values.password}
            helperText={formActions.errors.password && formActions.touched.password && formActions.errors.password}
            error={formActions.errors.password && formActions.touched.password !== undefined}
          />
        </FormControl>
        <div className={classes.actionButtonsWrapper}>
          <Button type="submit" className={classes.addRecordButton} id='addDialogRecordButton'
                  disabled={formActions.isSubmitting || !formActions.isValid}>
            <FormattedMessage id='LoginForm.submit'/>
          </Button>
          <Link to={NamedRoutes.auth.forgotPassword} className={classes.changePasswordLink}>
            <FormattedMessage id='LoginForm.changePassword'/>
          </Link>
        </div>
        {(formActions.isSubmitting || !!getUserLoader) && <FormLoader loading={true}/>}
      </form>
    </div>
  )
}

export default LoginForm
