import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './Styles'
import { Slide, DialogActions, Button } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const AddNewDialog = ({ DialogTitle, dialogAction, children, isDialogOpen, cancelDialog, hasCKEditor }) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      disableEnforceFocus={hasCKEditor || false}
      maxWidth={['delete', 'confirm'].includes(dialogAction) ? 'sm' : 'md'}
      onClose={cancelDialog}
      open={isDialogOpen}
      TransitionComponent={Transition}
      className={classes.DialogWrapper}>
      <MuiDialogTitle disableTypography>
        <Typography variant="h6" className={classes.dialogTitle}>{DialogTitle}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={cancelDialog}
                    id='closeDialogButton'>
          <CloseIcon/>
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent>
        {children}
      </MuiDialogContent>
      {(['show', 'status'].includes(dialogAction)) &&
      <DialogActions>
        <div className={classes.actionButtonsWrapper}>
          <Button variant='outlined' color='primary'
                  onClick={cancelDialog}>
            <FormattedMessage id='AddNewDialog.Cancel'/>
          </Button>
        </div>
      </DialogActions>
      }
    </Dialog>
  )
}

export default AddNewDialog
