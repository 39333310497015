import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  TableSortLabel,
  Tooltip, Button
} from '@material-ui/core'
import ActionsMenu from './ActionsMenu'
import { useStyles } from './Styles'
import { FormattedMessage, useIntl } from 'react-intl'
import { ListingConsumer } from '../../context/ListingContext'
import 'moment/locale/ar-ly'
import Moment from 'react-moment'
import { formatPhoneNumber } from '../../utils/helpers'
import { FormLoader } from '../Loader/FormLoader'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import VisibilityIcon from '@material-ui/icons/Visibility'
import HistoryIcon from '@material-ui/icons/History'
import { LanguageConsumer } from '../../context/LanguageContext'
import CollapseList from './CollapseList'
import Configuration from '../../Services/Api/Configuration'
import { useHistory } from 'react-router-dom'
import { AuthConsumer } from '../../context/AuthContext'
import TicketState from '../State'
import Countdown, { zeroPad } from 'react-countdown'
import moment from 'moment'

// Renderer callback with condition
const CountDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return '00:00:00'
  } else {
    // Render a countdown
    return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
  }
}

const ListingTable = ({
                        rows, columns, entityName, additionalClass, listingLoading,
                        entityActivateLoading, handleChangeListingSort, inner
                      }) => {
  const classes = useStyles()
  const intl = useIntl()
  let history = useHistory()
  const [pdfLoading, setPdfLoading] = React.useState(false)
  const [order, setOrder] = React.useState('desc')
  const [orderBy, setOrderBy] = React.useState('')

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    handleChangeListingSort(property, isAsc ? 'desc' : 'asc')
  }

  const handleLabelDisplayedRows = ({ from, to, count }) => (
    <FormattedMessage
      id={`TablePagination.LabelDisplayedRows.with${(count !== -1) ? 'Count' : 'NoCount'}`}
      values={{ from: from, to: to, count: count }}/>
  )

  const tooltipValue = (value, fields) => {
    let intersection = fields.filter(x => Object.keys(value || {}).includes(x))
    return !!intersection?.length ? (!!fields?.length && <Tooltip
      title={
        <React.Fragment>
          {fields.map((userProp, index) => (
            !!value[userProp] &&
            <div key={index} className={classes.userDataWrapper}>
              <label><FormattedMessage id={`Fields.Labels.${userProp}`}/>:</label>
              <span>{typeof value[userProp] === 'object'
                ? JSON.stringify(value[userProp], null, 2)
                : ['start_date', 'end_date', 'created_at', 'updated_at'].includes(userProp) ? <LanguageConsumer>
                  {({ locale }) => (
                    <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                            format="DD/MM/YYYY, h:mm A">{value}</Moment>
                  )}
                </LanguageConsumer> : value[userProp]}
              </span>
            </div>
          ))}
        </React.Fragment>
      }
      arrow
    >
      <span>{value?.name || ''}</span>
    </Tooltip>) : value?.name || ''
  }

  const getColumnValue = (key, value, selectedRow) => {
    if (!!key) {
      switch (key) {
        case 'email':
          return <a href={`mailto:${value}`} target={'_blank'} rel="noreferrer"
                    className={classes.mailWrapper}>{value}</a>
        case 'mobile':
        case 'phone_number':
          return <a href={`tel:${value}`} target={'_blank'} rel="noreferrer"
                    className={classes.phoneWrapper}>{formatPhoneNumber(value)}</a>
        case 'gender':
          return !!value && <FormattedMessage id={`gender.${value}`}/>
        case 'calllogsType':
          return !!value && <FormattedMessage id={`callLogTypes.${value}`}/>
        case 'vehicleProductStatus':
          return !!value && <FormattedMessage id={`vehicleProductStatus.${value}`}/>
        case 'orderStatus':
          return !!value && <FormattedMessage id={`status.${value}`}/>
        case 'is_backorder':
          return <FormattedMessage id={`is_backorder.${value}`}/>
        case 'supervisor':
        case 'leader':
        case 'sales':
        case 'created_by':
          return !!value && !!value?.name && tooltipValue(value, ['email', 'mobile', 'gender'])
        case 'customer':
          return !!value && !!value?.name && tooltipValue(value, ['tax_card_number', 'organization_id', 'commercial_reg_number', 'phone_number'])
        case 'product':
          return !!value && !!value?.name && tooltipValue(value, ['id', 'type'])

        case 'service_request':
          return !!value && !!value?.name && tooltipValue(value, ['id', 'vehicle_count'])
        case 'order_customer':
          return !!value && !!value?.name && tooltipValue(value, ['id', 'name', 'phone_number'])
        case 'order_customer_contact':
          return !!value && !!value?.name && tooltipValue(value, ['id', 'name', 'mobile'])
        case 'service_request_schedule':
          return !!value && !!value?.name && tooltipValue(value, ['id', 'address', 'start_date', 'end_date', 'vehicle_count'])
        case 'technician':
          return !!value && !!value?.name && tooltipValue(value, ['id', 'email', 'mobile', 'gender'])
        case 'status_track':
          return <ListingConsumer>
            {({ openDialog }) => (
              <Button size={'medium'} color="secondary" variant={'contained'} startIcon={<HistoryIcon/>}
                      className={classes.statusButton} aria-label="Status" onClick={(e) => {
                !!e?.preventDefault && e.preventDefault()
                !!e?.stopPropagation && e.stopPropagation()
                openDialog('status', selectedRow, selectedRow?.id)
              }}>
                <FormattedMessage id={`statusTrack.${value || 'pending'}`}/>
              </Button>
            )}
          </ListingConsumer>

        case 'ticket_type':
          if (!!value) {
            value['ticketCategoryName'] = value?.ticket_category?.name || ''
          }
          return !!value && !!value?.name && tooltipValue(value, ['id', 'description', 'ticketCategoryName'])

        case 'caseTeam':
          if (!!value) {
            value['name'] = value?.title || ''
            value['supervisorName'] = value?.supervisor?.name || ''
            value['leaderName'] = value?.leader?.name || ''
          }
          return !!value && !!value?.name && tooltipValue(value, ['id', 'supervisorName', 'leaderName'])

        case 'form_template':
          if (!!value) {
            value['fieldsCount'] = value?.fields?.length || 0
          }
          return !!value && !!value?.name && tooltipValue(value, ['id', 'fieldsCount'])

        case 'product_imei':
          if (!!value) {
            value['name'] = value?.imei || ''
            value['inventoryName'] = value?.inventory?.name || ''
            value['productName'] = value?.product?.name || ''
            value['productType'] = value?.product?.type || ''
          }
          return !!value && !!value?.name && tooltipValue(value, ['id', 'inventoryName', 'productName', 'productType'])

        case 'vehicle':
        case 'vehiclePlate':
          if (!!value) {
            value['brandName'] = value?.brand?.name || ''
          }
          if (key === 'vehiclePlate') {
            value['name'] = value?.plate_number || ''
          }
          return !!value && !!value?.name && tooltipValue(value, ['id', 'brandName', 'model_year', 'motor_number', 'plate_number'])


        case 'authorized_contact':
          if (!!value) {
            value['customerName'] = value?.customer?.name || ''
          }
          return !!value && !!value?.name && tooltipValue(value, ['email', 'mobile', 'nid_number', 'customerName', 'description'])

        case 'installation_order':
          if (!!value) {
            value['name'] = value?.id || ''
          }
          return !!value && !!value?.name && tooltipValue(value, ['service_request_id', 'service_request_schedule_id', 'status', 'technician_id'])

        case 'verified':
          return !!value && (value?.toString() === '1') ? <CheckCircleIcon color={'primary'}/> :
            <CancelIcon color={'error'}/>

        case 'ticketStatus':
          return !!value && <TicketState state={value} status/>

        case 'state':
          return !!value && <TicketState state={value}/>

        case 'customersType':
        case 'brand':
        case 'operator':
        case 'inventory':
        case 'ticket_category':
          return !!value && !!value?.name && <>{value?.name || ''}</>

        case 'contact':
        case 'customer_contact':
          return !!value && !!value?.name && <>{value?.name || ''}</>

        case 'remaining_sla':
          return !!value ? <Countdown
            date={Date.now() + moment.duration(value).asMilliseconds()}
            renderer={CountDownRenderer}/> : '00:00:00'

        case 'nid_photo':
          return !!value && <ListingConsumer>
            {({ openDialog }) => (
              <img alt={selectedRow?.name} className={classes.nidImage} src={Configuration.STORAGE_BASE_URL + value}
                   onClick={() => openDialog('showImage', selectedRow, selectedRow.id)}/>
            )}
          </ListingConsumer>


        case 'created_at':
        case 'updated_at':
        case 'published_at':
        case 'start_date':
        case 'end_date':
          return <LanguageConsumer>
            {({ locale }) => (
              <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                      format="DD MMMM YYYY, h:mm A">{value}</Moment>
            )}
          </LanguageConsumer>
        case 'open_date':
          return <LanguageConsumer>
            {({ locale }) => (
              <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                      format="DD-MM-YYYY hh:mm:ss A">{value}</Moment>
            )}
          </LanguageConsumer>

        case 'installation_date':
          return <LanguageConsumer>
            {({ locale }) => (
              <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                      format="Do MMMM YYYY">{value}</Moment>
            )}
          </LanguageConsumer>
        case 'roles':
        case 'operators':
          return !!value && !!value.length &&
            value.map((role, index) => (
              !!role?.name &&
              <Chip key={index} className={classes.TableChip} variant="outlined" color="primary"
                    label={role?.name || ''}/>
            ))
        case 'permissions':
          return !!value && !!value.length && <CollapseList list={value} render={(value) => value.name}/>

        case 'serviceRequestType':
          return !!value && <FormattedMessage id={`serviceRequestType.${value}`}/>
        case 'view':
          return !!value && <VisibilityIcon onClick={() => history.push(value)}/>
        case 'fields':
          return !!value && !!value?.length && <CollapseList list={value}
                                                             render={(value) => tooltipValue(value, ['name', 'type', 'title', 'required', 'additional', 'placeholder'])}/>
        case 'sensors':
          return !!value && !!value?.length && <CollapseList list={value}
                                                             render={(value) => tooltipValue(value, ['name', 'serial_number', 'cost', 'price', 'created_at', 'updated_at'])}/>
        default:
          return value
      }
    } else {
      return ''
    }
  }

  return (
    <Paper className={`${classes.root} ${additionalClass}`} elevation={0}>
      <TableContainer className={classes.container}>
        {(!!entityActivateLoading || !!pdfLoading) && <FormLoader loading={true}/>}
        <Table stickyHeader aria-label="sticky table">

          <TableHead>
            <TableRow>
              {!!columns && !!columns.length && columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.numeric ? 'right' : 'left'}
                  sortDirection={(!!column.sort && orderBy === (column.sortId || column.id)) ? order : false}
                >
                  {!!column.sort ?
                    <TableSortLabel
                      active={orderBy === (column.sortId || column.id)}
                      direction={orderBy === (column.sortId || column.id) ? order : 'asc'}
                      onClick={createSortHandler((column.sortId || column.id))}
                    >
                      {!!column.label && <FormattedMessage id={column.label}/>}
                      {orderBy === (column.sortId || column.id) ?
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                        : null}
                    </TableSortLabel>
                    :
                    !!column.label && <FormattedMessage id={column.label}/>
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>


          <TableBody>
            {!!rows && !!rows.length ? rows.map((row, rowIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                    {columns.map((column) => {
                      let style = {}
                      if (!!row.color) {
                        style = { ...style, color: row.color }
                      }
                      if (!!column.minWidth) {
                        style = { ...style, minWidth: column.minWidth }
                      }
                      return (
                        <TableCell key={column.id} style={style}>
                          {column.id === 'actions' && !inner ?
                            <AuthConsumer>
                              {({ userPermissions }) => (
                                <ActionsMenu selectedRow={row} id={row.id} entityName={entityName}
                                             userPermissions={userPermissions} setPdfLoading={setPdfLoading}/>
                              )}
                            </AuthConsumer> :
                            getColumnValue(column.id, row[column.id], row)}{column.color}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              }) :
              !listingLoading && <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell colSpan={(!!columns && !!columns.length) ? columns.length : 1}
                           className={classes.noResults}><FormattedMessage
                  id='Table.NoResults'/></TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ListingConsumer>
        {({ listingCount, listingRowsPerPage, listingPage, handleChangeListingPage, handleChangeListingRowsPerPage, listingLoading }) => (
          !listingLoading && !!listingCount &&
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 200]}
            component="div"
            count={listingCount || -1}
            rowsPerPage={listingRowsPerPage}
            page={listingPage}
            onChangePage={handleChangeListingPage}
            onChangeRowsPerPage={handleChangeListingRowsPerPage}
            labelDisplayedRows={handleLabelDisplayedRows}
            labelRowsPerPage={intl.formatMessage({ id: 'TablePagination.LabelRowsPerPage' })}
            backIconButtonText={intl.formatMessage({ id: 'TablePagination.BackIconButtonText' })}
            nextIconButtonText={intl.formatMessage({ id: 'TablePagination.NextIconButtonText' })}
          />
        )}
      </ListingConsumer>
    </Paper>
  )
}

export default ListingTable
