import { filterData, log } from '../../utils/helpers'
import moment from 'moment'

export const loginPayload = (formData) => {
  log('loginPayload', formData)
  return {
    'email': formData.email,
    'password': formData.password
  }
}

export const forgotPasswordPayload = (formData) => {
  log('forgotPasswordPayload', formData)
  return {
    'email': formData.email || '',
    'recaptcha_token': formData.reCaptcha
  }
}
export const changePasswordPayload = (formData, token) => {
  log('changePasswordPayload', formData)
  return {
    'email': formData.email || '',
    'password': formData.password || '',
    'password_confirmation': formData.confirmPassword || '',
    'token': token || '',
    'recaptcha_token': formData.reCaptcha
  }
}
export const ListingParamsPayload = (entityName, page, limit, sort, filters, isList) => {
  log('ListingParamsPayload', entityName, page, limit, filters)
  let ListingParams = ['maintenance-order-status-tracks'].includes(entityName) ? {
    sort: '-created_at',
    'perPage': 100
  } : {
    sort: sort || '-created_at',
    'page': !!isList ? 0 : page,
    'perPage': !!isList ? 0 : limit
  }
  let newFilters = {}
  if (!!Object.keys(filters || {})?.length) {
    let likeKeys = ['name', 'email', 'title', 'code', 'mobile', 'imei']
    Object.keys(filters).forEach((filterKey) => {
      if (!!likeKeys.includes(filterKey) && !!filters[filterKey]) {
        newFilters[`${filterKey}[like]`] = (filterKey === 'mobile') ? (filters[filterKey]?.length > 3 ? '%' + filters[filterKey]?.replace(/\s/g, '') + '%' || '' : '') : '%' + filters[filterKey] + '%'
      } else {
        newFilters[filterKey] = filters[filterKey]
      }
    })
  }
  return { ...ListingParams, ...filterData(newFilters || {}) }
}
export const ListingDialogSubmitPayload = (entityName, action, newData, oldData, attachmentType, pdfAttachmentType) => {
  let data = {}
  if (action !== 'delete') {
    switch (entityName) {
      case 'users':
        data = {
          'name': newData.name || '',
          'email': newData.email || '',
          'gender': newData.gender || '',
          'mobile': newData.mobile.length > 3 ? newData.mobile?.replace(/\s/g, '') || '' : '',
          'password': newData.password || '',
          'roles': newData.roles || ''
        }
        break
      case 'roles':
        data = {
          'name': newData.name || '',
          'permissions': newData.permissions || ''
        }
        break
      case 'teams':
        data = {
          'title': newData.title || '',
          'supervisor_id': newData.supervisor_id || '',
          'leader_id': newData.leader_id || ''
        }
        break
      case 'service-requests':
        data = {
          'customer_id': newData.customer_id || '',
          'customer_contact_id': newData.customer_contact_id || '',
          'vehicle_count': newData.vehicle_count || 0,
          'type': newData.type || ''
        }
        break

      case 'operators':
        data = {
          'name': newData.name || '',
          'code': newData.code || ''
        }
        break

      case 'ticket-types':
        data = {
          'name': newData.name || '',
          'description': newData.description || '',
          'ticket_category_id': newData.ticket_category_id || ''
        }
        break

      case 'imeis':
        data = {
          'product_id': newData.product_id || '',
          'inventory_id': newData.inventory_id || '',
          'imei': newData.imei || ''
        }
        break

      case 'form-templates':
        data = {
          'name': newData.name || '',
          'fields': newData.fields || []
        }
        break

      case 'calllogs':
        data = {
          'title': newData.title || '',
          'customer_id': newData.customer_id || '',
          'contact_id': newData.contact_id || '',
          'type': newData.type || '',
          'description': newData.description || ''
        }
        break
      case 'vehicle-products':
        if (action === 'transfer') {
          data = {
            'vehicle_product_id': newData?.vehicle_product_id || '',
            'vehicle_id': newData?.vehicle_id || '',
            'sensor_ids': newData?.sensor_ids || ''
          }
        } else {
          data = {
            'product_id': newData.product_id || '',
            'product_imei_id': newData.product_imei_id || '',
            'vehicle_id': newData.vehicle_id || '',
            'installation_order_id': newData.installation_order_id || '',
            'operator_id': newData.operator_id || '',
            'sensor_ids': newData.sensor_ids || '',
            'sim_number': newData.sim_number || '',
            'sim_serial': newData.sim_serial || '',
            'status': newData.status || '',
            'installation_date': !!newData.installation_date ? moment(newData.installation_date).locale('en-us').format('DD-MM-YYYY') || '' : '',
            'needs_configuration': newData.needs_configuration || ''
          }
        }
        break

      case 'ticket-cases':
        data = {
          'name': newData.name || '',
          'sla': newData.sla || '',
          'ticket_type_id': newData.ticket_type_id || '',
          'form_template_id': newData.form_template_id || '',
          'team_id': newData.team_id || '',
          'sort_order': parseInt(newData?.sort_order || 0),
          'description': newData.description || ''
        }
        break
      case 'tickets':
        data = {
          'title': newData.title || '',
          'body': newData.body || '',
          'ticket_category_id': newData.ticket_category_id || '',
          'ticket_type_id': newData.ticket_type_id || '',
          'customer_id': newData.customer_id || '',
          'customer_contact_id': newData.customer_contact_id || '',
          'customer_vehicle_id': newData.customer_vehicle_id || '',
          'created_by': newData.created_by || ''
        }
        break

      case 'ticket-user-cases':
        let additionalFields = {}
        let fields = []
        Object.keys(newData).filter(fieldKey => !['id', 'status'].includes(fieldKey.toString()))?.forEach(field => {
          fields.push({ name: field, value: newData[field] || '' })
        })
        if (!!fields?.length) {
          additionalFields['additional'] = fields
        }

        data = {
          'status': newData.status || '',
          ...additionalFields
        }
        break

      case 'customer-types':
      case 'ticket-categories':
      case 'brands':
        data = {
          'name': newData.name || '',
          'description': newData.description || ''
        }
        break

      case 'vehicle-brands':
      case 'permissions':
      case 'maintenance-providers':
        data = {
          'name': newData.name || ''
        }
        break
      case 'customers':
        data = {
          'name': newData.name || '',
          'lead': newData.lead || '',
          'organization_id': newData.organization_id || '',
          'commercial_reg_number': newData.commercial_reg_number || '',
          'tax_card_number': newData.tax_card_number || '',
          'phone_number': newData.phone_number.length > 3 ? newData.phone_number?.replace(/\s/g, '') || '' : '',
          'sales_id': newData.sales_id || '',
          'authorized_contact_id': newData.authorized_contact_id || '',
          'type_id': newData.type_id || '',
          'operators': newData.operators || '',
          'verified': newData.verified || false,
          'address': newData.address || ''
        }
        break

      case 'products':
        let additional = {}
        if (newData.type === 'simple' && newData?.quantities?.length) {
          let quantities = []
          newData?.quantities?.forEach((quantity) => {
            quantities.push({ quantity: quantity.quantity, inventory_id: quantity?.inventory?.id || '' })
          })
          additional = { quantities: quantities || [] }
        } else if (newData.type === 'configurable' && newData?.imeis?.length) {
          additional = { imeis: newData.imeis || [] }
        }
        data = {
          'name': newData.name || '',
          'type': newData.type || '',
          'serial_number': newData.serial_number || '',
          'brand_id': newData.brand_id || '',
          'cost': newData.cost || '',
          'price': newData.price || '',
          'description': newData.description || '',
          ...additional
        }
        break
      case 'maintenance-orders':
      case 'maintenance-orders-list':
        let products = []
        if (newData?.products?.length) {
          newData?.products?.forEach((product) => {
            let obj = {}
            let prodObj = {}
            if (entityName === 'maintenance-orders') {
              obj = { vehicle_product_id: !!Object.keys(product?.vehicle_product || {})?.length ? product?.vehicle_product?.id : product?.vehicle_product_id }
            } else {
              obj = {
                product_id: product?.product?.id,
                vehicle_id: product?.vehicle?.id
              }
              if (product?.product?.type === 'simple') {
                prodObj = { quantity: product.quantity || '' }
              } else if (product?.product?.type === 'configurable') {
                prodObj = { product_imei_id: product?.imei?.id || '' }
              }
            }
            products.push({ ...obj, ...prodObj })
          })
        }
        data = {
          'service_request_id': newData.service_request_id || '',
          'service_request_schedule_id': newData.service_request_schedule_id || '',
          'technician_id': newData.technician_id || '',
          'status': newData.status || '',
          'products': products || []
        }
        break
      case 'installation-orders':
      case 'installation-orders-list':
        data = {
          'service_request_id': newData.service_request_id || '',
          'service_request_schedule_id': newData.service_request_schedule_id || '',
          'technician_id': newData.technician_id || '',
          'status': newData.status || ''
        }
        break
      case 'installation-order-products':
        data = {
          'installation_order_id': newData.installation_order_id || '',
          'product_id': newData?.product?.id || '',
          'product_imei_id': newData?.imei?.id || '',
          'vehicle_id': newData?.vehicle?.id || '',
          'operator_id': newData.operator_id || '',
          'sensor_ids': newData.sensor_ids || '',
          'sim_number': newData.sim_number || '',
          'sim_serial': newData.sim_serial || ''
        }
        break
      case 'maintenance-product-status-tracks':
        let someData = {}
        if (newData?.status === 'solved') {
          someData = { solved_by: newData.solved_by || '' }
          if (newData?.solved_by === 'device_replacement') {
            if (!!Object.keys(newData?.replaced_product || {}).length) {
              someData = { ...someData, replaced_product_id: newData?.replaced_product?.id || '' }
              if (newData?.replaced_product?.type === 'configurable' && !!Object.keys(newData?.replaced_imei || {}).length) {
                someData = { ...someData, replaced_imei_id: newData?.replaced_imei?.id || '' }
              }
            }
            someData = { ...someData, replace_reason: newData?.replace_reason || '' }
          }
        }
        data = {
          'maintenance_order_product_id': newData.maintenance_order_product_id || '',
          'status': newData.status || '',
          'reason': newData.reason || '',
          ...someData
        }
        break
      case 'maintenance-order-status-tracks':
        data = {
          'maintenance_order_id': newData.maintenance_order_id || '',
          'status': newData.status || '',
          'reason': newData.reason || ''
        }
        break
      case 'vehicles':
        data = {
          'brand_id': newData.brand_id || '',
          'model_year': newData.model_year || '',
          'plate_number': newData.plate_number || '',
          'motor_number': newData.motor_number || '',
          'chassis_number': newData.chassis_number || '',
          'customer_id': newData.customer_id || ''
        }
        break

      case 'service-request-schedules':
      case 'service-request-schedules-list':
        data = {
          'service_request_id': newData.service_request_id || '',
          'start_date': !!newData.start_date ? moment(newData.start_date).locale('en-us').format('DD-MM-YYYY hh:mm a') || '' : '',
          'end_date': !!newData.end_date ? moment(newData.end_date).locale('en-us').format('DD-MM-YYYY hh:mm a') || '' : '',
          'address': newData.address || '',
          'address_description': newData.address_description || '',
          'is_needs_visit': newData.is_needs_visit || false,
          'vehicle_count': newData.vehicle_count || 0,
          'note': newData.note || ''
        }
        break
      case 'inventories':
        data = {
          'name': newData.name || '',
          'is_backorder': newData.is_backorder || false
        }
        break
      case 'contacts':
        const formData = new FormData()
        formData.append('name', newData.name || '')
        formData.append('email', newData.email || '')
        formData.append('mobile', newData.mobile.length > 3 ? newData.mobile?.replace(/\s/g, '') || '' : '')
        formData.append('customer_id', newData.customer_id || '')
        formData.append('nid_number', newData.nid_number || '')
        formData.append('authorized', newData.authorized || false)
        formData.append('description', newData.description || '')
        if (action === 'edit') {
          formData.append('_method', 'PUT')
        }
        if (!!newData.nid_photo) {
          formData.append('nid_photo', newData.nid_photo || '')
        }
        data = formData
        break
      default:
        break
    }
  }

  return data
}
