import { makeStyles } from '@material-ui/core/styles'
import { ArabicFont } from '../../utils/Fonts'

export const useStyles = makeStyles((theme) => ({
  languageSwitcherWrapper: {
    position: 'relative'
  },
  languageSwitcherButton: {
    color: theme.palette.primary.dark,
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
    display: 'flex !important',
    alignItems: 'center',
    border: 0,
    fontFamily: ArabicFont,
    fontSize: theme.typography.pxToRem(16),
    transition: 'all 0.3s ease-in-out',
    '&:not(.mobile)': {
      flexDirection: 'row-reverse'
    },
    '&:hover, &:focus': {
      color: theme.palette.secondary.main,
      outline: 'none'
    },
    '&.mobile': {
      width: '100%',
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        color: theme.palette.primary.dark,
      }
    },
  }
}))
