import React from 'react'
import { useFormik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { Button } from '@material-ui/core'
import { AddNewUserFormValidation } from '../../../utils/validationSchema'
import { FormLoader } from '../../Loader/FormLoader'
import { useStyles } from '../../../utils/FormStyles'
import { ListingConsumer, ListingProvider } from '../../../context/ListingContext'
import FormTextField from './Fields/FormTextField'
import FormPhoneField from './Fields/FormPhoneField'
import FormSelectField from './Fields/FormSelectField'
import FormPassWordField from './Fields/FormPassWordField'
import FormEntityLoadField from './Fields/FormEntityLoadField'

const UsersForm = ({ onSubmit, currentData, cancelDialog, currentAction }) => {
  const classes = useStyles()
  const formActions = useFormik({
    initialValues: {
      id: currentData?.id || '',
      name: currentData?.name || '',
      email: currentData?.email || '',
      gender: currentData?.gender || '',
      mobile: '+20' + (!!currentData?.mobile ? currentData?.mobile.replace(/^\+20/g, '') : ''),
      password: '',
      roles: currentData?.roles || []
    },
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    validationSchema: AddNewUserFormValidation,
    onSubmit: ((values, formikHelpers) => {
      onSubmit(values, formikHelpers)
    })
  })

  return (
    <form onSubmit={formActions.handleSubmit} noValidate autoComplete="off" className={classes.formWrapper}>

      <FormTextField formActions={formActions} classes={classes} name={'name'} labelKey={'Fields.Labels.Name'}
                     required/>
      <FormTextField formActions={formActions} classes={classes} name={'email'} labelKey={'Fields.Labels.email'}
                     required
                     email/>
      <FormSelectField formActions={formActions} classes={classes} name={'gender'} labelKey={'Fields.Labels.gender'}
                       required type={'gender'}/>
      <FormPhoneField formActions={formActions} classes={classes} name={'mobile'} labelKey={'Fields.Labels.mobile'}
                      required/>
      <FormPassWordField formActions={formActions} classes={classes} name={'password'}
                         labelKey={'Fields.Labels.password'} required/>

      <ListingProvider entityName={'roles'} initListArr={currentData?.roles}>
        <ListingConsumer>
          {({ listingData, listingLoading, handleListingFilterSubmit }) => (
            <FormEntityLoadField formActions={formActions} classes={classes} name={'roles'}
                                 list={listingData} loading={listingLoading} entityKey={'name'}
                                 searchable={'name'} loadEntities={handleListingFilterSubmit}
                                 labelKey={'Fields.Labels.roles'} required multiple addMore/>
          )}
        </ListingConsumer>
      </ListingProvider>


      <div className={classes.actionButtonsWrapper}>
        <Button type="submit" variant='contained' color='primary'
                className={classes.addRecordButton} id='addDialogRecordButton'
                onClick={formActions.handleSubmit}
                disabled={formActions.isSubmitting || !formActions.isValid}
        >
          <FormattedMessage id={`AddNewDialog.AddNewRecord.${currentAction}`}/>
        </Button>
        <Button variant='outlined' color='primary'
                onClick={cancelDialog}>
          <FormattedMessage id='AddNewDialog.Cancel'/>
        </Button>
      </div>
      {formActions.isSubmitting && <FormLoader loading={true}/>}
    </form>
  )
}

export default UsersForm
