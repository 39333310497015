import React from 'react'
import { LanguageConsumer } from '../context/LanguageContext'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import TurnedInIcon from '@material-ui/icons/TurnedIn'
import BeenhereIcon from '@material-ui/icons/Beenhere'
import Moment from 'react-moment'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

export const ListingHeaders = {
  /** users listing stats **/
  users: [
    { id: 'name', label: 'Fields.Labels.Name', sort: false },
    { id: 'email', label: 'Fields.Labels.email', sort: true },
    { id: 'gender', label: 'Fields.Labels.gender', sort: true, search: true },
    { id: 'mobile', label: 'Fields.Labels.mobile', sort: true },
    { id: 'roles', label: 'Fields.Labels.roles', sort: true, search: true, searchId: 'role', searchInitVal: [] },
    { id: 'permissions', label: 'Fields.Labels.permissions', sort: true },
    { id: 'actions', label: '' }
  ],

  /** Teams listing stats **/
  teams: [
    { id: 'title', label: 'Fields.Labels.title', sort: true },
    {
      id: 'supervisor',
      label: 'Fields.Labels.supervisor',
      sort: true,
      search: true,
      searchId: 'supervisor_id',
      filter: { 'role': ['supervisor'] }
    },
    {
      id: 'leader', label: 'Fields.Labels.leader', sort: true, search: true, searchId: 'leader_id',
      filter: { 'role': ['leader'] }
    },
    { id: 'actions', label: '' }
  ],

  /** Customers listing stats **/
  customers: [
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'lead', label: 'Fields.Labels.lead', sort: true },
    { id: 'organization_id', label: 'Fields.Labels.organization_id', sort: true },
    { id: 'commercial_reg_number', label: 'Fields.Labels.commercial_reg_number', sort: true },
    { id: 'tax_card_number', label: 'Fields.Labels.tax_card_number', sort: true },
    { id: 'phone_number', label: 'Fields.Labels.phone_number', sort: true },
    {
      id: 'operators',
      label: 'Fields.Labels.operators',
      sort: true,
      search: true,
      searchId: 'operator_id',
      searchEntity: 'operators'
    },
    { id: 'customersType', label: 'Fields.Labels.customerType', sort: true },
    {
      id: 'sales', label: 'Fields.Labels.sales', sort: true, search: true,
      searchId: 'sales_id',
      filter: { 'role': ['sales'] }
    },
    { id: 'authorized_contact', label: 'Fields.Labels.authorizedContact', sort: true },
    { id: 'verified', label: 'Fields.Labels.verified', sort: true },
    { id: 'created_at', label: 'Fields.Labels.createdAt', sort: true },
    { id: 'actions', label: '' }
  ],

  /** Customer Contacts listing stats **/
  contacts: [
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'email', label: 'Fields.Labels.email', sort: true },
    { id: 'mobile', label: 'Fields.Labels.mobile', sort: true },
    {
      id: 'customer',
      label: 'Fields.Labels.customer',
      sort: true,
      search: true,
      searchId: 'customer_id',
      searchEntity: 'customers'
    },
    { id: 'nid_number', label: 'Fields.Labels.nidNumber' },
    { id: 'nid_photo', label: 'Fields.Labels.nidPhoto' },
    { id: 'created_at', label: 'Fields.Labels.createdAt', sort: true },
    { id: 'actions', label: '' }
  ],

  /** Roles listing stats **/
  roles: [
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'permissions', label: 'Fields.Labels.permissions', search: true, searchId: 'permission', searchInitVal: [] },
    { id: 'actions', label: '' }
  ],

  /** Permissions listing stats **/
  permissions: [
    { id: 'name', label: 'Fields.Labels.Name', sort: true, search: true },
    { id: 'actions', label: '' }
  ],


  /** Vehicles listing stats **/
  vehicles: [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    {
      id: 'brand',
      label: 'Fields.Labels.brand',
      sort: true,
      search: true,
      searchEntity: 'vehicle-brands',
      searchId: 'brand_id'
    },
    { id: 'model_year', label: 'Fields.Labels.model_year', sort: true },
    { id: 'plate_number', label: 'Fields.Labels.plate_number', sort: true },
    { id: 'motor_number', label: 'Fields.Labels.motor_number', sort: true },
    { id: 'chassis_number', label: 'Fields.Labels.chassis_number', sort: true },
    {
      id: 'customer',
      label: 'Fields.Labels.customer',
      sort: true,
      sortId: 'customer',
      search: true,
      searchEntity: 'customers',
      searchId: 'customer_id'
    },
    { id: 'actions', label: '' }
  ],
  'vehicle-brands': [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'actions', label: '' }
  ],
  'maintenance-providers': [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'actions', label: '' }
  ],
  brands: [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'description', label: 'Fields.Labels.description', sort: true },
    { id: 'actions', label: '' }
  ],

  operators: [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'code', label: 'Fields.Labels.code', sort: true },
    { id: 'actions', label: '' }
  ],
  'customer-types': [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'description', label: 'Fields.Labels.description', sort: true },
    { id: 'actions', label: '' }
  ],
  'ticket-categories': [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'description', label: 'Fields.Labels.description', sort: true },
    { id: 'actions', label: '' }
  ],
  'ticket-types': [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    {
      id: 'ticket_category',
      label: 'Fields.Labels.ticket_category',
      sort: true,
      sortId: 'ticket_category',
      search: true,
      searchEntity: 'ticket-categories',
      searchId: 'ticket_category_id'
    },
    { id: 'actions', label: '' }
  ],
  'ticket-cases': [
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'description', label: 'Fields.Labels.description', sort: true },
    { id: 'sla', label: 'Fields.Labels.sla', sort: true },
    {
      id: 'ticket_type',
      label: 'Fields.Labels.ticket_type',
      sort: true,
      sortId: 'ticket_types',
      search: true,
      searchEntity: 'ticket-types',
      searchId: 'ticket_types_id'
    },
    { id: 'caseTeam', label: 'Fields.Labels.team', sort: true, sortId: 'team_id' },
    {
      id: 'form_template',
      label: 'Fields.Labels.form_template',
      sort: true,
      sortId: 'form_template',
      search: true,
      searchEntity: 'form-templates',
      searchId: 'form_template_id'
    },
    { id: 'sort_order', label: 'Fields.Labels.sort_order', sort: true },
    { id: 'actions', label: '' }
  ],

  'tickets': [
    { id: 'title', label: 'Fields.Labels.title', sort: true },
    { id: 'body', label: 'Fields.Labels.body', sort: true },
    { id: 'sla', label: 'Fields.Labels.sla', sort: true },
    { id: 'remaining_sla', label: 'Fields.Labels.remaining_sla', sort: true },
    { id: 'open_date', label: 'Fields.Labels.open_date', sort: true },
    { id: 'ticketStatus', label: 'Fields.Labels.status', sort: true, search: true, searchId: 'status' },
    { id: 'state', label: 'Fields.Labels.state', sort: true, search: true },
    { id: 'created_by', label: 'Fields.Labels.created_by', sort: true },
    { id: 'actions', label: '' }
  ],
  'ticket-user-cases': [
    { id: 'id', label: 'Fields.Labels.id' },
    { id: 'name', label: 'Fields.Labels.Name' },
    { id: 'sla', label: 'Fields.Labels.sla' },
    { id: 'remaining_sla', label: 'Fields.Labels.remaining_sla' },
    { id: 'description', label: 'Fields.Labels.description' },
    { id: 'case_number', label: 'Fields.Labels.case_number' },
    { id: 'is_reopened', label: 'Fields.Labels.is_reopened' },
    { id: 'open_date', label: 'Fields.Labels.open_date' },
    { id: 'ticketStatus', label: 'Fields.Labels.status' },
    { id: 'state', label: 'Fields.Labels.state' }
  ],
  inventories: [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    { id: 'is_backorder', label: 'Fields.Labels.is_backorder' },
    { id: 'actions', label: '' }
  ],
  imeis: [
    {
      id: 'product', label: 'Fields.Labels.product', sort: true,
      sortId: 'product_id',
      search: true,
      searchEntity: 'products',
      searchId: 'product_id'
    },
    {
      id: 'inventory',
      label: 'Fields.Labels.inventory',
      sort: true,
      sortId: 'inventory',
      search: true,
      searchEntity: 'inventories',
      searchId: 'inventory_id'
    },
    { id: 'imei', label: 'Fields.Labels.imei', sort: true },
    { id: 'actions', label: '' }
  ],
  products: [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true },
    {
      id: 'brand',
      label: 'Fields.Labels.brand',
      sort: true,
      search: true,
      searchEntity: 'brands',
      searchId: 'brand_id'
    },
    { id: 'productsType', label: 'Fields.Labels.type', sort: true, sortId: 'type' },
    { id: 'serial_number', label: 'Fields.Labels.serial_number', sort: true },
    { id: 'cost', label: 'Fields.Labels.cost', sort: true },
    { id: 'price', label: 'Fields.Labels.price', sort: true },
    { id: 'description', label: 'Fields.Labels.description', sort: true },
    { id: 'quantity', label: 'Fields.Labels.quantity', sort: true },
    {
      id: 'inventory',
      label: 'Fields.Labels.inventory',
      hide: true,
      sort: true,
      sortId: 'inventory',
      search: true,
      searchEntity: 'inventories',
      searchId: 'inventory_id'
    },
    { id: 'actions', label: '' }
  ],
  'service-requests': [
    {
      id: 'customer',
      label: 'Fields.Labels.customer',
      sort: true,
      search: true,
      searchEntity: 'customers',
      searchId: 'customer_id'
    },
    {
      id: 'customer_contact',
      label: 'Fields.Labels.customer_contact',
      sort: true
      // search: true,
      // searchEntity: 'contacts',
      // searchId: 'customer_contact_id'
    },
    { id: 'vehicle_count', label: 'Fields.Labels.vehicle_count', sort: true },
    { id: 'remaining', label: 'Fields.Labels.remaining' },
    {
      id: 'serviceRequestType',
      label: 'Fields.Labels.serviceRequestType',
      sort: true,
      sortId: 'type',
      search: true,
      searchId: 'type'
    },
    { id: 'created_at', label: 'Fields.Labels.createdAt', sort: true },
    { id: 'updated_at', label: 'Fields.Labels.updatedAt', sort: true },
    { id: 'actions', label: '' }
  ],
  'service-request-schedules': [
    { id: 'start_date', label: 'Fields.Labels.start_date' },
    { id: 'end_date', label: 'Fields.Labels.end_date' },
    { id: 'address', label: 'Fields.Labels.address' },
    { id: 'address_description', label: 'Fields.Labels.address_description' },
    { id: 'vehicle_count', label: 'Fields.Labels.vehicle_count' },
    { id: 'is_needs_visit', label: 'Fields.Labels.is_needs_visit' },
    { id: 'note', label: 'Fields.Labels.note' }
    // { id: 'actions', label: '' }
  ],
  'service-request-schedules-list': [
    { id: 'id', label: 'Fields.Labels.id' },
    { id: 'start_date', label: 'Fields.Labels.start_date', minWidth: 130 },
    { id: 'end_date', label: 'Fields.Labels.end_date', minWidth: 130 },
    { id: 'type', label: 'Fields.Labels.type' },
    { id: 'address', label: 'Fields.Labels.address' },
    { id: 'address_description', label: 'Fields.Labels.address_description' },
    { id: 'vehicle_count', label: 'Fields.Labels.vehicle_count' },
    { id: 'is_needs_visit', label: 'Fields.Labels.is_needs_visit' },
    { id: 'note', label: 'Fields.Labels.note' },
    { id: 'actions', label: '' }
  ],
  'installation-orders': [
    { id: 'technician', label: 'Fields.Labels.technician' },
    { id: 'status', label: 'Fields.Labels.status' }
    // { id: 'products', label: 'Fields.Labels.products' },
    // { id: 'actions', label: '' }
  ],
  'maintenance-orders': [
    { id: 'technician', label: 'Fields.Labels.technician' },
    { id: 'status', label: 'Fields.Labels.status' }
    // { id: 'products', label: 'Fields.Labels.products' },
    // { id: 'actions', label: '' }
  ],
  'installation-orders-list': [
    { id: 'id', label: 'Fields.Labels.id' },
    { id: 'service_request', label: 'Fields.Labels.service_request' },
    { id: 'order_customer', label: 'Fields.Labels.order_customer' },
    { id: 'order_customer_contact', label: 'Fields.Labels.order_customer_contact' },
    { id: 'service_request_schedule', label: 'Fields.Labels.service_request_schedule' },
    { id: 'technician', label: 'Fields.Labels.technician' },
    { id: 'orderStatus', label: 'Fields.Labels.status' },
    // { id: 'products', label: 'Fields.Labels.products' },
    { id: 'actions', label: '' }
  ],
  'maintenance-orders-list': [
    { id: 'id', label: 'Fields.Labels.id' },
    { id: 'service_request', label: 'Fields.Labels.service_request' },
    { id: 'order_customer', label: 'Fields.Labels.order_customer' },
    { id: 'order_customer_contact', label: 'Fields.Labels.order_customer_contact' },
    { id: 'service_request_schedule', label: 'Fields.Labels.service_request_schedule' },
    { id: 'technician', label: 'Fields.Labels.technician' },
    { id: 'orderStatus', label: 'Fields.Labels.status' },
    { id: 'status_track', label: 'Fields.Labels.status_track' },
    { id: 'actions', label: '' }
  ],
  'maintenance-order-status-tracks': [
    { id: 'id', label: 'Fields.Labels.id' },
    { id: 'statusTrack', label: 'Fields.Labels.status' },
    { id: 'reason', label: 'Fields.Labels.reason' }
    // { id: 'products', label: 'Fields.Labels.products' },
    // { id: 'actions', label: '' }
  ],
  'maintenance-product-status-tracks': [
    { id: 'id', label: 'Fields.Labels.id' },
    { id: 'statusTrack', label: 'Fields.Labels.status' },
    { id: 'reason', label: 'Fields.Labels.reason' },
    { id: 'solved_by', label: 'Fields.Labels.solvedBy' }
    // { id: 'products', label: 'Fields.Labels.products' },
    // { id: 'actions', label: '' }
  ],

  'product-quantities': [
    { id: 'quantity', label: 'Fields.Labels.quantity' },
    { id: 'inventory', label: 'Fields.Labels.inventory' }
  ],

  'installation-order-products': [
    { id: 'product', label: 'Fields.Labels.product' },
    { id: 'product_imei', label: 'Fields.Labels.product_imei' },
    { id: 'quantity', label: 'Fields.Labels.quantity' },
    { id: 'vehicle', label: 'Fields.Labels.vehicle' }
  ],
  /** Vehicle products listing stats **/
  'vehicle-products': [
    { id: 'customer', label: 'Fields.Labels.customer', sort: true },
    {
      id: 'product', label: 'Fields.Labels.product', sort: true, search: true,
      searchEntity: 'products',
      searchId: 'product_id'
    },
    { id: 'product_imei', label: 'Fields.Labels.product_imei', sort: true },
    {
      id: 'vehiclePlate', label: 'Fields.Labels.vehicle', sort: true, search: true,
      searchEntity: 'vehicles',
      searchId: 'vehicle_id'
    },
    { id: 'sensors', label: 'Fields.Labels.sensors', sort: true },
    { id: 'installation_order', label: 'Fields.Labels.installation_order', sort: true },
    { id: 'operator', label: 'Fields.Labels.operator', sort: true },
    { id: 'sim_number', label: 'Fields.Labels.sim_number', sort: true },
    { id: 'sim_serial', label: 'Fields.Labels.sim_serial', sort: true },
    {
      id: 'vehicleProductStatus',
      label: 'Fields.Labels.status',
      sort: true,
      sortId: 'status'
      // search: true,
      // searchId: 'status'
    },
    { id: 'installation_date', label: 'Fields.Labels.installation_date', sort: true },
    { id: 'actions', label: '' }
  ],
  /** Call-logs listing stats **/
  calllogs: [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'title', label: 'Fields.Labels.title', sort: true },
    {
      id: 'customer',
      label: 'Fields.Labels.customer',
      sort: true,
      search: true,
      searchEntity: 'customers',
      searchId: 'customer_id'
    },
    { id: 'contact', label: 'Fields.Labels.contact', sort: true },
    { id: 'calllogsType', label: 'Fields.Labels.callType', sort: true, search: true, searchId: 'type' },
    { id: 'created_at', label: 'Fields.Labels.createdAt', sort: true },
    { id: 'actions', label: '' }
  ],
  'form-templates': [
    { id: 'id', label: 'Fields.Labels.id', sort: true },
    { id: 'name', label: 'Fields.Labels.Name', sort: true, search: true },
    { id: 'fields', label: 'Fields.Labels.fields' },
    { id: 'actions', label: '' }
  ]

}

export const arrangeListingData = (entityName, data, parentEntityName, locale) => {
  if (!!data && !!data?.length) {
    switch (entityName) {
      case 'customers':
      case 'calllogs':
        data.forEach((item) => {
          if (!!item.type) {
            item[entityName + 'Type'] = item.type
          }
        })
        break
      case 'ticket-cases':
        data.forEach((item) => {
          if (!!item.team) {
            item['caseTeam'] = item.team
          }
        })
        break
      case 'tickets':
      case 'ticket-user-cases':
        data.forEach((item) => {
          if (!!item.status) {
            item['ticketStatus'] = item.status
          }
        })
        break
      case 'teams':
        data.forEach((item) => {
          if (!!item.title) {
            item['name'] = item.title
          }
        })
        break

      case 'products':
        data.forEach((item) => {
          if (!!item.type) {
            item['productsType'] = item.type
          }
          if (!!item?.imeis?.length) {
            item.imeis.forEach((imei) => {
              if (!!imei?.inventory) {
                imei['inventoryName'] = imei?.inventory?.name || ''
                imei['inventory_id'] = imei?.inventory?.id || ''
              }
            })
          }
        })
        break
      case 'vehicle-products':
        data.forEach((item) => {
          if (!!item.status) {
            item['vehicleProductStatus'] = item.status
          }
          item['sensors'] = item['sensors']?.map(item => item.product)
          item['vehiclePlate'] = item['vehicle']
          item['name'] = [`${item?.vehicle?.name || ''}${item?.vehicle?.brand?.name ? `(${item?.vehicle?.brand?.name})` : ''}`,
            `${item?.product?.name || ''}${item?.product?.type ? `(${item?.product?.type})` : ''}`,
            item?.customer?.name || ''
          ].filter(item => !!item).join(' - ')
        })
        break

      case 'imeis':
        data.forEach((item) => {
          if (!!item.imei) {
            item['name'] = item.imei
          }
          if (!!item.inventory) {
            item['inventoryName'] = item.inventory?.name || ''
            item['inventory_id'] = item.inventory?.id || ''
          }
        })
        break

      case 'service-requests':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            if (!!item?.vehicle_count) {
              item['remaining'] = Math.max((item.vehicle_count || 0) - (item.products_count || 0), 0)
            }
            if (!!item?.type) {
              item['serviceRequestType'] = item.type
            }
            if (!!item?.customer?.name) {
              item['name'] = `${item?.customer?.name || ''} (${item.type}) - ${item.vehicle_count || 0}`
            }
          }
        })
        break
      case 'service-request-schedules':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            item['icon'] = <AccessTimeIcon/>
            if (!!item?.start_date) {
              item['headerTitle'] = <LanguageConsumer>
                {({ locale }) => (
                  <Moment locale={locale === 'ar' ? 'ar-ly' : 'en-us'}
                          format="Do MMMM YYYY, h:mm A">{item.start_date}</Moment>
                )}
              </LanguageConsumer>
              item['name'] = item['headerTitle']//moment(item.start_date).locale('en-us').format('Do MMMM YYYY, h:mm A')
            }
          }
        })
        break
      case 'service-request-schedules-list':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            item['type'] = item?.maintenance_orders?.length ? 'Maintenance' : 'Installation'
            if (!!item?.start_date) {
              item['name'] = moment(item.start_date).locale(locale === 'ar' ? 'ar-ly' : 'en-us').format('Do MMMM YYYY, h:mm A')
            }
          }
        })
        break
      case 'product-quantities':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            item['icon'] = <EqualizerIcon/>
            item['headerTitle'] = `${item?.inventory?.name || ''} (${item.quantity || 0})`
            item['name'] = item['quantity'] || 0
          }
        })
        break

      case 'installation-order-products':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            item['icon'] = <TurnedInIcon/>
            item['headerTitle'] = item?.product?.name || item?.vehicle_product?.product?.name || ''
            item['name'] = item?.product?.name || item?.vehicle_product?.product?.name || ''
            item['product'] = { ...(item?.product || {}), ...(item?.vehicle_product?.product || {}) }
            item['vehicle'] = { ...(item?.vehicle || {}), ...(item?.vehicle_product?.vehicle || {}) }
          }
        })
        break
      case 'maintenance-order-status-tracks':
      case 'maintenance-product-status-tracks':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            item['icon'] = <TurnedInIcon/>
            item['headerTitle'] = <FormattedMessage id={`statusTrack.${item?.status || 'pending'}`}/>
            item['name'] = item?.status || ''
            item['statusTrack'] = <FormattedMessage id={`statusTrack.${item?.status || 'pending'}`}/>
          }
        })
        break
      case 'installation-orders':
      case 'maintenance-orders':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            item['icon'] = <BeenhereIcon/>
            item['headerTitle'] = item?.id?.toString() || ''
            item['name'] = item?.id?.toString() || ''
          }
        })
        break
      case 'installation-orders-list':
      case 'maintenance-orders-list':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            item.service_request['name'] = item?.service_request?.id || null
            item.service_request_schedule['name'] = item?.service_request_schedule?.id || null
            item['order_customer'] = item?.service_request?.customer || null
            item['order_customer_contact'] = item?.service_request?.customer_contact || null
            item['name'] = item?.id?.toString() || ''
            item['orderStatus'] = item?.status || ''
          }
        })
        break

      case 'vehicles':
        data.forEach((item) => {
          if (!!item && !!Object.keys(item || {})?.length) {
            let newName = []
            newName.push(item?.brand?.name || '')
            newName.push(item?.chassis_number || '')
            newName.push(item?.plate_number || '')
            item['name'] = newName.join(' - ')
          }
        })
        break
      default:
        break
    }
  }
  return data
}
